import { useContext, useEffect, useState } from 'react';

import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { ApiCalls } from '../models/apiCalls';
import Context from '../models/Context';
import Footer from '../components/Footer';
import Header from '../components/Header';
import huck from '../images/america.png';
import ToastVille from '../components/Toast';

interface IContactProps {
    currentUser: any,
    setCurrentUser: (val: any) => void
}

const ContactPage = (props: IContactProps) => {
    const { currentUser, setCurrentUser } = props;

    const apiCalls = new ApiCalls();
    const { darkMode } = useContext(Context);
    const navigate = useNavigate();

    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [showToast, setShowToast] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const clearForm = () => {
        setName('');
        setMessage('');
    }

    const saveContactForm = () => {
        apiCalls.submitContactForm({ message, name });
        setShowToast('Your message was sent to Hucklberry. Well get back to you as soon as humanly possible!');
        clearForm();
    }

    //-----Pure JSX Functions-----//

    const contactFormJSX = () => {
        return <Container>
            <Row className='justify-content-md-center mt-3'>
                <Col xs='12' lg='7' xl='6'>
                    <h5 className='border-bottom fw-bold pb-3 mb-3'>Contact us</h5>
                    <Form>
                    <div className='d-flex justify-content-center'>
                                    <div className='my-3 text-center'>
                                        <img className='flag-1 mb-2' src={huck} alt='user-profile-image' />
                                        <p className='fw-bold text-center'>Hucklberry.com</p>
                                        <p className='text-center'>PO Box 1286</p>
                                        <p className='text-center'>Three Forks, MT 59752</p>
                                        <p className='text-center'>support@hucklberry.com</p>
                                        <p className='text-center'>(406) 898-7716</p>
                                    </div>
                                </div>
                        <Form.Group className='mb-3' controlId='hucklberry-visitor-name'>
                            <Form.Label>Say hello!</Form.Label>
                            <Form.Control onChange={(e) => setName(e?.target?.value)} placeholder='Your name..' type='text' value={name} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='hucklberry-visitor-message'>
                            <Form.Control as='textarea' onChange={(e) => setMessage(e?.target?.value)} placeholder='Questions and comments welcome..' 
                                rows={9} type='text' value={message} />
                        </Form.Group>
                        <div className='d-flex justify-content-end'>
                            <Button onClick={() => navigate('/')} variant='light'>
                                Back
                            </Button>
                            <Button className='ms-2' disabled={name == '' || message == ''} onClick={() => saveContactForm()}>
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>;
    }

    return (
        <>
            <div className='pb-5' style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
                <Row>
                    <Col xs='12'>
                        <Header currentUser={currentUser} hideCenter={true}
                            setCurrentUser={setCurrentUser} />
                    </Col>
                    <Col className='border-top header-padding pt-4 mt-1' lg='12'>
                        {contactFormJSX()}
                    </Col>
                </Row>
            </div>
            {showToast != '' && <ToastVille message={showToast} />}
            <Footer />
        </>
    )
}
export default ContactPage;