
import { useContext, useEffect, useState } from 'react';

import { Button, Col, Container, Form, Row, ToastContainer, Toast } from 'react-bootstrap';
import {
    Box, BrightnessAltHigh, BrightnessHigh, BrightnessLow, Building, Calendar2, Calendar3, CalendarMonth, Camera2, Cart2, CurrencyDollar,
    DashCircle, HouseDoorFill, HouseHeart, Image, Lock, PlusCircle, Recycle, Umbrella, XCircle } from 'react-bootstrap-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { ApiCalls } from '../models/apiCalls';
import ChooseDateRangeModal from '../components/ChooseDateRangeModal';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import Context from '../models/Context';
import EditCurrentUserModal from '../components/EditCurrentUserModal';
import EditListingData from '../components/EditListingData';
import ErrorModal from '../components/ErrorModal';
import Header from '../components/Header';
import Maps from '../components/Map';
import PhotoUpload from '../components/PhotoUpload';
import { ReservationStatus } from '../models';
import SuccessModal from '../components/SuccessModal';
import Toastville from '../components/Toast';

interface IBecomeAHostProps {
    currentUser: any,
    setCurrentUser: (user: any) => void
}

const BecomeAHostPage = (props: IBecomeAHostProps) => {
    const { currentUser, setCurrentUser } = props;

    const apiCalls = new ApiCalls();
    const auth = getAuth();
    const { darkMode } = useContext(Context);
    const { listingId } = useParams();
    const navigate = useNavigate();
    const rentalTypes = [{ name: 'Vacation rental' }, { name: 'Month-to-month' }, { name: 'Year-long lease' }, { name: 'Sublet room' }];
    const secondRentalTypes = [{ name: 'Apartment' }, { name: 'Townhouse' }, { name: 'Single family home' }, { name: 'Tiny house/ADU' }, { name: 'Trailer/RV' }];
    const spaceTypeList = [{ name: 'An entire place' }, { name: 'A private room' }, { name: 'A shared room' }];

    const [allowPets, setAllowPets] = useState(false);
    const [allReservations, setAllReservations] = useState<any>([]);
    const [blockBackBtn, setBlockBackBtn] = useState(false);
    const [category, setCategory] = useState<any>(null);
    const [checkInAMPM, setCheckInAMPM] = useState('pm');
    const [checkOutAMPM, setCheckOutAMPM] = useState('am');
    const [checkInTime, setCheckInTime] = useState(4);
    const [checkOutTime, setCheckOutTime] = useState(11);
    const [city, setCity] = useState('');
    const [didJustCheckAddress, setDidJustCheckAddress] = useState(false);
    const [state, setState] = useState('');
    const [characters, setCharacters] = useState([]);
    const [address, setAddress] = useState('');
    const [addressSelectedFromMap, setAddressSelectedFromMap] = useState<any>(null);
    const [deleteListing, setDeleteListing] = useState(false);
    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [invoices, setInvoices] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lat, setLat] = useState('');
    const [lease, setLease] = useState<any>(null);
    const [long, setLong] = useState('');
    const [listing, setListing] = useState<any>(null);
    const [images, setImages] = useState([]);
    const [minStay, setMinStay] = useState(3);
    const [newNumBaths, setNewNumBaths] = useState(1);
    const [notifications, setNotifications] = useState<any>([]);
    const [numBaths, setNumBaths] = useState(1);
    const [numbBeds, setNumBeds] = useState(1);
    const [numBedrooms, setNumBedrooms] = useState(1);
    const [numGuests, setNumGuests] = useState(1);
    const [price, setPrice] = useState(listingId ? 0 : 199);
    const [type, setType] = useState<any>(null);
    const [showChooseDateRangeModal, setShowChooseDateRangeModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState('');
    const [showToast, setShowToast] = useState('');
    const [showUpdateCurrentUserModal, setShowUpdateCurrentUserModal] = useState(false);
    const [spaceSelected, setSpaceSelected] = useState<any>(null);
    const [step, setStep] = useState(listingId ? 7 : 1);
    const [validAddress, setValidAddress] = useState(false);

    onAuthStateChanged(auth, user => user ? setCurrentUser(user) : navigate('/'));

    useEffect(() => {
        window.scrollTo(0, 0);
        getAllReservationsForThisListing();
    }, []);

    useEffect(() => {
        if (addressSelectedFromMap) {
            getAddressFromArray();
            if (type != 'Vacation rental') setMinStay(30);
        }
    }, [addressSelectedFromMap]);

    useEffect(() => {
        if (deleteListing) {
            saveListing();
        }
    }, [deleteListing]);

    useEffect(() => {
        if (description != '' && descriptionError) {
            setDescriptionError('');
        }
        setIsLoading(false);
    }, [description]);

    useEffect(() => {
        if (images?.length > 0) {
            const arr: any[] = [];
            images.map((i: any, idx: number) => {
                arr.push({ id: `image-id${idx}`, thumb: i?.thumb ? i.thumb : i });
            });
            setCharacters(arr as any);
        }
    }, [images, step]);

    useEffect(() => {
        if (listing?.id) {
            setAddress(listing.address);
            setAllowPets(listing.petsAllowed);
            setCategory(listing.category);
            setCity(listing.city);
            setDescription(listing.description);
            setImages(listing.images);
            setLat(listing.lat);
            setLong(listing.lng);
            setMinStay(listing.minStay);
            setNewNumBaths(listing.numBaths);
            setNumBaths(listing.numBaths);
            setNumBedrooms(listing.numBedrooms);
            setNumGuests(listing.numGuests);
            setPrice(listing.price);
            setSpaceSelected(listing.spaceSelected);
            setState(listing.state);
            setType(listing.type);
        }
    }, [listing]);

    useEffect(() => {
        if (listingId) {
            getListingById();
        }
    }, [listingId]);

    useEffect(() => {
        if (showSuccessToast != '') {
            setTimeout(() => {
                setShowSuccessToast('');
            }, 5150);
        }
    }, [showSuccessToast]);

    useEffect(() => {
        if (step < 0 && step <= 7) {
            window.scrollTo(0, 0);
        }
        if (step == 4 && listingId) {
            setValidAddress(true);
        }
    }, [step]);

    useEffect(() => {
        if (type == 'Month-to-month' || type == 'Sublet room') {
            setMinStay(30);
        } else if (type == 'Year-long lease') {
            setMinStay(365);
        }
    }, [type]);

    const cancelAddressClicked = () => {
        setStep(step - 1);
        setAddressSelectedFromMap(null);
    }

    const continueClicked = () => {
        setShowErrorModal(false);
        setStep(7);
    }

    const didClickBackToEditPhotos = () => {
        setStep(step - 1);
        setBlockBackBtn(true);
    }

    const didSelectDates = async (start: any, end: any) => {
        if (end > start) {
            apiCalls.addReservation(listingId, listing, 0, 0, new Date(start), new Date(end), 0, ReservationStatus.Blocked, true);
            setShowToast('Those times have been blocked out.');
            getAllReservationsForThisListing();
        }
    }

    const generateContentBasedOffStep = () => {
        switch (step) {
            case 1: return selectPropertyRentalTypeJSX();
            case 2: return whichBestDescribesYourPlaceJSX();
            case 3: return whatKindOfSpaceJSX();
            case 4: return chooseYourLocationJSX();
            case 5: return guestsAndPetsJSX();
            case 6: return dragYourPhotosHereJSX();
            case 7: return reviewYourListingJSX();
            default: return <></>;
        }
    }

    const getAddressFromArray = () => {
        setValidAddress(false);
        if ((addressSelectedFromMap as any).addressArray?.length > 0) {
            var addr = '';
            var city = '';
            var state = '';
            (addressSelectedFromMap as any)?.addressArray?.map((a: any) => {
                const idx = a?.types?.findIndex((b: any) => b == 'street_number' || b == 'route' || b == 'locality' || b == 'administrative_area_level_1' || b == 'postal_code' || b == 'country');
                if (idx > -1) {
                    if (a?.types[idx] == 'administrative_area_level_1') {
                        state = a.short_name;
                    }
                    if (a?.types[idx] == 'locality') {
                        city = a.short_name;
                    }
                    addr = addr + ' ' + (a as any).short_name
                }
            });
            setValidAddress((addressSelectedFromMap as any)?.addressArray?.length > 4 ? true : false);
            setCity(city);
            setState(state);
            setLong(addressSelectedFromMap.lng);
            setLat(addressSelectedFromMap.lat);
            setAddress(addr);
        }
    }

    const getAllReservationsForThisListing = async () => {
        const res = await apiCalls.getAllReservationsByListingId(listingId);
        setAllReservations(res);
    }

    const getListingById = async () => {
        const listingData = await apiCalls.getListingById(listingId as any);
        setListing(listingData);
    }

    function handleOnDragEnd(result: any) {
        if (!result.destination) return;
        const items = Array.from(images);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setImages(items);
    }

    const saveListing = async () => {
        if (!auth?.currentUser) return;
        setIsLoading(true);
        if (await validateListing()) {
            listingId ? updateListing() : saveNewListingToAPI();
        } else {
            setShowErrorModal(true);
        }
    }

    const saveNewListingToAPI = async () => {
        const listingData = {
            address: address,
            category: category,
            checkInTime: checkInTime,
            checkOutAMPM: checkOutAMPM,
            checkInAMPM: checkInAMPM,
            checkOutTime: checkOutTime,
            city: city,
            description: description,
            images: characters,
            lat: lat,
            lease: lease ? lease : '',
            lng: long,
            minStay,
            numBaths: numBaths,
            numBedrooms: numBedrooms,
            numGuests: numGuests,
            petsAllowed: allowPets,
            price: Number(price),
            spaceSelected: spaceSelected,
            state: state,
            type: type,
        };
        await apiCalls.addListing(listingData);
        setCurrentUser({ listings: [currentUser?.listings?.length > 0 ? currentUser?.listings : null, listingData], ...currentUser });
        navigate('/account?didEditListing=true');
    }

    const showConfirmToastAfterProfileEdit = () => {
        setShowSuccessToast('Your personal information was updated! You may now upload photos.');
    }

    const skipBack = () => {
        if (listingId) return;
        setDidJustCheckAddress(true);
        setStep(4);
    }

    const toEnd = () => {
        setDidJustCheckAddress(false);
        setStep(7);
    }

    const updateListing = async () => {
        await apiCalls.updateListing(listingId as any, {
            active: deleteListing ? false : true,
            category: category,
            description: description,
            lat: lat,
            lease: lease ? lease : '',
            lng: long,
            type: type,
            minStay,
            numBaths: newNumBaths,
            numBedrooms: numBedrooms,
            numGuests: Number(numGuests),
            images: characters,
            petsAllowed: allowPets,
            price: Number(price),
            spaceSelected: spaceSelected
        });
        if (deleteListing) {
            navigate('/account');
            return;
        }
        navigate(`/listing/${listingId}?didEditListing=true`);
    }

    const userSelectedImagesAndAlreadyUploaded = (i: any) => {
        setImages(i);
        setStep(7);
    }

    const validateListing = () => {
        if (description == '') {
            setDescriptionError('invalid');
            return false;
        }
        if (images?.length < 5) {
            return false;
        }
        if (isNaN(price) || Number(price) == 0 || Number(price) > 99999) {
            return false;
        }
        return true;
    }

    //-----Pure JSX Functions-----//

    const bottomButtonsJSX = (disabledVal: boolean, cancelCallback: () => void, saveBtnText: string) => {
        return <div className='fixed-bottom w-100'>
            <div className='d-flex peener tweener'>
                <Button className='flex-fill mr-1' onClick={() => cancelCallback()} variant={darkMode ? 'outline-secondary' : 'secondary'}>Cancel</Button>
                <Button className='flex-fill ml-1' disabled={disabledVal} onClick={() => didJustCheckAddress ? toEnd() : setStep(step + 1)}>{saveBtnText}</Button>
            </div>
        </div>;
    }

    const calculateIconJSX = (name: string, hook: any) => {
        const color = (hook == name ? '#0d6efd' : (darkMode ? 'white' : '#222222'));
        if(name == 'An entire place') return <BrightnessHigh className='fs-3' style={{ color: color, opacity: hook == name ? '1' : '0.5' }} />
        if(name == 'A private room') return <BrightnessLow className='fs-3' style={{ color: color, opacity: hook == name ? '1' : '0.5' }} />
        if(name == 'A shared room') return <BrightnessAltHigh className='fs-3' style={{ color: color, opacity: hook == name ? '1' : '0.5' }} />
        if (name == 'Trailer/RV') return <Cart2 className='fs-3' style={{ color: color, opacity: hook == name ? '1' : '0.5' }} />
        if (name == 'Tiny house/ADU') return <Box className='fs-3' style={{ color: color, opacity: hook == name ? '1' : '0.5' }} />
        if (name == 'Townhouse') return <HouseDoorFill className='fs-3' style={{ color: color, opacity: hook == name ? '1' : '0.5' }} />
        if (name == 'Single family home') return <HouseHeart className='fs-3' style={{ color: color, opacity: hook == name ? '1' : '0.5' }} />
        if (name == 'Apartment') return <Building style={{ color: color, opacity: hook == name ? '1' : '0.5' }} />
        if (name == 'Sublet room') return <Recycle className='fs-3' style={{ color: color, opacity: hook == name ? '1' : '0.5' }} />
        if (name == 'Year-long lease') return <Calendar3 className='fs-3' style={{ color: color, opacity: hook == name ? '1' : '0.5' }} />
        if (name == 'Month-to-month') return <CalendarMonth className='fs-3' style={{ color: color, opacity: hook == name ? '1' : '0.5' }} />
        return <Umbrella className='fs-3' style={{ color: color, opacity: hook == name ? '1' : '0.5' }} />
    }

    const chooseYourLocationJSX = () => {
        return <Row className='vh-100'>
            <Col className='gradient h-100 d-none d-lg-block' xs='12' lg='6'>
                <div className='align-items-center d-flex h-100'>
                    <h1 className='mx-5'>What's the address?</h1>
                </div>
            </Col>
            <Col className='shitty' xs='12' lg='6'>
                <Maps address={address} selected={addressSelectedFromMap} isOnMapPage={true} listOfListings={addressSelectedFromMap ? [addressSelectedFromMap] : []}
                    onMapPage={true} setSelected={setAddressSelectedFromMap} />
                {bottomButtonsJSX(!address || !validAddress, cancelAddressClicked, 'Next step')}
            </Col>
        </Row>;
    }

    const dragYourPhotosHereJSX = () => {
        return <Row className='align-items-center jahStepHeight1'>
            <Col className='d-none d-lg-block gradient h-100' lg='6'>
                <div className='align-items-center d-flex h-100'>
                    <h1 className='mx-5'>Add photos</h1>
                </div>
            </Col>
            <Col xs='12' lg='6'>
                <div className='d-sm-block d-md-block d-lg-none d-xl-none w-100'>
                    <div className='d-flex justify-content-center'>
                        <h2 className='light-gray mx-5 my-3 text-center'>
                            Add photos
                        </h2>
                    </div>
                </div>
                <PhotoUpload callback={(e) => userSelectedImagesAndAlreadyUploaded(e)} blockBackBtn={blockBackBtn} images={images} setImages={setImages}
                    setShowUpdateUserName={setShowUpdateCurrentUserModal} setStep={setStep} step={step} />
            </Col>
        </Row>;
    }

    const guestsAndPetsJSX = () => {
        return <Row className='align-items-center jahStepHeight'>
            <Col className='gradient h-100 d-none d-lg-block' xs='6'>
                <div className='d-flex align-items-center h-100'>
                    <h1 className='mx-4'>How many guests would you like to welcome?</h1>
                </div>
            </Col>
            <Col className='align-items-center justify-content-center' xs='12' lg='6'>
                {mobileTitleJSX('How many guests would you like to welcome?')}
                <div className='border mb-4 mx-4 px-2 py-4 rounded '>
                    <div className='align-items-center d-flex justify-content-between pb-4 px-5'>
                        <h3>Guests</h3>
                        {plusOrMinusJSX(numGuests, setNumGuests)}
                    </div>
                    <div className='align-items-center d-flex justify-content-between pb-4  px-5'>
                        <h3>Beds</h3>
                        {plusOrMinusJSX(numbBeds, setNumBeds)}
                    </div>
                    <div className='align-items-center d-flex justify-content-between pb-4  px-5'>
                        <h3>Bedrooms</h3>
                        {plusOrMinusJSX(numBedrooms, setNumBedrooms)}
                    </div>
                    <div className='align-items-center d-flex justify-content-between px-5'>
                        <h3>Baths</h3>
                        {plusOrMinusJSX(numBaths, setNumBaths, true)}
                    </div>
                </div>
                <div className='border mx-4 px-2 py-4 rounded'>
                    <div className='align-items-center d-flex justify-content-between px-5'>
                        <h3>Pets allowed</h3>
                        <div className='fs-5 mt-1'>
                            <Form.Check type='switch' onClick={() => setAllowPets(!allowPets)}
                                id='custom-switch' checked={allowPets}
                                label={allowPets ? 'Yes' : 'No'} />
                        </div>
                    </div>
                </div>
                {bottomButtonsJSX(false, () => setStep(step - 1), 'Continue')}
            </Col>

        </Row>;
    }

    const jahButtonGroupJSX = () => {
        return <div className='mt-4'>
            <Button className='mb-2 w-100' disabled={isLoading || description == ''} onClick={() => saveListing()} size='sm'>
                {listingId ? 'Update' : 'Save'} listing
            </Button>
            <Button className='mb-2 w-100' disabled={isLoading} onClick={() => didClickBackToEditPhotos()} size='sm' variant='outline-secondary'>
                Edit photos <Camera2 className='fs-8 ms-2' />
            </Button>
            {listingId && <>
                <Button className='align-items-center d-flex justify-content-center mb-2 w-100'
                    disabled={description == ''}
                    onClick={() => setShowChooseDateRangeModal(true)}
                    size='sm' variant='outline-secondary'>
                    Block dates<Calendar2 className='fs-8 ms-2' />
                </Button>
                <Button className='w-100' onClick={() => setShowConfirmDeleteModal(true)} size='sm' variant='outline-danger'>
                    Delete listing
                </Button>
            </>}
        </div>;
    }

    const jahStepJSX = (title: string, list: any[], hook: any, setHook: any) => {
        return <Row className='align-items-center jahStepHeight'>
            <Col className='gradient h-100 d-none d-lg-block' xs='12' lg='6'>
                <div className='align-items-center d-flex h-100'>
                    <h1 className='mx-5'>{title}</h1>
                </div>
            </Col>
            <Col className='cursor' xs='12' lg='6'>
                {mobileTitleJSX(title)}
                <div className='px-3'>
                    {list?.length > 0 && list.map(rental => {
                        return <div className={['align-items-center border rounded d-flex justify-content-between mb-2 p-4', hook == rental?.name ? 'bold-block' : ''].join(' ')}
                            onClick={() => setHook(rental?.name as any)} key={`rental-type-${rental.name}`}>
                            <h5 className='fw-bold' style={{ color: rental?.name == hook ? (darkMode ? 'white' : '#222222') : (darkMode ? '#d3d3d3' : '#222222') }}>
                                {rental?.name}
                            </h5>
                            {calculateIconJSX(rental?.name, hook)}
                        </div>
                    })}
                </div>
                {bottomButtonsJSX(!hook, () => step <= 1 ? navigate('/') : setStep(step - 1), 'Continue')}
            </Col>
        </Row>;
    }

    const listingInfoJSX = () => {
        return <Col xs='12' md='8'>
            <Form.Control as='textarea' className='matty-titties' onChange={(e) => setDescription(e.target.value)} isInvalid={descriptionError != ''}
                placeholder='Write a description about your place...' style={{ minHeight: '350px' }} value={description} />
            <EditListingData
                allowPets={allowPets} setAllowPets={setAllowPets}
                category={category} setCategory={setCategory}
                checkInAMPM={checkInAMPM} setCheckInAMPM={setCheckInAMPM}
                checkOutAMPM={checkOutAMPM} setCheckOutAMPM={setCheckOutAMPM}
                checkInTime={checkInTime} setCheckInTime={setCheckInTime}
                checkOutTime={checkOutTime} setCheckOutTime={setCheckOutTime}
                lease={lease} setLease={setLease}
                minStay={minStay} setMinStay={setMinStay}
                spaceSelected={spaceSelected} setEditSpaceSelected={setSpaceSelected}
                numGuests={numGuests} setEditNumGuests={setNumGuests}
                numBedrooms={numBedrooms} setEditNumBedrooms={setNumBedrooms}
                numBaths={numBaths} setEditNumBaths={setNumBaths}
                newNumBaths={newNumBaths} setNewNumBaths={setNewNumBaths}
                type={type} setType={setType} />
        </Col>;
    }

    const listingPhotosAndBlockButtonJSX = () => {
        return <Col className='mt-2' xs='12' md='4'>
            <div className='d-none d-md-block'>
                {jahButtonGroupJSX()}
            </div>
            <p className='mt-1 py-2 text-center' style={{ color: 'lightgray' }}>Sort photos by dragging & dropping</p>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId='characters'>
                    {(provided) => (
                        <ul className='characters' style={{ alignItems: characters?.length == 0 ? 'center' : '', display: characters?.length == 0 ? 'flex' : '' }} {...provided.droppableProps} ref={provided.innerRef}>
                            {characters?.length > 0 ? characters.map(({ id, name, thumb }, index) => {
                                return (
                                    <Draggable key={id} draggableId={id} index={index}>
                                        {(provided) => (
                                            <li className='px-0' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <div className='characters-thumb'>
                                                    <img src={thumb} alt={`Thumb${id}`} />
                                                </div>
                                            </li>
                                        )}
                                    </Draggable>
                                );
                            }) : <div className='align-items-center d-flex justify-content-center h-100 w-100'>
                                <Image style={{ fontSize: '70px', color: 'lightgray' }} />
                            </div>}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
            <div className='d-sm-block d-md-none d-xl-none'>
                {jahButtonGroupJSX()}
            </div>
        </Col>;
    }

    const mobileTitleJSX = (title: string) => {
        return <div className='d-sm-block d-md-block d-lg-none d-xl-none w-100'>
            <h2 className='fw-bold mx-5 mt-4 mb-5 text-center'>{title}</h2>
        </div>;
    }

    const plusOrMinusJSX = (val: number, callBack: (newVal: any) => void, isDecimal?: boolean) => {
        if (isDecimal) {
            return <div className='d-flex'>
                <h4 className='cursor' onClick={() => val > .5 ? callBack(val - .5) : null}><DashCircle /></h4>
                <h5 className='fw-bold mt-1 mx-3'>{val}</h5>
                <h4 className='cursor' onClick={() => callBack(val + .5)}><PlusCircle /></h4>
            </div>;
        }
        return <div className='d-flex'>
            <h4 className='cursor' onClick={() => val > 1 ? callBack(val - 1) : null}><DashCircle /></h4>
            <h5 className='fw-bold mt-1 mx-3'>{val}</h5>
            <h4 className='cursor' onClick={() => callBack(val + 1)}><PlusCircle /></h4>
        </div>;
    }

    const reviewYourListingJSX = () => {
        return <div>
            <Row style={{ marginBottom: '500px' }}>
                <Col className='py-2' xs='12'>
                    <Header currentUser={currentUser} invoices={invoices} setInvoices={setInvoices}
                        notifications={notifications} setNotifications={setNotifications} setCurrentUser={setCurrentUser}></Header>
                </Col>
                <div className='pitty-titties'>
                    <Container fluid='xl'>
                        <Row>
                            <Col className='d-flex py-3' xs='12' md='6'>
                                {window?.innerWidth > 991 && listingId && <Lock style={{ marginTop: '2px', fontSize: '33px', color: 'gray' }} />}
                                <h3 className='addressy cursor flex-fill' style={{ color: listingId ? 'gray' : 'black' }} onClick={() => skipBack()}>
                                    {address}
                                </h3>
                            </Col>
                            <Col className='py-3' xs='12' md='6'>
                                <div className='d-flex flexible position-relative'>
                                    <h4 className='addressy'>Price:</h4>
                                    <div className='d-flex'>
                                        <CurrencyDollar className='dolla-dolla-bill-yall' />
                                        <Form.Control className='bigly-font fuck' onChange={(e) => setPrice(e.target.value as any)} placeholder='199' style={{ maxWidth: '110px' }} type='number' value={price} />
                                        <div className='mt-1'>
                                            <h4 className='addressy light-gray ml-2' style={{ opacity: '0.69' }}>/{type == 'Vacation rental' ? 'night' : 'month'}</h4>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Col className='pitty-titties' xs='12'>
                    <div>
                        <Container fluid='xl'>
                            <Row className='fuckcss'>
                                {listingInfoJSX()}
                                {listingPhotosAndBlockButtonJSX()}
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        </div>;
    }

    const selectPropertyRentalTypeJSX = () => {
        return jahStepJSX('What kind of property rental is this?', rentalTypes, type, setType);
    }

    const toastJSX = () => {
        return <ToastContainer className='m-3' position='top-end'>
            <Toast bg='success'>
                <Toast.Body className='d-flex fw-bold' style={{ color: 'white' }}>
                    <div className='flex-fill'>{showSuccessToast}</div>
                    <div>
                        <XCircle className='cursor ml-3' onClick={() => setShowSuccessToast('')} style={{ color: 'white' }} />
                    </div>
                </Toast.Body>
            </Toast>
        </ToastContainer>;
    }

    const whatKindOfSpaceJSX = () => {
        return jahStepJSX('What kind of space will your guests have?', spaceTypeList, spaceSelected, setSpaceSelected);
    }

    const whichBestDescribesYourPlaceJSX = () => {
        return jahStepJSX('Which of these best describes your place?', secondRentalTypes, category, setCategory);
    }

    return (
        <div className='vh-100' style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
            <Row>
                <Col className='mobile-no-padding-right' lg='12' style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
                    {generateContentBasedOffStep()}
                </Col>
            </Row>
            <ErrorModal continueClicked={() => continueClicked()} isOpen={showErrorModal} setIsOpen={setShowErrorModal} errors={[
                { description: description == '' ? 'description' : '' },
                { description: images?.length < 5 ? 'images' : '' },
                { description: Number(price) <= 0 || Number(price) > 99999 ? 'price' : '' }]} />
            <SuccessModal listingId={listingId} isOpen={showSuccessModal} setIsOpen={setShowSuccessModal} />
            <ConfirmDeleteModal callback={() => setDeleteListing(true)} isOpen={showConfirmDeleteModal} setIsOpen={setShowConfirmDeleteModal} />
            <EditCurrentUserModal callback={showConfirmToastAfterProfileEdit} isOpen={showUpdateCurrentUserModal} setIsOpen={setShowUpdateCurrentUserModal} />
            {showSuccessToast != '' && toastJSX()}
            <ChooseDateRangeModal allReservations={allReservations} callback={didSelectDates}
                isOpen={showChooseDateRangeModal} setIsOpen={setShowChooseDateRangeModal} />
            {showToast != '' ? <Toastville setShowToast={setShowToast} message={showToast} /> : <></>}
        </div>
    )
}
export default BecomeAHostPage;