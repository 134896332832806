import { useContext, useEffect, useState } from 'react';

import { Badge, Button, Col, Row, Table, Toast, ToastContainer } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import { House } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

import { ApiCalls } from '../models/apiCalls';
import ConfirmReservationModal from './ConfirmReservationModal';
import Context from '../models/Context';
import SeeIdModal from './SeeIdModal';
import { ReservationStatus } from '../models';

interface RessyProps {
    activeHook: number,
    invoices: any,
    isBookings: boolean,
    notifications: any,
    reservations: any[],
    setActiveHook: (val: number) => void,
}

const Reservations = (props: RessyProps) => {
    const { activeHook, invoices, isBookings, notifications, reservations, setActiveHook } = props;

    const auth = getAuth();
    const apiCalls = new ApiCalls();
    const { darkMode } = useContext(Context);
    const navigate = useNavigate();

    const [activeRequests, setActiveRequests] = useState<any>([]);
    const [image, setImage] = useState<any>(null);
    const [openConfirmReservationModal, setOpenConfirmReservationModal] = useState(false);
    const [openSeeIdModal, setOpenSeeIdModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [selectedReq, setSelectedReq] = useState<any>(null);

    useEffect(() => {
        if (notifications?.length > 0 && reservations?.length > 0) {
            checkIfReservationHasInvoiceAttached();
        } else {
            setActiveRequests(reservations);
        }
    }, [notifications, reservations]);

    useEffect(() => {
        if (showToast) {
            setTimeout(() => {
                setShowToast(false);
            }, 5150);
        }
    }, [showToast]);

    const accept = (l: any) => {
        setOpenConfirmReservationModal(true);
        setSelectedReq(l);
    }

    const acceptOrDenyReservation = (val: any, accept: boolean) => {
        if (!accept) {
            setOpenConfirmReservationModal(false);
            deny(val);
        } else {
            addInvoice(val);
            updateReservationToAccepted()
        }
    }

    const addInvoice = (obj: any) => {
        const newInvoice = {
            active: true,
            address: obj?.address,
            date: new Date(),
            email: obj?.guestEmail,
            listingId: obj.listingId,
            reservationId: obj.id,
            startDate: obj.startDate,
            endDate: obj.endDate,
            userId: auth?.currentUser?.uid,
            items: [
                {
                    amount: Number(obj.price) + '00',
                    currency: 'usd',
                    description: obj.listingId
                }
            ]
        };
        apiCalls.addInvoice(newInvoice)
        window.scrollTo(0, 0);
        updateReservationWithInvoiceData(obj, newInvoice);
        setShowToast(true);
    }

    const checkIfReservationHasInvoiceAttached = () => {
        const arr: any[] = [];
        reservations.forEach((r: any) => {
            const idx = invoices?.findIndex((n: any) => n?.listingId == r?.listingId && r?.guestEmail == n?.email);
            if (!isBookings) {
                if (idx == -1) arr.push(r);
            } else {
                if (idx > -1) arr.push({ ...r, invoice: idx > -1 ? { ...invoices[idx] } : null });
            }
        });
        setActiveRequests(arr);
    }

    const deny = (res: any) => {
        apiCalls.updateReservationToAcceptedOrDenied(res?.id, 'Denied');
        setActiveRequests(activeRequests.filter((f: any) => f.id != res.id));
        apiCalls.addNotification(res?.userId, ` denied your listing request`);
    }

    const generateRowAction = (l: any) => {
        if (l?.invoice) {
            const isPaid = l.invoice?.stripeInvoiceStatus == 'paid';
            return <div className='d-flex'>
                <Button className='flex-fill' onClick={() => navigate(`/booking/${l.listingId}/${l.guestEmail}`)} size='sm' variant={isPaid ? 'outline-primary' : 'outline-dark'}>
                    {isPaid ? 'Paid in full' : 'Invoice sent'}
                </Button>
            </div>
        }
        return <div className='d-flex'>
            <Button className='flex-fill me-1' onClick={() => deny(l)} size='sm' variant='outline-danger'>
                Deny
            </Button>
            <Button className='flex-fill' onClick={() => accept(l)} size='sm' variant='outline-primary'>
                Accept
            </Button>
        </div>;
    }

    const imageClicked = (i: any) => {
        setOpenSeeIdModal(true);
        setImage(i)
    }

    const updateReservationToAccepted = () => {
        apiCalls.updateReservationToAcceptedOrDenied(selectedReq?.id, 'Accepted');
    }

    //-----Pure JSX Functions-----//

    const badgeJSX = () => {
        return <div className='badges d-flex'>
            <Badge bg={'light'} className={activeHook === 0 ? 'active-badge' : ''} onClick={() => setActiveHook(0)} pill>
                Active ({activeRequests.filter((r: any) => r?.status !== ReservationStatus.Denied)?.length})
            </Badge>
        </div>;
    }
    const rowJSX = (l: any, index: number) => {
        return <tr key={`hucklberry-home-request-${index}`}>
            <td>{l.address}</td>
            <td className='cursor fw-bold' onClick={() => imageClicked(l.guestPhoto)}>
                <img className='mr-2' src={l.guestPhoto} style={{ height: '26px', borderRadius: '13px', width: '26px' }} />{l.guestName}
            </td>
            <td>{l.startDate} -<br /> {l.endDate}</td>
            <td>${l.price}</td>
            <td>{generateRowAction(l)}</td>
        </tr>;
    }

    const showEmptyMessageBasedOffBadgeTypeJSX = () => {
        if(isBookings) return <p>You don't have any<br />bookings yet.</p>
        switch (activeHook) {
            case 1: return <p>You don't have any completed<br />reservations yet.</p>;
            default: return <p>You don't have any active<br />reservations yet.</p>;
        }
    }

    const tableJSX = () => {
        const arr = activeRequests.filter((r: any) => r?.status !== ReservationStatus.Denied);
        return arr?.length > 0 ? <div className='w-100'>
            <Table responsive striped bordered hover>
                <thead>
                    <tr >
                        <th>Address</th>
                        <th>Guest</th>
                        <th>Checkin - Checkout</th>
                        <th>Pending Total</th>
                        <th>Manage</th>
                    </tr>
                </thead>
                <tbody>
                    {arr.map((l: any, index: number) => {
                        return rowJSX(l, index);
                    })}
                </tbody>
            </Table>
        </div> :
            <div className='py-5 text-center w-100'>
                <h2><House /></h2>
                {showEmptyMessageBasedOffBadgeTypeJSX()}
            </div>
    }

    const titleJSX = () => {
        return <div className='d-flex justify-content-betweeen'>
            {isBookings ? <h2 className='position-relative'>Upcoming bookings</h2> :
                        <h2 className='position-relative'>Requests</h2>}
        </div>;
    }

    const toastJSX = () => {
        return <ToastContainer className='m-3' position='top-end' >
            <Toast bg='success'>
                <Toast.Body style={{ color: 'white', fontWeight: '700' }}>
                    Invoice sent! We'll notify you once your guest pays the amount in full.
                </Toast.Body>
            </Toast>
        </ToastContainer>;
    }

    const updateReservationWithInvoiceData = (obj: any, newInvoice: any) => {
        let arr: any[] = [];
        activeRequests.forEach((r: any) => {
            arr.push({ ...r, invoice: obj?.listingId == r?.listingId ? newInvoice : null });
        });
        setActiveRequests(arr);
    }

    return <Row>
        <Col xs='12'>
            <div className='d-flex justify-content-between'>
                <div>{titleJSX()}</div>
                <div>{badgeJSX()}</div>
            </div>
            <div className={[activeRequests?.length == 0 ? 'd-flex align-items-center' : '', 'light-gray no-guests', ''].join(' ')}>
                {tableJSX()}
                <ConfirmReservationModal callback={acceptOrDenyReservation} isOpen={openConfirmReservationModal}
                    selectedReq={selectedReq} setIsOpen={setOpenConfirmReservationModal} />
                <SeeIdModal img={image} isOpen={openSeeIdModal} setIsOpen={setOpenSeeIdModal} />
            </div>
        </Col>
        {showToast && toastJSX()}
    </Row>;
}
export default Reservations;