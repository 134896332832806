



import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import '../styles/GeneralStyling.css';

interface IChooseDateRangeModalProps {
    isOpen: boolean,
    listingId?: string,
    setIsOpen: (val: boolean) => void
}

const SuccessModal = (props: IChooseDateRangeModalProps) => {
    const navigate = useNavigate();
    const { isOpen, listingId, setIsOpen } = props;

    //-----Pure JSX Functions-----//

    return (
        <>
            <Modal centered onHide={() => setIsOpen(false)} show={isOpen}>
                <Modal.Body>
                    <Row>
                        <Col className='mb-1' xs='12'>
                            <h4 className='mb-3 text-center'>Congratulations!</h4>
                            <p className='text-center'>Your listing was {listingId ? 'updated successfully' : 'saved successfully'}.</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex w-100'>
                        <Button className='flex-fill mr-1' onClick={() => navigate('/')} variant='outline-secondary'>Home</Button>
                        <Button className='flex-fill ml-1' onClick={() => navigate(`/listing/${listingId}`)} variant='success'>View my listing</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default SuccessModal;