
import { useContext, useState } from 'react';

import { Col, Container, Form, Row } from 'react-bootstrap';
import Context from '../models/Context';
import { ClipboardCheck, ClipboardCheckFill } from 'react-bootstrap-icons';

import Footer from '../components/Footer';
import Header from '../components/Header';

interface ICareersPageProps {
    currentUser: any,
    setCurrentUser: (val: any) => void
}

const CareersPage = (props: ICareersPageProps) => {
    const { currentUser, setCurrentUser } = props;
    const { darkMode } = useContext(Context);

    const [didCopy, setDidCopy] = useState(false);

    const didClickCopy = () => {
        navigator?.clipboard?.writeText('support@hucklberry.com');
        setDidCopy(true);
    }

    //-----Pure JSX Functions-----//

    const aboutUsJSX = () => {
        return <Container>
            <Row className='justify-content-md-center mt-3'>
                <Col xs='12' lg='7' xl='6'>
                    <h5 className='border-bottom fw-bold pb-3 mb-4'>Woot, we're hiring!</h5>
                    <Form>
                        <Form.Group className='mb-4 pb-4'>
                            <Form.Text className="text-muted">
                                <p className='border-bottom pb-3'>
                                    Below is a somewhat comprehensive list of current positions we need filled. If you live in Bozeman, great! If you dont, no worries at all. We're a remote first company, but we'd also love to see your shining face stop by our office. 
                                    <br/><br/>If you see a position that piques your interest, send your resume over to 
                                    <span className='fw-bold'> support@hucklberry.com {copyJSX()}</span>.<br/> 
                                    Even if you don't see anything, still feel free to reach out. We love having resumes on file, and you never know what kind of work we might be able to drum up.
                                </p>
                                <div className='border-bottom'>
                                    <br/>
                                    {backendJobJSX()}
                                    <br />
                                </div>
                                <div className='border-bottom'>
                                    <br/>
                                    {frontendJSX()}
                                    <br />
                                </div>
                                <div className='border-bottom'>
                                    <br/>
                                    {internJSX()}
                                    <br />
                                </div>
                                <div className='border-bottom'>
                                    <br/>
                                    {salesJSX()}
                                    <br />
                                </div>
                                <div className='border-bottom'>
                                    <br/>
                                    {socialMediaCoordJSX()}
                                    <br />
                                </div>
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>;
    }

    const backendJobJSX = () => {
        return <div>
            <div className='d-flex fw-bold justify-content-between mb-2'>
                <p><u>Backend Engineer</u></p>
                <p><u>130k/year</u></p>
            </div>
            <p>
                This is a fully remote salaried position managing our backend infrastructure. Our back end is a sweet combination of Google Cloud Platform services && Firebase, 
                any eligible candidate would have a deep understanding of optimizing databases, particularly in GCP/Firebase. Must have 4 years of professional experience, 
                or a super amazing portfolio.
            </p>
        </div>;
    }

    const copyJSX = () => {
        return <>
            {didCopy ? <ClipboardCheckFill className='cursor ms-2' /> : <ClipboardCheck className='cursor ms-2' onClick={() => didClickCopy()} />}
            {didCopy && <span className='ms-2' style={{ fontSize: '10px', color: 'green' }}><u>COPIED!</u></span>}
        </>;
    }

    const frontendJSX = () => {
        return <div>
            <div className='d-flex fw-bold justify-content-between mb-2'>
                <p><u>Frontend Developer</u></p>
                <p><u>130k/year</u></p>
            </div>
            <p>
                This is a fully remote salaried position designing and developing our apps front end, which is built in React && Typescript. An eligible candidate should have at least 4 years of
                professional front end coding experience, or a super amazing portfolio. Must be an expert in writing functional (not class based) React. <br/><br/> 
                PS: We think Redux is sooo 2018... Context API ftw ;)
            </p>
        </div>;
    }

    const internJSX = () => {
        return <div>
            <div className='d-flex fw-bold justify-content-between mb-2'>
                <p><u>Software Internship</u></p>
                <p><u>$30/hour</u></p>
            </div>
            <p>
                This is a part time, hourly internship with a heavy focus on HTML, Javascript & CSS. An eligible candidate would currently be enrolled
                at Montana State in the Computer Science department, or in your moms basement with a curiousity for coding and passion for learning. This role will shadow one of our senior front end developers. Must have a great attitude and a desire to learn!
                No coding experience required. Will be approximately 15-20 hours a week.
            </p>
        </div>;
    }

    const salesJSX = () => {
        return <div>
            <div className='d-flex fw-bold justify-content-between mb-2'>
                <p><u>Sales Internship</u></p>
                <p><u>$30/hour</u></p>
            </div>
            <p>
                This is a part time, hourly position to help us continue to lock down new clients. An eligible candidate would be super personable, 
                and be willing to handle tasks ranging from customer support to prospecting new customers. In this role you will shadow one of our senior sales engineers.
                Will be approximately 15-20 hours per week.
            </p>
        </div>;
    }

    const socialMediaCoordJSX = () => {
        return <div>
            <div className='d-flex fw-bold justify-content-between mb-2'>
                <p><u>Social Media Coordinator</u></p>
                <p><u>$25/hour</u></p>
            </div>
            <p>
                This is a part time, hourly position to help us manage and grow our social media pages. Must have experience managing and retaining 
                followers on Instagram & Twitter, as well as basic knowledge in Adobe Illustrator or Photoshop. Will be approximately 10-15 hours a week.
            </p>
        </div>;
    }

    return (
        <>
            <div className='pb-5' style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
                <Row>
                    <Col xs='12'>
                        <Header currentUser={currentUser} hideCenter={true}
                            setCurrentUser={setCurrentUser} />
                    </Col>
                    <Col className='border-top header-padding pt-4 mt-1' lg='12'>
                        {aboutUsJSX()}
                    </Col>
                </Row>
            </div>
            <Footer />
        </>
    )
}
export default CareersPage;