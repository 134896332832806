
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Context from './Context';
import Sidenav from '../components/Sidenav';
import { EyeSlash, EyeSlashFill } from 'react-bootstrap-icons';

export class Utilities {
    context = useContext(Context);
    navigate = useNavigate();

    calculateServiceFees(total: number) {
        let fee = (total * 1.029) - total;
        fee =  Math.round(fee + 5);
        if(fee % 2 == 1) {
            fee = fee + 1;
        }
        return fee;
    }

    calculateTotal (total: number) {
        const myTotal = (this.calculateServiceFees(total)) + total;
        return Math.round(myTotal);
    }

    formatNumberToShowDollazAndCentz (items: any) {
        return items && items?.length > 0 ? Number(items[0].amount / 100).toFixed(2) : 0;
    }

    getNextMonth(day: number, month: number, year: number) {
        return new Date(year, month + 1, day).getMonth();
    }

    getNextMonthFull(day: number, month: number, year: number) {
        return new Date(year, month + 1, day);
    }

    privateJSX() {
        return <span className='ms-2' style={{ color: '#ff5349', fontSize: '14px' }}><EyeSlash /></span>;
    }

    titleJSX() {
        return <>
            <div className='d-flex'>
                <Sidenav />
            </div>
        </>;
    }

}

export enum ReservationStatus {
    Blocked = 'Blocked',
    Denied='Denied',
    Requested = 'Requested',
}