import { useContext, useState } from 'react';

import { getAuth, signOut } from 'firebase/auth';
import { Badge, ListGroup, Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Context from '../models/Context';

const Sidenav = () => {
    const auth = getAuth();
    const { darkMode } = useContext(Context);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const logout = () => {
        signOut(auth).then(() => {
            navigate('/signinchoose');
        });
    }

    //-----Pure JSX Functions-----//

    const rowJSX = (title, path) => {
        const isThisPage = (window?.location?.pathname == path);
        return <ListGroup.Item className={['cursor py-0', title == 'HOME' ? 'pt-3' : ''].join(' ')} onClick={() => isThisPage ? setShow(false) : navigate(path)}>
            <Badge bg={isThisPage ? 'primary' : 'light'} text={isThisPage ? 'light' : 'dark'} className='mb-2 p-2 w-100'>{title}</Badge>
        </ListGroup.Item>
    }

    return (
        <>
            <h1 className='title huckleberry-small d-flex  d-none d-lg-block' onClick={() => setShow(true)} style={{ color: '#59CBE8'}}>
                hucklBerry
            </h1>
            <div className='pt-1 titleXL d-lg-none ms-2' onClick={handleShow} style={{ color: darkMode ? '#59CBE8' : '#C724B1' }}>H</div>
            <Offcanvas className={darkMode ? 'dark' : ''} onHide={handleClose} show={show}>
                <Offcanvas.Header className='mb-3 pb-0' closeButton>
                    <Offcanvas.Title className='fw-bold' onClick={() => setShow(false)}>Hucklberry</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='px-0'>
                    <ListGroup>
                        {rowJSX('HOME', '/')}
                        {auth?.currentUser && rowJSX('ACCOUNT', '/account')}
                        {auth?.currentUser?.phoneNumber ? rowJSX('CREATE LISTING', '/become-a-host') : rowJSX('MAP', '/map')}
                        {rowJSX('FAQ', '/faq')}
                        {rowJSX('CONTACT', '/contact')}
                        {auth?.currentUser ? <ListGroup.Item className='px-3 py-0'>
                            <Badge bg='light' text='dark' className='mb-3 p-2 w-100' onClick={() => logout()}>LOGOUT</Badge>
                        </ListGroup.Item> : rowJSX('LOGIN', '/signinchoose')}
                    </ListGroup>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Sidenav;