
import { useContext, useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { ApiCalls } from '../models/apiCalls';
import Context from '../models/Context';
import Header from '../components/Header';
import Maps from '../components/Map';

interface IMapPageProps {
    currentUser: any,
    setCurrentUser: (num: any) => void
}

const MapPage = (props: IMapPageProps) => {
    const { currentUser, setCurrentUser } = props;
    const { darkMode } = useContext(Context);
    
    let params = new URLSearchParams(window.location.search);
    const addr = params?.get('addr');
    const lat = params?.get('lat');
    const lng = params?.get('lng');
    const chosenRentalType = params?.get('type');
    
    const apiCalls = new ApiCalls;
    const auth = getAuth();

    const address = addr ? addr : ''
    const [addressSelectedFromMap, setAddressSelectedFromMap] = useState<any>({ 
        lat: lat ? parseFloat(lat as any) : 45.68, lng: lng ? parseFloat(lng as any) : -111.04
    });
    const [invoices, setInvoices] = useState<any>([]);
    const [listOfListings, setListOfListings] = useState<any>([]);
    const [notifications, setNotifications] = useState<any>([]);

    onAuthStateChanged(auth, user => {
        if(user) {
            setCurrentUser(user);
        }
    });

    useEffect(() => {
        if(addressSelectedFromMap) {
            getListings();
        }
    }, [addressSelectedFromMap]);

    const getListings = async (e?: any) => {
        const listings = await apiCalls.getMapListings(addressSelectedFromMap);
        const arr: any[] = [];
        listings?.map((l: any) => {
            arr.push({ ...l });
        });
        if(listings?.length > 0) {
            setListOfListings(listings);
        }
    }

    //-----Pure JSX Functions-----//

    return (
        <div className='mb-2' style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
            <Row>
                <Col className='border-bottom pb-2' lg='12'>
                    <Header currentUser={currentUser}  invoices={invoices} 
                        setInvoices={setInvoices} notifications={notifications}
                        rentalType={chosenRentalType} setNotifications={setNotifications} 
                        setCurrentUser={setCurrentUser} />
                </Col>
                <Col xs='12'>
                    <Maps address={address} listOfListings={listOfListings} onMapPage={true}
                        selected={addressSelectedFromMap} setSelected={setAddressSelectedFromMap} />
                </Col>
            </Row>
        </div>
    )
}
export default MapPage;