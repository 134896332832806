
import { useContext, useEffect, useState } from 'react';

import { Col, Collapse, Container, Form, Row } from 'react-bootstrap';
import { CaretDownSquareFill, CaretRightSquareFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

import Context from '../models/Context';
import Footer from '../components/Footer';
import Header from '../components/Header';

interface IFAQPageProps {
    currentUser: any,
    setCurrentUser: (val: any) => void
}

const FAQPage = (props: IFAQPageProps) => {
    const { currentUser, setCurrentUser } = props;

    const { darkMode } = useContext(Context);
    const navigate = useNavigate();

    const [showQuestion1, setShowQuestion1] = useState(false);
    const [showQuestion2, setShowQuestion2] = useState(false);
    const [showQuestion3, setShowQuestion3] = useState(false);
    const [showQuestion4, setShowQuestion4] = useState(false);
    const [showQuestion5, setShowQuestion5] = useState(false);
    const [showQuestion6, setShowQuestion6] = useState(false);

    useEffect(() => {
        window?.scrollTo(0, 0);
    }, []);

    //-----Pure JSX Functions-----//

    const aboutUsJSX = () => {
        return <Container>
            <Row className='justify-content-md-center mt-3'>
                <Col xs='12' lg='7' xl='6'>
                    <h5 className='border-bottom fw-bold pb-3'>Frequently asked questions</h5>
                    <Form>
                        <Form.Group className='mb-4 pb-4'>
                            <Form.Text className='text-muted'>
                                {questionJSX('How is Hucklberry different?', showQuestion1, setShowQuestion1,
                                    'Hucklberry allows you to do both short-term vacation rentals, as well as year long rentals with lease functionality. We also have much lower service fees than AirBnb, as well as cleaning fees built into the nightly price of each listing.')}
                                {questionJSX('Is it safe?', showQuestion2, setShowQuestion2,
                                    'Safety has always been our utmost priority. We validate the identities of all of our users for full transparency between owners and their guests. With that said, we still think you should use common sense when booking a place. If you show up and things feel unsafe, call your local police department, and notify us immediately. Well refund you the full amount, as well as help you to find alternative accomodations.')}
                                {questionJSX('Where was Hucklberry built?', showQuestion3, setShowQuestion3,
                                    'Proudly this application was designed, developed, and tested entirely in Bozeman, MT by a team of amazing humans. Despite being a remote first company, our whole engineering team calls this place home.')}
                                {questionJSX('How much do you charge for service fees?', showQuestion4, setShowQuestion4,
                                    'For vacation rentals with no lease, there is a 2.9% processing fee from Stripe. On top of that we take a maximum of $5 per booking. If youre rental is long term (with a lease) though, youll be doing all payments through Venmo which is 100% free ;)')}
                                {questionJSX('Why isnt Hucklberry available outside the US?', showQuestion5, setShowQuestion5,
                                    'The core tenants of our business are safe spaces and fair pricing. We feel that by operating exculsively within the US, we can ensure the highest standards of safety and accountability for all of our customers.')}
                                <div className='border-bottom cursor pb-4' onClick={() => setShowQuestion6(!showQuestion6)}>
                                    <br />
                                    <div className='d-flex fw-bold justify-content-between'>
                                        <p className='mb-0'>Are you hiring?</p>
                                        <div>
                                            {showQuestion6 ? <CaretDownSquareFill /> : <CaretRightSquareFill />}
                                        </div>
                                    </div>
                                    <div>
                                        <Collapse in={showQuestion6}>
                                            <div>
                                                <div className='mt-3'>
                                                    We most certainly are! Check out our 
                                                    <b onClick={() => navigate('/careers')} style={{ color: 'blue' }}> careers </b> 
                                                    page for more info
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>;
    }

    const questionJSX = (question: string, hook: boolean, setHook: (val: boolean) => void, message: any) => {
        return <div className='border-bottom cursor pb-4' onClick={() => setHook(!hook)}>
            <br />
            <div className='d-flex fw-bold justify-content-between'>
                <p className='mb-0'>{question}</p>
                <div>
                    {hook ? <CaretDownSquareFill /> : <CaretRightSquareFill />}
                </div>
            </div>
            <div>
                <Collapse in={hook}>
                    <div>
                        <div className='mt-3'>
                            {message}
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>;
    }

    return (
        <>
            <div className='pb-5' style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
                <Row>
                    <Col xs='12'>
                        <Header currentUser={currentUser} hideCenter={true}
                            setCurrentUser={setCurrentUser} />
                    </Col>
                    <Col className='border-top header-padding pt-4 mt-1' lg='12'>
                        {aboutUsJSX()}
                    </Col>
                </Row>
            </div>
            <Footer />
        </>
    )
}
export default FAQPage;