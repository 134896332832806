import { useContext } from 'react';

import { Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Context from '../models/Context';
import Footer from '../components/Footer';
import Header from '../components/Header';
import huck from '../images/america.png';

interface IAboutPageProps {
    currentUser: any,
    setCurrentUser: (val: any) => void
}

const AboutPage = (props: IAboutPageProps) => {
    const { currentUser, setCurrentUser } = props;
    const { darkMode } = useContext(Context);

    const navigate = useNavigate();

    //-----Pure JSX Functions-----//

    const aboutUsJSX = () => {
        return <Container>
            <Row className='justify-content-md-center mt-3'>
                <Col xs='12' lg='7' xl='6'>
                    <h5 className='border-bottom fw-bold pb-3 mb-4'>About Hucklberry.com</h5>
                    <Form>
                        <Form.Group className='border-bottom mb-4 pb-4'>
                            <Form.Text className={darkMode ? 'text-lightgray' : 'text-muted'}>
                                <div className='border-bottom fw-bold'>The fastest, most efficient way to rent a home online.<br /><br /></div>
                                <div className='border-bottom'>
                                    <br />
                                    We believe that service fees should not be hundreds of dollars. Travel is already expensive as it is, and the last thing
                                    you need is some billion dollar software company charging you 10% of your booking price just so they can buy another
                                    golden toilet. It's not rocket science, it's a freakin website. You should be charged accordingly.<br /><br />
                                    What? Fair pricing? That's a crazy concept. This is the
                                    idea that Hucklberry was born from. Make an amazing product, don't screw people over, and have some fun along the way.
                                    <br /><br />
                                </div>
                                <div className='border-bottom'>
                                    <br />
                                    We are a proudly US based company that chooses to do business exclusively within the United States. We do this because well,
                                    <span className='cursor fw-bold' onClick={() => window.open('https://en.wikipedia.org/wiki/United_States', '_blank')}> 'Merica</span> and
                                    also because it allows us to ensure the highest standards of safety and accountability for our customers.
                                    <br /><br />
                                    For more information visit our <span className='cursor fw-bold' onClick={() => navigate('/faq')}>FAQ Page</span>. To speak directly to our team,
                                    <span className='cursor fw-bold' onClick={() => navigate('/contact')}> Contact Us</span>.
                                    <br /><br />
                                </div>
                                <br />
                                <div className='d-flex justify-content-center'>
                                    <div className='my-3 text-center'>
                                        <img className='flag-1 mb-2' src={huck} alt='user-profile-image' />
                                        <p className='fw-bold text-center'>Hucklberry.com</p>
                                        <p className='text-center'>PO Box 1286</p>
                                        <p className='text-center'>Three Forks, MT 59752</p>
                                        <p className='text-center'>support@hucklberry.com</p>
                                        <p className='text-center'>(406) 898-7716</p>
                                    </div>
                                </div>
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>;
    }

    return (
        <>
            <div className='pb-5' style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
                <Row>
                    <Col xs='12'>
                        <Header currentUser={currentUser} hideCenter={true}
                            setCurrentUser={setCurrentUser} />
                    </Col>
                    <Col className='border-top header-padding pt-4 mt-1' lg='12'>
                        {aboutUsJSX()}
                    </Col>
                </Row>
            </div>
            <Footer />
        </>
    )
}
export default AboutPage;