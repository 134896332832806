import { useContext, useEffect, useRef, useState } from "react";

import { ArrowLeftSquareFill, ArrowRightSquareFill, Check2Circle, DoorClosed, Github, HouseDoor, InfoCircle, FilePdf, Building } from 'react-bootstrap-icons';
import { Button, Col, Container, Form, Overlay, Row, Spinner, Tooltip } from 'react-bootstrap';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, useParams } from "react-router-dom";

import { ApiCalls } from '../models/apiCalls';
import BookListingModal from '../components/BookListingModal';
import ChooseDateRangeModal from '../components/ChooseDateRangeModal';
import Context from '../models/Context';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Maps from '../components/Map';
import { ReservationStatus, Utilities } from '../models'
import ShowWhoopsModal from '../components/ShowWhoopsModal';
import ToastVille from "../components/Toast";

interface IListingPropsPage {
    currentUser: any,
    setCurrentUser: (user: any) => void
}

const ListingPage = (props: IListingPropsPage) => {
    const { currentUser, setCurrentUser } = props;

    const apiCalls = new ApiCalls();
    const auth = getAuth();
    const { darkMode } = useContext(Context);
    const { listingId } = useParams();

    let params = new URLSearchParams(window.location.search);
    const didEditListing = params.get('didEditListing');
    const isBooking = params.get('isBooking');
    const target = useRef(null);
    const utils = new Utilities();

    const navigate = useNavigate();

    const [address, setAddress] = useState('');
    const [addressSelectedFromMap, setAddressSelectedFromMap] = useState<any>({ lat: 45.68, lng: -111.04 });
    const [didChooseDates, setDidChooseDates] = useState<any>(false);
    const [invoices, setInvoices] = useState<any>([]);
    const [notifications, setNotifications] = useState<any>([]);
    const [showAlert, setShowAlert] = useState(didEditListing ? `We're updating your listing as we speak!` : '');
    const [showInfoTooltip, setShowInfoTooltip] = useState(false);
    const [showWhoopsModal, setShowWhoopsModal] = useState(false);

    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    const [allReservations, setAllReservations] = useState<any>([]);
    const [endDate, setEndDate] = useState<any>(tomorrow);
    const [fullScreenImage, setFullScreenImage] = useState<any>(null);
    const [currentUserBooking, setCurrentUserBooking] = useState<boolean>(isBooking ? true : false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [listing, setListing] = useState<any>(null);
    const [listingReservation, setListingReservation] = useState<any>(null);
    const [newlyPaidInvoices, setNewlyPaidInvoices] = useState<any>([]);
    const [numGuests, setNumGuests] = useState<any>(2);
    const [openDateRangeModal, setOpenDateRangeModal] = useState(false);
    const [reserveNumNights, setReserveNumNights] = useState(1);
    const [reviewCount, setReviewCount] = useState(0);
    const [startDate, setStartDate] = useState<any>(new Date());
    const [total, setTotal] = useState(0);

    onAuthStateChanged(auth, user => {
        if (user) {
            setCurrentUser(user);
        }
    });

    useEffect(() => {
        window?.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (listingId) {
            getListingById();
            getReviews();
        }
    }, [listingId, showAlert]);

    useEffect(() => {
        if (auth?.currentUser) {
            setCurrentUser(auth.currentUser)
            if (auth.currentUser.email) {
                checkIfCurrentUserHasReservation();
                getNewlyPaidInvoices();
            }
            getAllReservationsForThisListing();
        }
    }, [auth?.currentUser]);

    useEffect(() => {
        if (startDate && endDate && listing) {
            const diffInMs = endDate - startDate;
            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
            const numNights = diffInDays;
            if (listing?.type == 'Vacation rental') {
                setReserveNumNights(numNights == 0 ? 1 : numNights);
            } else {
                const stay = numNights / (listing?.type == 'Year-long lease' ? 1 : 30);
                setReserveNumNights(363 < stay && stay < 367 ? 365 : stay);
            }
        }
    }, [endDate, listing, startDate]);

    useEffect(() => {
        if (listing) {
            setAddress(listing.address);
            setAddressSelectedFromMap({ lat: listing.lat, lng: listing.lng });
            if (showAlert == '') setIsLoading(false);
            setEndDate(checkEndDate(tomorrow));
        }
    }, [listing]);

    useEffect(() => {
        if (openDateRangeModal) {
            getAllReservationsForThisListing();
        }
    }, [openDateRangeModal]);

    useEffect(() => {
        const totalBeforeFee = Math.round(listing?.price * Number(calcReserve()));
        setTotal((listing?.type == 'Year-long lease' ? (totalBeforeFee * 2) : totalBeforeFee) + utils.calculateServiceFees(totalBeforeFee));
    }, [reserveNumNights]);

    const checkEndDate = (def: any) => {
        const nextMonth = utils.getNextMonthFull(startDate.getDate(), startDate.getMonth(), startDate.getFullYear());
        const nextYear = utils.getNextMonthFull(startDate.getDate(), startDate.getMonth() - 1, startDate.getFullYear() + 1);
        return listing?.type == 'Vacation rental' ? def : (listing?.type == 'Year-long lease' ? nextYear : nextMonth);
    }

    const checkIfCurrentUserHasReservation = async () => {
        const reqs = await apiCalls.getReservation(auth.currentUser?.email, listingId);
        setListingReservation(reqs?.length > 0 ? reqs[0] : null);
    }

    const clearDatesCallback = () => {
        setDidChooseDates(false);
        setEndDate(checkEndDate(tomorrow));
        setStartDate(today);
    }

    const didSelectDates = async (start: any, end: any) => {
        if (auth?.currentUser?.phoneNumber) {
            if (start != 'didDelete') {
                await apiCalls.addReservation(listingId, listing, 0, 0, new Date(start), new Date(end), 0, ReservationStatus.Blocked, true);
                setShowAlert('Time blocked out successfully.');
            } else {
                setShowAlert('Time unblocked successfully.');
            }
            return;
        }
        if (end > start) {
            setStartDate(start);
            setEndDate(end);
            setDidChooseDates(true);
        }
    }

    const differenceInDays = () => {
        const oneDay = 1000 * 60 * 60 * 24;
        const diffInTime = new Date(listingReservation?.startDate).getTime() - new Date().getTime();
        return Math.ceil(diffInTime / oneDay);
    }

    const fullScreenClicked = (val: any) => {
        setFullScreenImage(val);
        setIsFullScreen(true);
    }

    const getListingById = async () => {
        const listingData = await apiCalls.getListingById(listingId as any);
        setListing(listingData);
    }

    const getAllReservationsForThisListing = async () => {
        const res = await apiCalls.getAllReservationsByListingId(listingId);
        setAllReservations(res);
    }

    const getNewlyPaidInvoices = async () => {
        const invs = await apiCalls.getPaidInvoice(auth?.currentUser?.email as any);
        setNewlyPaidInvoices(invs);
    }

    const getReviews = async () => {
        const reviewData = await apiCalls.getReviewsByListingId(listingId);
        setReviewCount(reviewData?.length > 0 ? reviewData.length : 0);
    }

    const getTypeOfPlace = () => {
        switch (listing?.spaceSelected) {
            case 'A private room': return <div className='ml-3'>
                <h4>A private room</h4>
                <small className='opacity-50'>You will be have your own private room but with common areas.</small>
            </div>;
            case 'A shared room': return <div className='ml-3'>
                <h4>Shared room</h4>
                <small className='opacity-50'>You will be sharing a room.</small>
            </div>;
            default: return <div className='ml-3'>
                <h4>An entire place</h4>
                <small className='opacity-50'>Introvert much? Sounds great because you got the whole place to yourself!</small>
            </div>;
        }
    }

    const lastPhotoClicked = () => {
        if (fullScreenImage != null) {
            if ((fullScreenImage - 1) <= 0) {
                setFullScreenImage(listing?.images?.length - 1);
            } else {
                setFullScreenImage(fullScreenImage - 1);
            }
        }
    }

    const nextPhotoClicked = () => {
        if (fullScreenImage != null) {
            if ((fullScreenImage + 1) == listing?.images?.length) {
                setFullScreenImage(0);
            } else {
                setFullScreenImage(fullScreenImage + 1);
            }
        }
    }

    const perUnitTime = () => {
        switch (listing?.type) {
            case 'Year-long lease': return 'month';
            case 'Month-to-month': return 'month';
            case 'Sublet room': return 'month';
            default: return 'night';
        }
    }

    const reserveClicked = () => {
        if (auth?.currentUser) {
            setCurrentUserBooking(true);
        } else {
            setShowWhoopsModal(true);
        }
    }

    const roundStayLength = () => {
        if (listing?.type == 'Vacation rental') return Math.floor(reserveNumNights) + ` night${Math.floor(reserveNumNights) == 1 ? '' : 's'}`;

        const remainder = reserveNumNights % 1;
        if (listing?.type == 'Year-long lease') {
            const yrs = reserveNumNights / 365;
            const dayRem = Math.floor((reserveNumNights - 365) / 30);
            return reserveNumNights == 365 ? '1 year lease' : Math.floor(yrs) + ` year ${dayRem as any} month${dayRem == 1 ? '' : 's'}`
        }
        let numMonths = reserveNumNights.toFixed(2) as any;
        if (remainder <= 0.1) numMonths = Math.floor(numMonths as any);
        if (remainder > 0.1 && remainder <= 0.35) numMonths = Math.floor(numMonths as any) + '.25';
        if (remainder > 0.35 && remainder <= 0.65) numMonths = Math.floor(numMonths as any) + '.5';
        if (remainder > 0.65 && remainder <= 0.9) numMonths = Math.floor(numMonths as any) + '.75';
        if (remainder > 0.9) numMonths = Math.floor(numMonths as any) + 1;
        return `${numMonths} month${numMonths == 1 ? '' : 's'}`
    }

    const saveReservation = async (data: any) => {
        window?.scroll(0, 0);
        const newReservationId = await apiCalls.addReservation(listingId, listing, numGuests, utils.calculateTotal(total), startDate, endDate, reserveNumNights, ReservationStatus.Requested);
        await apiCalls.addNotification(listing.userId, `sent a reservation request.`, newReservationId);
        setShowAlert('We just sent off your reservation. We will notify when the owner approves or denies your request.');
        setCurrentUserBooking(false);
        checkIfCurrentUserHasReservation();
    }

    //-----Pure JSX Functions-----/

    const featuresAndDescriptionJSX = () => {
        return <>
            {titleJSX()}
            <div className='d-block d-lg-none py-3'>
                {showReservationOrPaidJSX()}
            </div>

            <div className='border-bottom'>
                <div className='border-bottom d-flex py-3'>
                    <DoorClosed style={{ fontSize: '30px', marginTop: '8px' }} />
                    {getTypeOfPlace()}
                </div>
                <div className='d-flex py-3'>
                    <Github style={{ fontSize: '30px', marginTop: '8px' }} />
                    <div className='ml-3'>
                        <h4>{listing?.petsAllowed ? 'Pets allowed' : 'No pets allowed'}</h4>
                        <small className='opacity-50'>{listing?.petsAllowed ? 'Must be well behaved and quiet.' : 'Service animals only.'}</small>
                    </div>
                </div>
            </div>
            {listing?.lease ? <div className='border-bottom'>
                <div className='border-bottom d-flex py-3'>
                    <FilePdf style={{ fontSize: '30px', marginTop: '8px' }} />
                    <div className='ms-3'>
                        <h4>Lease required</h4>
                        <small>This owner requires a lease to stay at their property.</small>
                    </div>
                </div>
            </div> : <div className='border-bottom'>
                <div className='border-bottom d-flex py-3'>
                    <FilePdf style={{ fontSize: '30px', marginTop: '8px' }} />
                    <div className='ms-3'>
                        <h4>No lease required</h4>
                        <small className='opacity-50'>Sweet! This owner does not require a lease to stay at their property.</small>
                    </div>
                </div>
            </div>}
            <div className='ms-3 mt-5'>
                {listing.description}
            </div>
        </>;
    }

    const imagesJSX = () => {
        return <Row className='cursor mb-4'>
            <Col xs='12' sm='6'>
                {listing?.images?.length > 0 && <img onClick={() => fullScreenClicked(0)} src={listing.images[0].thumb}
                    className='hucklberry-home-photo mb-2' style={{ borderRadius: '2%' }} alt={`hucklberry-home-photo`} />}
            </Col>
            <Col className='lee-wook' xs='12' sm='6'>
                {listing?.images?.length > 4 ? <div>
                    <Row>
                        <Col className='mb-2 piggy pr-1' xs='6'>
                            <img className='hucklberry-home-photo-half'
                                onClick={() => fullScreenClicked(1)} src={listing.images[1].thumb} alt={`hucklberry-home-photo-half`} />
                        </Col>
                        <Col className='mb-2 pl-1' xs='6'>
                            <img className='hucklberry-home-photo-half' onClick={() => fullScreenClicked(2)} src={listing.images[2].thumb} alt={`hucklberry-home-photo-half`} />
                        </Col>
                        <Col className='piggy pr-1' xs='6'>
                            <img className='hucklberry-home-photo-half' onClick={() => fullScreenClicked(3)} src={listing.images[3].thumb} alt={`hucklberry-home-photo-half`} />
                        </Col>
                        <Col className='pl-1' xs='6'>
                            <img className='hucklberry-home-photo-half' onClick={() => fullScreenClicked(4)} src={listing.images[4].thumb} alt={`hucklberry-home-photo-half`} />
                        </Col>
                    </Row>
                </div> : <></>}
            </Col>
            {isFullScreen && <div className='full-page-image'>
                <div className='jahmon position-relative'>
                    <ArrowLeftSquareFill className='fixed-left' onClick={() => lastPhotoClicked()} />
                    <img onClick={() => setIsFullScreen(!isFullScreen)} src={listing.images[fullScreenImage ? fullScreenImage : 0].thumb} />
                    <ArrowRightSquareFill className='fixed-right' onClick={() => nextPhotoClicked()} />
                </div>
            </div>}
        </Row>;
    }

    const listingJSX = () => {
        return <div>
            <div>
                {imagesJSX()}
                {!isFullScreen && <Row>
                    <Col xs='12' lg='8'>
                        {featuresAndDescriptionJSX()}
                    </Col>
                    <Col className='d-none d-lg-block' xs='4'>
                        {showReservationOrPaidJSX()}
                    </Col>
                </Row>}
            </div>
        </div>;
    }

    const mainContentJSX = () => {
        return <Row>
            <Col className='mb-3' xs='12'>
                <Header currentUser={currentUser} setInvoices={setInvoices} invoices={invoices} notifications={notifications} setNotifications={setNotifications} setCurrentUser={setCurrentUser} />
            </Col>
            <Col className='p-teezley mb-5' xs='12' style={{ minHeight: '70vh' }}>
                {isLoading ? <div className='align-items-center d-flex h-100'>
                    <div className='text-center w-100'>
                        <Spinner animation='border' variant='secondary' />
                    </div>
                </div>
                    :
                    <div>
                        <Container fluid='xl'>
                            {listing && listingJSX()}
                        </Container>
                    </div>}
            </Col>
            {!isFullScreen && listing?.lat && !isLoading && <Col xs='12'>
                <Container fluid='xl'>
                    <Maps address={address} listOfListings={[listing]} onMapPage={true}
                        selected={addressSelectedFromMap} setSelected={setAddressSelectedFromMap} />
                </Container>
            </Col>}
            {!isFullScreen && <Footer />}
        </Row>;
    }

    const calcReserve = () => {
        if (listing?.type == 'Year-long lease') return reserveNumNights / 365;
        if (listing?.type == 'Sublet room' || listing?.type == 'Month-to-month') return Math.floor(reserveNumNights);
        return reserveNumNights;
    }

    const makeReservationJSX = () => {
        const priceTimesNights = (Math.round(listing?.price * calcReserve())) ?? 1;
        return <div className='reservation-card p-2'>
            <div className='d-flex justify-content-between'>
                {showAlert ? <>
                </> : <h4 className='m-3'>${listing.price}/{perUnitTime()}</h4>}
                {!auth?.currentUser?.phoneNumber && <h4 className='m-3'>{roundStayLength()}</h4>}
            </div>
            {auth?.currentUser?.phoneNumber ? <>
                {auth?.currentUser?.uid == listing?.userId ? <div className='mb-2 mx-2'>
                    <Button className='w-100' onClick={() => setOpenDateRangeModal(true)} variant='outline-danger'>Block Nights</Button>
                    <Button className='mt-2 w-100' onClick={() => navigate(`/become-a-host/${listing.id}`)} variant='outline-primary'>Edit listing</Button>
                    <Button className='mt-2 w-100' onClick={() => navigate('/contact')} variant='outline-secondary'>Report an issue</Button>
                </div> : <> </>}
            </> : <>
                <div className='d-flex justify-content-between px-3 w-100'>
                    <div className='flex-fill'>
                        <Form.Label className='mb-0 light-gray' style={{ fontSize: '11px' }}>Check in</Form.Label>
                        <Button className='w-100' onClick={() => setOpenDateRangeModal(true)} variant={darkMode ? 'outline-light' : 'outline-dark'}>
                            {startDate.toLocaleDateString('us', { month: 'short', day: 'numeric', weekday: 'short' })}
                        </Button>
                    </div>
                    <div className='flex-fill ps-2'>
                        <Form.Label className='mb-0 light-gray' style={{ fontSize: '11px' }}>Check out</Form.Label>
                        <Button className='w-100' onClick={() => setOpenDateRangeModal(true)} variant={darkMode ? 'outline-light' : 'outline-dark'}>
                            {endDate.toLocaleDateString('us', { month: 'short', day: 'numeric', weekday: 'short' })}
                        </Button>
                    </div>
                </div>
                <div className='mx-3'>
                    <Form.Label className='ms-1 mb-0 light-gray' style={{ fontSize: '11px' }}>Number of guests</Form.Label>
                    <Form.Control onChange={(e) => setNumGuests(e.target.value)} placeholder='Number of guests' type='number' value={numGuests} />
                </div>
                <div className='m-3'>
                    <Button className='mb-1 w-100' disabled={auth?.currentUser?.phoneNumber ? true : false} onClick={() => reserveClicked()}
                        variant={darkMode ? 'outline-primary' : 'primary'}>
                        Reserve {String(listing?.category).toLowerCase()}
                        <HouseDoor className='ml-2' style={{ fontWeight: '14px', marginTop: '-3px' }} />
                    </Button>
                    <p className='text-center' style={{ color: 'gray', fontSize: '13px' }}>You won't be charged yet</p>
                </div>
                <div className='d-flex justify-content-between m-3'>
                    {listing?.type == 'Year-long lease' ? <h6>First months rent &<br /> security deposit</h6> : <h6>${listing?.price} x {roundStayLength()}</h6>}
                    {listing?.type == 'Year-long lease' ? <h6>${Number(priceTimesNights * 2).toFixed(2)}</h6> : <h6>${Number(priceTimesNights).toFixed(2)}</h6>}
                </div>
                <div className='d-flex justify-content-between mb-3 mx-3'>
                    <h6>Cleaning charge</h6>
                    <h6>INCLUDED</h6>
                </div>
                <div className='border-bottom d-flex justify-content-between pb-4 mx-3'>
                    <h6 className='d-flex'>
                        Service fee
                        <div ref={target}>
                            <InfoCircle className='cursor ms-1' onClick={() => setShowInfoTooltip(!showInfoTooltip)} />
                        </div>
                    </h6>
                    <h6>${utils.calculateServiceFees(total)}</h6>
                </div>
                <div className='d-flex justify-content-between position-relative px-3 pb-2 pt-3 pe-4'>
                    <h5 className='fw-bold'>Total</h5>
                    <h5 className='fw-bold'>${Number((listing?.type == 'Year-long lease' ? priceTimesNights * 2 : priceTimesNights) + utils.calculateServiceFees(total)).toFixed(2)}<span style={{ bottom: '18px', fontSize: '10px', position: 'absolute' }}>.00</span></h5>
                </div>
                {tooltipJSX()}
            </>}
        </div>;
    }

    const sentRequestJSX = () => {
        return <div className='p-2' style={{ boxShadow: `2px 2px 6px ${darkMode ? '#5a5a5a' : 'lightgray'}`, borderRadius: '8px', 
            border: '1px solid #5a5a5a' }}>
            <div className='m-3'>
                <h4 className='mb-1' style={{ color: darkMode ? '#fff' : 'unset'}}>Your request is pending...</h4>
                <p className='fs-7' style={{ color: darkMode ? '#fff' : 'gray' }}>
                    Your reservation for {listingReservation?.startDate} hasn't been approved yet.<br /> We'll notify you as soon as it is!
                </p>
            </div>
            <div className='m-3'>
                <Button className='mb-1 w-100' disabled={true} variant='outline-primary'>
                    Awaiting owner response..
                </Button>
            </div>
        </div>;
    }

    const showReservationOrPaidJSX = () => {
        if (newlyPaidInvoices?.findIndex((i: any) => i?.listingId == listingId) > -1) return youAlreadyBookedItJSX();
        if (listingReservation?.listingId == listingId) return sentRequestJSX();
        return makeReservationJSX();
    }

    const titleJSX = () => {
        return <div className='border-bottom d-flex justify-content-between'>
            <div>
                <h3 className='mb-2'>{listing?.type} in {listing?.city}, {listing?.state}</h3>
                <h5 className='d-flex pb-4' style={{ color: 'gray' }}>
                    <span className='mr-2'>{listing.numGuests} guest{listing?.numGuests > 1 && 's'}</span>
                    <span className='mr-2'>{listing.numBedrooms} bedroom</span>
                    <span className='mr-3'>{listing.numBaths} bath{listing.numBaths > 1 && 's'}</span>
                    <h6 className='cursor fw-bold mt-1' onClick={() => navigate(`/review/${listingId}?reservation=${listingReservation?.id}`)}>
                        <u>{reviewCount} Reviews</u>
                    </h6>
                </h5>
            </div>
            <div className='position-relative round-image'>
                <Building style={{ fontSize: `calc(2.5rem + 1.5vw)` }} />
            </div>
        </div>;
    }

    const tooltipJSX = () => {
        return <Overlay target={target.current} show={showInfoTooltip} placement='top'>
            {(props) => (
                <Tooltip onMouseLeave={() => setShowInfoTooltip(false)} {...props}>
                    <div className='py-1'>
                        <p className='fs-8 text-left'>High service fees? Psssh, that's for Airbnb. Stripe charges us 2.9%, and we never take more than $5 for ourselves.</p><br />
                        <div>
                            <div className='d-flex justify-content-between'>
                                <small>Stripe processing fee</small>
                                <small>2.9%</small>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <small>Hucklberry fee</small>
                                <small>$5 max</small>
                            </div><br />
                            <div className='d-flex justify-content-between'>
                                <small className='fw-bold'>Total fees:</small>
                                <small className='fw-bold'>${utils.calculateServiceFees(total)}</small>
                            </div>
                        </div>
                    </div>
                </Tooltip>
            )}
        </Overlay>
    }

    const youAlreadyBookedItJSX = () => {
        const timeUntilStay = differenceInDays();
        if (timeUntilStay < 0) {
            return <div className='p-2' style={{ boxShadow: `2px 2px 6px ${darkMode ? '#5a5a5a' : 'lightgray'}`, borderRadius: '8px', 
                border: '1px solid #5a5a5a' }}>
                <div className='m-3'>
                    <h4 className='d-flex mb-2'>
                        <Check2Circle className='me-2 fs-2' style={{ color: 'green' }} />
                        <u>You stayed here {timeUntilStay * -1} days ago</u>
                    </h4>
                    <p style={{ color: 'gray' }}>If you haven't already be sure to leave a review for your host.</p>
                </div>
                <div className='mb-2 mx-3'>
                    <Button className='w-100' onClick={() => navigate(`/review/${listingId}?reservation=${listingReservation?.id}`)}>
                        Leave a review
                    </Button>
                </div>
            </div>
        }
        return <div className='p-2' style={{ boxShadow: `2px 2px 6px ${darkMode ? '#5a5a5a' : 'lightgray'}`, borderRadius: '8px', 
            border: '1px solid #5a5a5a' }}>
            <div className='m-3'>
                <h4 className='d-flex mb-2'>
                    <Check2Circle className='me-2 fs-2' style={{ color: 'green' }} />
                    <u>Booked - {listing?.city}, {listing?.state} </u>
                </h4>
                <p style={{ color: 'gray' }}>Pack your bags, it's time for a road trip! <br /> {timeUntilStay}</p>
            </div>
            <div className='mb-2 mx-3'>
                <Button className='w-100' onClick={() => navigate(`/booking/${listingId}/${listingReservation?.guestEmail}`)}>
                    View your booking
                </Button>
            </div>
        </div>;
    }

    return (
        <div style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
            {mainContentJSX()}
            {startDate && endDate && openDateRangeModal && <ChooseDateRangeModal allReservations={allReservations}
                callback={didSelectDates}
                clearDatesCallback={clearDatesCallback}
                datesAlreadySelected={true}
                didSelectDates={didChooseDates}
                isOpen={openDateRangeModal}
                listing={listing}
                end={endDate}
                start={startDate}
                setIsOpen={setOpenDateRangeModal} />}
            <BookListingModal callback={saveReservation} isOpen={currentUserBooking} setIsOpen={setCurrentUserBooking}
                listingData={{ endDate, total: total, startDate, numGuests }} />
            {showAlert != '' && <ToastVille message={showAlert} callback={() => setShowAlert('')} />}
            <ShowWhoopsModal callbackSave={() => navigate('/emailsignin')} show={showWhoopsModal} setShow={setShowWhoopsModal} />
        </div>
    )
}
export default ListingPage;