



import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import '../styles/GeneralStyling.css';

interface IConfirmDeleteProps {
    callback: () => void
    isOpen: boolean,
    listingId?: string,
    setIsOpen: (val: boolean) => void
}

const ConfirmDeleteModal = (props: IConfirmDeleteProps) => {
    const navigate = useNavigate();
    const { callback, isOpen, listingId, setIsOpen } = props;

    const confirmDelete = () => {
        setIsOpen(false);
        callback();
    }

    //-----Pure JSX Functions-----//

    return (
        <>
            <Modal centered onHide={() => setIsOpen(false)} show={isOpen}>
                <Modal.Body>
                    <Row>
                        <Col className='mb-1' xs='12'>
                            <h4 className='mb-3 text-center'>Confirm</h4>
                            <p className='text-center'>Are you sure you want to delete this listing? <br /> You cannot undo this.</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex w-100'>
                        <Button className='flex-fill mr-1' onClick={() => setIsOpen(false)} variant='outline-danger'>Cancel</Button>
                        <Button className='flex-fill ml-1' onClick={() => confirmDelete()} variant='danger'>Confirm delete</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ConfirmDeleteModal;