import { useContext, useEffect, useState } from "react";

import {
    ArrowLeftSquareFill, ArrowRightSquareFill, CurrencyDollar, Calendar3,
    Envelope, FileEarmarkCheck, FilePdf, PinMapFill, Star, ShieldCheck, HouseDoor, Telephone, FilePdfFill, CheckCircle, CheckCircleFill
} from 'react-bootstrap-icons';
import { Button, Card, Col, Collapse, Container, Row } from 'react-bootstrap';
import Context from '../models/Context';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, useParams } from "react-router-dom";

import { ApiCalls } from '../models/apiCalls';
import Footer from '../components/Footer';
import Header from '../components/Header';
import huck from '../images/america.png';
import LeaseModal from "../components/LeaseModal";
import Toastville from '../components/Toast';

interface IListingPropsPage {
    currentUser: any,
    setCurrentUser: (user: any) => void
}

const BookingPage = (props: IListingPropsPage) => {
    const { currentUser, setCurrentUser } = props;

    const apiCalls = new ApiCalls();
    const auth = getAuth();
    const { darkMode } = useContext(Context);
    const { guestEmail, listingId } = useParams();
    const navigate = useNavigate();

    const [activeInvoice, setActiveInvoice] = useState<any>(null);
    const [didCopy, setDidCopy] = useState(false);
    const [guest, setGuest] = useState<any>(null);
    const [invoices, setInvoices] = useState<any>([]);
    const [notifications, setNotifications] = useState<any>([]);
    const [owner, setOwner] = useState<any>(null);
    const [showAlert, setShowAlert] = useState(false);
    const [showLeaseModal, setShowLeaseModal] = useState(false);
    const [showLeaseSubmittedToast, setShowLeaseSubmittedToast] = useState(false);

    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    const [endDate, setEndDate] = useState<any>(tomorrow);
    const [fullScreenImage, setFullScreenImage] = useState<any>(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [listing, setListing] = useState<any>(null);
    const [newlyPaidInvoices, setNewlyPaidInvoices] = useState<any>([]);
    const [reservation, setReservation] = useState<any>(null);
    const [showPhoneNumber, setShowPhoneNumber] = useState(false);
    const [startDate, setStartDate] = useState<any>(new Date());

    onAuthStateChanged(auth, user => user && setCurrentUser(user));

    useEffect(() => {
        getListingById();
        getNewlyPaidInvoices();
        if (guestEmail) {
            getReservationByEmail();
        }
    }, []);

    useEffect(() => {
        if (invoices?.length > 0) {
            const inv = invoices?.find((i: any) => auth?.currentUser?.email ? i?.email == auth?.currentUser?.email : i?.userId == auth?.currentUser?.uid);
            if (inv) setActiveInvoice(inv);
        }
    }, [invoices]);

    useEffect(() => {
        if (startDate && endDate && listing) {
            const diffInMs = endDate - startDate;
            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
            const numNights = Math.floor(diffInDays);
        }
    }, [endDate, listing, startDate]);

    useEffect(() => {
        if (listing) {
            getOwner();
            getGuest();
        }
    }, [listing]);

    useEffect(() => {
        if (listing && (!reservation?.lease?.images || reservation?.lease?.images?.length == 0)) {
            checkForLeaseAgreement();
        }
    }, [listing, reservation]);

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, 5150);
        }
    }, [showAlert]);

    const calculateDaysUntilGuestCheckin = () => {
        if (!activeInvoice || activeInvoice?.stripeInvoiceStatus != 'paid') return 'Currently awaiting payment';
        const today = new Date();
        const checkInDay = reservation?.startDate;
        if (!checkInDay) return;
        var Difference_In_Time = today.getTime() - new Date(checkInDay).getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        if (auth?.currentUser?.email) {
            if (Difference_In_Days < 0) return `You checked in at ${listing?.checkInTime} ${listing?.checkInAMPM}`
            if (Difference_In_Days <= 1) return `You check in today at ${listing?.checkInTime}${listing?.checkInAMPM}`;
            if (Difference_In_Days == 2) return 'You check in tomorrow';
            return `You check in ${Math.ceil(Difference_In_Days)} days from now`
        } else {
            if (Difference_In_Days < -1) return `Guest checked in ${Math.ceil(Difference_In_Days * -1)} days ago`;
            if (Difference_In_Days < 0 && Difference_In_Days >= -1) return `Guest checked in at ${listing?.checkInTime} ${listing?.checkInAMPM}`;
            if (Difference_In_Days <= 1) return `Your guest is checking in today at ${listing?.checkInTime}${listing?.checkInAMPM}`;
            if (Difference_In_Days == 2) return 'Your guest is checking in tomorrow';
            return `Your guest will be checking in ${Math.ceil(Difference_In_Days)} days from now`;
        }
    }

    const checkForLeaseAgreement = () => {
        if (listing?.lease) {
            setShowLeaseModal(true);
        }
    }

    const copyShit = () => {
        navigator.clipboard.writeText(String(owner?.phone).slice(2));
        setDidCopy(true);
    }

    const downloadReceipt = () => {
        var a: any = document.body.appendChild(document.createElement("a"));
        a.download = "MyReceipt.html";
        a.href = "data:text/html," + document.getElementById('my-listing-receipt')?.innerHTML;
        a.click();
    }

    const fullScreenClicked = (val: any) => {
        setFullScreenImage(val);
        setIsFullScreen(true);
    }

    const getGuest = async () => {
        const g = await apiCalls.getGuestOfListing(guestEmail as any);
        setGuest(g);
    }

    const getListingById = async () => {
        const listingData = await apiCalls.getListingById(listingId as any);
        setListing(listingData);
        window.scrollTo(0, 0);
    }

    const getNewlyPaidInvoices = async () => {
        const invs = await apiCalls.getPaidInvoice(guestEmail as any);
        setNewlyPaidInvoices(invs);
    }

    const getOwner = async () => {
        const owner = await apiCalls.getUserById(listing?.userId);
        if (owner) {
            setOwner(owner as any);
        }
    }

    const getReservationByEmail = async () => {
        const reqs = await apiCalls.getReservation(guestEmail, listingId);
        setReservation(reqs?.length > 0 ? reqs[0] : null);
    }

    const lastPhotoClicked = () => {
        if (fullScreenImage != null) {
            const previousPhotoOutOfBounds = (fullScreenImage - 1) <= 0;
            previousPhotoOutOfBounds ? setFullScreenImage(listing?.images?.length - 1) : setFullScreenImage(fullScreenImage - 1);
        }
    }

    const leaseSavedCallback = async (imgs: any, aprvDeny: boolean, msg: string) => {
        await apiCalls.updateReservation(reservation?.id, {
            ...reservation,
            lease: {
                confirmed: aprvDeny ? aprvDeny : false,
                leaseAgreement: listing?.lease,
                images: imgs, 
                message: msg
            }
        });
        setShowLeaseSubmittedToast(true);
    }

    const nextPhotoClicked = () => {
        if (fullScreenImage != null) {
            const nextImageIsntOutOfBounds = (fullScreenImage + 1) == listing?.images?.length;
            nextImageIsntOutOfBounds ? setFullScreenImage(0) : setFullScreenImage(fullScreenImage + 1);
        }
    }

    const openGoogleMapsInNewTab = () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${listing?.lat},${listing?.lng}`);
    }

    //-----Pure JSX Functions-----//

    const imagesJSX = () => {
        return <Row className='cursor mb-4' style={{ minHeight: '30vh' }}>
            <Col xs='12' md='6'>
                {listing?.images?.length > 0 && <img onClick={() => fullScreenClicked(0)} src={listing.images[0].thumb}
                    className='hucklberry-home-photo' style={{ borderRadius: '2%' }} alt={`hucklberry-home-photo`} />}
            </Col>
            <Col className='mobile-padding' xs='12' md='6'>
                {listing?.images?.length > 4 ? <div>
                    <Row className='piggywrapper'>
                        <Col className='mb-2 piggy pr-1' xs='6'>
                            <img className='hucklberry-home-photo-half'
                                onClick={() => fullScreenClicked(1)} src={listing.images[1].thumb} alt={`hucklberry-home-photo-half`} />
                        </Col>
                        <Col className='mb-2 pl-1' xs='6'>
                            <img className='hucklberry-home-photo-half' onClick={() => fullScreenClicked(2)} src={listing.images[2].thumb} alt={`hucklberry-home-photo-half`} />
                        </Col>
                        <Col className='piggy pr-1' xs='6'>
                            <img className='hucklberry-home-photo-half' onClick={() => fullScreenClicked(3)} src={listing.images[3].thumb} alt={`hucklberry-home-photo-half`} />
                        </Col>
                        <Col className='pl-1' xs='6'>
                            <img className='hucklberry-home-photo-half' onClick={() => fullScreenClicked(4)} src={listing.images[4].thumb} alt={`hucklberry-home-photo-half`} />
                        </Col>
                    </Row>
                </div> : <></>}
            </Col>
            {isFullScreen && <div className='full-page-image'>
                <div className='h-100 position-relative'>
                    <ArrowLeftSquareFill className='fixed-left' onClick={() => lastPhotoClicked()} />
                    <img onClick={() => setIsFullScreen(!isFullScreen)} src={listing.images[fullScreenImage ? fullScreenImage : 0].thumb} />
                    <ArrowRightSquareFill className='fixed-right' onClick={() => nextPhotoClicked()} />
                </div>
            </div>}
        </Row>;
    }

    const leaseJSX = () => {
        return <div className='d-flex justify-content-between mt-3'>
            <p>Lease Information:</p>
            <div className='w-50'>
                <p className='cursor fs-8 text-end' onClick={() => window?.open(listing?.lease, '_blank')} style={{ color: 'blue', opacity: '0.4' }}>
                    <u>{listing?.lease}</u>
                </p>
            </div>
        </div>;
    }

    const leftColumnJSX = () => {
        const isSignedLease = reservation?.lease?.images?.length > 0;
        return <Col className='mb-4' xs='12' lg='4'>
            <Card className='p-4'>
                <div className='mb-4 position-relative round-image text-center'>
                    <img src={listing?.images?.length > 0 ? listing?.images[0].thumb : huck} alt='hucklberry' />
                </div>
                <div className='border-top d-flex mb-2 pt-3 text-gray'>
                    <HouseDoor className='fs-4 me-3' />
                    <h6 className='fw-bold mt-1'>{listing?.address}</h6>
                </div>
                <div className='d-flex mb-2 pb-2 text-gray'>
                    <Star className='fs-4 me-3' />
                    <h6 className='mt-1'>7 reviews</h6>
                </div>
                <div className='border-top d-flex pt-3 text-gray'>
                    <ShieldCheck className='fs-4 me-3' />
                    <h6 className='mt-1'>Identity verified</h6>
                </div>
                <div className='d-flex my-2 pt-2 text-gray'>
                    <Calendar3 className='fs-4 me-3' />
                    <h6 className='mt-1'>{reservation?.numNights} nights</h6>
                </div>
                <div className='align-items-center d-flex my-2 pt-2 text-gray'>
                    <FilePdf className='fs-4 me-3' />
                    <h6 className='mt-1'>Lease status -
                        <span className='fw-bold ms-1'>
                            {isSignedLease ? 'Signed' : 'Not signed'}
                        </span>
                    </h6>
                    {isSignedLease && <CheckCircleFill className='ms-2 mt-1' style={{ color: 'green' }} />}
                </div>
                <div className='d-flex pt-1 text-gray'>
                    <CurrencyDollar className='fs-4 me-3' style={{ color: 'green' }} />
                    <h6 className='mt-1'>
                        <span className={['fw-bold me-2', newlyPaidInvoices?.length > 0 ? '' : 'danger'].join(' ')}>
                            <u>{newlyPaidInvoices?.length > 0 ? 'TOTAL PAID' : 'AWAITING PAYMENT'}</u>:
                        </span>
                        ${reservation?.price}
                    </h6>
                </div>
            </Card>
        </Col>;
    }

    const mainContentJSX = () => {
        return <div>
            <Container fluid='md'>
                <Row>
                    {leftColumnJSX()}
                    {rightColumnJSX()}
                </Row>
            </Container>
            <LeaseModal callback={leaseSavedCallback} isOpen={showLeaseModal} listing={listing}
                reservation={reservation} setIsOpen={setShowLeaseModal} />
        </div>;
    }

    const paymentDetailsJSX = () => {
        return <>
            <div className='mt-3'>
                <h5 className='fw-bold p-3'>Payment information</h5>
                <div>
                    <div className='align-content-center border-bottom border-top d-flex justify-content-between p-3'>
                        <h6>Total price:</h6>
                        <h6>${reservation?.price}</h6>
                    </div>
                </div>
            </div>
            <div className='align-content-center border-bottom d-flex justify-content-between p-3'>
                <h6 className='mt-1'>Payment record</h6>
                <div>
                    {newlyPaidInvoices?.length > 0 ? <Button onClick={() => window.open(newlyPaidInvoices[0].stripeInvoiceUrl)}
                        size='sm' variant='outline-primary'>
                        View in Stripe
                    </Button>
                        :
                        <Button size='sm' variant='outline-secondary'>
                            Awaiting payment
                        </Button>}
                </div>
            </div>
        </>;
    }

    const rightColumnJSX = () => {
        return <Col xs='12' lg='8'>
            <div>
                <div className='align-items-center d-flex justify-content-between'>
                    {auth?.currentUser?.email ? <h3 className='fw-bold mb-2'>Your stay at {listing?.userName}'s place</h3> :
                        <h3 className='fw-bold mb-2'>Booking - {reservation?.guestName}</h3>}
                    <div className='d-flex'>
                        {listing?.lease && <div>
                            <Button onClick={() => setShowLeaseModal(true)} size='sm' variant='outline-dark'>
                                View lease info <FilePdf className='ms-1' />
                            </Button>
                        </div>}
                        <div>
                            <Button className='ms-1' onClick={() => navigate(`/message/${guest?.id}`)} size='sm' variant='outline-danger'>
                                Contact {auth?.currentUser?.email ? 'owner' : 'guest'} <Envelope className='ms-1' />
                            </Button>
                        </div>
                    </div>
                </div>
                <small className='fw-bold light-gray'>{listing?.address}</small><br />
                <small className='light-gray'>{calculateDaysUntilGuestCheckin()}</small>
            </div>
            <div className='pt-2'>
                {imagesJSX()}
            </div>
            <div className='align-content-center border-top border-bottom d-flex justify-content-between p-3'>
                <h6 className='cursor' onClick={() => openGoogleMapsInNewTab()}>Get directions</h6>
                <PinMapFill className='cursor' onClick={() => openGoogleMapsInNewTab()} />
            </div>
            <div className='border-bottom'>
                <div className='align-content-center d-flex justify-content-between p-3'>
                    <h6 className='cursor' onClick={() => setShowPhoneNumber(!showPhoneNumber)}>
                        {auth?.currentUser?.email ? 'Host phone number' : 'View guests email'}
                    </h6>
                    {!auth?.currentUser?.email ? <Envelope className='cursor' onClick={() => setShowPhoneNumber(!showPhoneNumber)} /> :
                        <Telephone className='cursor' onClick={() => setShowPhoneNumber(!showPhoneNumber)} />}
                </div>
                <Collapse in={showPhoneNumber}>
                    <div>
                        <div className='d-flex justify-content-center'>
                            <p className='fw-bold mb-3 mx-3'>{!auth?.currentUser?.email ? guestEmail : owner?.phone}</p>
                            <div>
                                <Button onClick={() => copyShit()} size='sm' variant='outline-secondary'>
                                    {didCopy ? 'Copied!' : 'Copy'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            <div className='align-content-center border-bottom d-flex justify-content-between p-3 mb-4'>
                <h6 className='cursor' onClick={() => navigate(`/listing/${listing?.id}`)}>Show listing</h6>
                <HouseDoor className='cursor' onClick={() => navigate(`/listing/${listing?.id}`)} />
            </div>
            {reservationDetailsJSX()}
            {paymentDetailsJSX()}
        </Col>;
    }

    const reservationDetailsJSX = () => {
        return <>
            <div>
                <h5 className='fw-bold p-3'>Reservation details</h5>
                <div>
                    <div className='align-content-center border-bottom border-top d-flex justify-content-between p-3'>
                        <h6>Number of guests</h6>
                        <h6>{listing?.numGuests}</h6>
                    </div>
                    <div className='align-content-center border-bottom d-flex justify-content-between p-3'>
                        <h6>Confirmation code</h6>
                        <div className='d-flex'>
                            <h6 style={{ color: 'lightgray' }}>{listing?.id}</h6>
                            <FileEarmarkCheck className='cursor ms-3' onClick={() => navigator.clipboard.writeText(listing?.id)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='align-content-center border-bottom d-flex justify-content-between p-3'>
                <h6>Cancellation policy</h6>
                <h6>No cancellations/refunds</h6>
            </div>
            <div className='align-content-center border-bottom d-flex justify-content-between p-3'>
                <h6 className='mt-1'>Download receipt</h6>
                <div>
                    {newlyPaidInvoices?.length > 0 ? <Button onClick={() => downloadReceipt()} size='sm' variant='outline-primary'>MyRecipt.pdf</Button>
                        : <Button disabled size='sm' variant='outline-danger'>n/a</Button>}
                </div>
                <div className='p-4' id='my-listing-receipt' hidden>
                    <h2>Hucklberry.com - Home rentals</h2>
                    <div className=''>
                        <p>Check In: {reservation?.startDate}</p>
                        <p>Check Out: {reservation?.endDate}</p>
                        <p>Address: {reservation?.address}</p>
                        <p>Price: ${reservation?.price}</p>
                        <p>Confirmation code: {reservation?.id}</p>
                        <p>Host: {listing?.userName}</p>
                    </div>
                </div>
            </div>
        </>;
    }

    return (
        <div style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
            <Row className='mb-5'>
                <Col className='border-bottom py-2' style={{ backgroundColor: 'rgb(247, 247, 247)' }} xs='12'>
                    <Header currentUser={currentUser} setInvoices={setInvoices} hideCenter={true}
                        invoices={invoices} notifications={notifications} setNotifications={setNotifications}
                        setCurrentUser={setCurrentUser} />
                </Col>
            </Row>
            {mainContentJSX()}
            {showLeaseSubmittedToast ? <Toastville message='Your signed lease photos have been submitted. Once the owner approves them we will send you directions to your new pad.' /> : <></>}
            {!isFullScreen && <Footer />}
        </div>
    )
}
export default BookingPage;