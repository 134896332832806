
import { useState } from 'react';

import { Button, Form, Spinner } from 'react-bootstrap';
import { getAuth, updateProfile } from 'firebase/auth';
import { PersonLinesFill } from 'react-bootstrap-icons';

import { ApiCalls } from '../models/apiCalls';
import PhotoUpload from './PhotoUpload';

interface IEditCurrentUserProps {
    callback: (val: any) => void,
}

const EditCurrentUserOnTheFly = (props: IEditCurrentUserProps) => {
    const { callback } = props;

    const apiCalls = new ApiCalls();
    const auth = getAuth();

    const [image, setImage] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [step, setStep] = useState(1);

    const generateContentBasedOffStep = () => {
        return step == 1 ? inputNameJSX() : uploadIDJSX();
    }

    const newPhotoSelected = (p: any) => {
        setImage(p);
        setIsLoading(false);
    }

    const save = () => {
        updateProfile(auth?.currentUser as any, {
            displayName: name,
            photoURL: image,
        }).then(() => {
            apiCalls.updateUserPhoto(auth?.currentUser?.uid, image, name);
            callback({
                photoURL: image, 
                name
            });
        });
    }

    const uploadClicked = () => {
        document?.getElementById('fuckloadButton')?.click();
        setIsLoading(true);
    }

    //-----Pure JSX Functions-----//

    const inputNameJSX = () => {
        return <div className='border mx-2 mt-3 p-4 rounded' style={{ backgroundColor: 'rgb(247, 247, 247)' }}>
            <Form.Label className='text-center w-100' style={{ fontSize: '13px' }}>
                Before you can proceed we need to get your full name. <br /> This name must match the photo
                ID you upload on the next step.</Form.Label>
            <div className='d-flex'>
                <Form.Control className='flex-fill' onChange={(e) => setName(e.target.value)}
                    placeholder='Full name here' size='sm' value={name} />
                <Button className='ms-2 w-25' disabled={name == '' || name?.length < 5}
                    onClick={() => setStep(2)} size='sm'>Next step</Button>
            </div>
        </div>;
    }

    const uploadIDJSX = () => {
        return <div className='border d-flex mt-3 mx-2 p-4 rounded'>
            <div className='border-right me-3 pe-3 text-center'>
                {isLoading ? <Spinner animation='border' className='mt-2' variant='primary' /> :
                    <>
                        {image ? <img className='mb-2' style={{ borderRadius: '5px', width: '100%', height: '40px', objectFit: 'cover' }}
                            src={image} alt='hucklberry-dl' /> :
                            <PersonLinesFill className='mb-2' style={{ fontSize: '40px' }} />}
                        <div className='d-flex'>
                            {
                                image ? <Button className='me-1' onClick={() => setImage(null)} size='sm' variant='outline-danger'>clear photo</Button> :
                                    <Button className='me-1' onClick={() => setStep(1)} size='sm' variant='outline-secondary'>back</Button>
                            }
                            {
                                image ? <Button onClick={() => save()} size='sm'>Confirm</Button> :
                                    <Button onClick={() => uploadClicked()} size='sm'>Upload file</Button>
                            }
                        </div>
                    </>}
            </div>
            <p className='light-gray mt-1'>Please upload a photo of your driver's license, passport, or green card.</p>
        </div>;
    }

    return <div>
        {!auth?.currentUser?.displayName || !auth?.currentUser?.photoURL ? generateContentBasedOffStep() : <></>}
        <PhotoUpload callback={(e) => newPhotoSelected(e[0].thumb)} isId={true} hideButton={true} images={[]} setImages={(e) => console.log('')} />
    </div>
}
export default EditCurrentUserOnTheFly;