import { useContext, useEffect, useState } from 'react';

import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { getAuth, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import Context from '../models/Context';
import ToastVille from './Toast';

const EmailSignIn = () => {
    const auth = getAuth();
    const { darkMode } = useContext(Context);
    const navigate = useNavigate();

    let params = new URLSearchParams(window.location.search);
    const isBooking = params.get('isBooking');
    const jerryFuckinEastman = params.get('email');
    const listingId = params.get('listingId');

    const [didSendEmail, setDidSendEmail] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [newEmail, setNewEmail] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    useEffect(() => {
        const screenHeightDiv = document.getElementById('full-height-me-daddy');
        if (screenHeightDiv) {
            screenHeightDiv.style.height = window.innerHeight + 'px';
        }
    }, []);

    useEffect(() => {
        if(auth) {
            checkIfConfirmingEmail();
        }
    }, [auth]);

    const checkIfConfirmingEmail = async () => {
        if(isSignInWithEmailLink(auth, window.location.href)) {

            if(jerryFuckinEastman) {
                await signInWithEmailLink(auth, jerryFuckinEastman, window.location.href);
                if(isBooking && listingId) {
                    navigate(`/listing/${listingId}?isBooking=${isBooking}`);
                } else {
                    navigate('/');
                }
            }
        }
        setIsLoading(false);
    }

    const didTypeNewEmail = (e) => {
        setNewEmail(e.target.value);
        if(didSendEmail) {
            setDidSendEmail(false);
        }
    }

    const resendEmail = () => {
        setDidSendEmail(false);
        setHasError(false);
        sendEmailClicked();
    }

    const sendEmailClicked = (e) => {
        e?.preventDefault();
        setIsLoading(true);
        let url = '';
        if(listingId){
            //url = `http://localhost:3000/emailsignin?email=${newEmail}&listingId=${listingId}&isBooking=${true}`;
            url = `http://hucklberry.com/emailsignin?email=${newEmail}&listingId=${listingId}&isBooking=${true}`;
        } else {
            //url = `http://localhost:3000/emailsignin?email=${newEmail}`;
            url = `http://hucklberry.com/emailsignin?email=${newEmail}`;
        }
        sendSignInLinkToEmail(auth, newEmail, { url: url, handleCodeInApp: true }).then(() => {
            setDidSendEmail(true);
            setIsLoading(false);
            setHasError(false);
            setToastMessage('We just sent you an email with a link to login. If you dont see it be sure to check your spam folder.')
        }).catch(err => {
            setHasError(true);
            setIsLoading(false);
        });
    }

    const validateEmail = () => {
        const indexOfAtSign = newEmail.indexOf('@');
        if(!newEmail || newEmail?.length < 6 || indexOfAtSign == -1 || newEmail?.length < (indexOfAtSign + 5)) {
            return false;
        }
        return true;
    }

    //-----Pure JSX Functions-----//

    const submitButtonJSX = () => {
        if (hasError) return <Button className='flex-fill ml-1' disabled={isLoading} onClick={() => sendEmailClicked()} variant='danger'>
            {isLoading ? <Spinner animation='border' size='sm' /> : 'Fix errors'}
        </Button>;

        if (didSendEmail) return <Button className='flex-fill ml-1' disabled>
            {isLoading ? <Spinner animation='border' size='sm' /> : 'Email successfully sent!'}
        </Button>;

        return <Button className='flex-fill ml-1' disabled={isLoading || !validateEmail()} type='submit'>
            {isLoading ? <Spinner animation='border' size='sm' /> : 'Send login link'}
        </Button>;
    }

    const titleJSX = () => {
        return <h1 aria-label='hucklberry-home-page' className='mt-5 title huckleberry-small' onClick={() => navigate('/')} style={{ marginTop: '12px' }}>
            hucklBerry
        </h1>;
    }

    return (
        <div className='disable-overflow veeh-100' id='full-height-me-daddy' style={{ overflow: 'hidden' }}>
            <Row className='h-100'>
                <Col className='d-none d-lg-block gradient pl-5' lg='6'>
                    {titleJSX()}
                    <div className='d-flex align-items-center' style={{ height: '90%' }}>
                        <div>
                            <h3 className='mb-2'>Enter email address..</h3>
                            <p>We'll never spam you, we hate that shit!</p>
                        </div>
                    </div>
                </Col>
                <Col className='align-items-center d-flex justify-content-center pl-0 h-100' xs='12' lg='6'>
                    <div className='px-5 w-100'>
                        <h3><span className='fw-bold'>huck<span style={{ color: darkMode ? '#59CBE8' : '#C724B1' }}>l</span>Berry</span></h3>
                        <Form className='w-100' onSubmit={sendEmailClicked}>
                            <Form.Group className='mt-3'>
                                <Form.Control className='w-100' disabled={isLoading} isInvalid={hasError}
                                    onChange={(e) => didTypeNewEmail(e)}
                                    placeholder='Enter email address' value={newEmail} />
                                <div className='pt-2 d-flex'>
                                    <Button className='flex-fill mr-1 px-4' disabled={didSendEmail || isLoading}
                                        onClick={() => navigate(listingId ? `/listing/${listingId}` : -1)} variant='outline-secondary'>Back</Button>
                                    {submitButtonJSX()}
                                </div>
                            </Form.Group>
                        </Form>
                        {didSendEmail && <div className='my-2'>
                            <p className='text-right' style={{ color: '#949494', fontSize: '12px', fontWeight: '700' }}>
                                Dont see it? Check your spam folder
                            </p>
                            <p className='text-right' style={{ color: '#949494', fontSize: '12px', fontWeight: '700' }}>
                                Still don't see it?
                                <span className='cursor ms-2' onClick={() => resendEmail()} style={{ color: darkMode ? 'white' : 'blue' }}>
                                    <u>RESEND</u>
                                </span>
                            </p>
                        </div>}
                    </div>
                </Col>
            </Row>
            {toastMessage !== '' && <ToastVille message={toastMessage} stayOpenIndefinitely={true} />}
            <div id='recaptcha-container' />
        </div>
    );
};
export default EmailSignIn;
