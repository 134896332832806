
import { useEffect, useState } from 'react';

import { Toast, ToastContainer } from 'react-bootstrap';
import { XCircle } from 'react-bootstrap-icons';

interface IToastProps {
    callback?: () => void,
    message: string,
    setShowToast?: (val: any) => void,
    stayOpenIndefinitely?: boolean
}

const ToastVille = (props: IToastProps) => {
    const { callback, message, setShowToast, stayOpenIndefinitely } = props;
    const [showAlert, setShowAlert] = useState(message && message != '' ? true : false);

    useEffect(() => {
        if (showAlert) {
            autoCloseToast()
        }
    }, [showAlert]);

    const autoCloseToast = () => {
        setTimeout(() => {
            if(!stayOpenIndefinitely) {
                callback && callback();
                setShowAlert(false);
                setShowToast && setShowToast('');
            }
        }, 3799);
    }

    return (
        <ToastContainer className='m-3' position='top-end'>
            <Toast show={showAlert} bg='primary'>
                <Toast.Body style={{ color: 'white' }}>
                    <div className='d-flex justify-content-between'>
                        <h6 className='fw-bold'>Woot!</h6>
                        <div>
                            <XCircle className='cursor ml-3' onClick={() => setShowAlert(false)} style={{ color: 'white' }} />
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className='flex-fill'>{message}</div>
                    </div>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}
export default ToastVille;