
import { useState } from 'react';

import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';

import Lease from './Lease';

interface IEditListingDataProps {
    checkInAMPM: any,
    setCheckInAMPM: any,
    checkInTime: any,
    setCheckInTime: any,

    checkOutAMPM: any,
    setCheckOutAMPM: any,
    checkOutTime: any,
    setCheckOutTime: any,

    allowPets: boolean,
    setAllowPets: (val: boolean) => void,

    lease: any,
    setLease: (val: any) => void,

    type: any,
    setType: (val: string) => void,

    category: any,
    setCategory: (val: string) => void,

    spaceSelected: any,
    setEditSpaceSelected: (val: string) => void,

    numGuests: number,
    setEditNumGuests: (val: number) => void,

    numBedrooms: number,
    setEditNumBedrooms: (val: number) => void,

    numBaths: number,
    setEditNumBaths: (val: number) => void,

    newNumBaths: number,
    setNewNumBaths: (val: number) => void,

    minStay: number, 
    setMinStay: (val: number) => void,
}

const EditListingData = (props: IEditListingDataProps) => {
    const { allowPets, setAllowPets, checkInAMPM, setCheckInAMPM, checkInTime, setCheckInTime,
        lease, setLease,
        checkOutAMPM, setCheckOutAMPM, checkOutTime, setCheckOutTime,
        type, setType,
        category, setCategory,
        spaceSelected, setEditSpaceSelected,
        numGuests, setEditNumGuests,
        numBedrooms, setEditNumBedrooms,
        numBaths, setEditNumBaths,
        newNumBaths, setNewNumBaths,
        minStay, setMinStay } = props;


    const [isEditingCheckInTime, setIsEditingCheckInTime] = useState(false);
    const [isEditingLease, setIsEditingLease] = useState(false);
    const [isEditingMinStay, setIsEditingMinStay] = useState(false);
    const [isEditingPets, setIsEditingPets] = useState(false);
    const [isEditingRentalType, setIsEditingRentalType] = useState(false);
    const [isEditingSecondRentalType, setIsEditingSecondRentalType] = useState(false);
    const [isEditingSpaceSelected, setIsEditingSpaceSelected] = useState(false);
    const [isEditingNumGuests, setIsEditingNumGuests] = useState(false);
    const [newNumGuests, setNewNumGuests] = useState(numGuests);
    const [isEditingNumBedrooms, setIsEditingNumBedrooms] = useState(false);
    const [newMinStay, setNewMinStay] = useState<any>(minStay);
    const [newNumBedrooms, setNewNumBedrooms] = useState(numBedrooms);
    const [isEditingNumBaths, setIsEditingNumBaths] = useState(false);

    const newSecondTypeSelected = (t: string) => {
        setCategory(t);
        setIsEditingSecondRentalType(false);
    }

    const newSpaceSelected = (t: string) => {
        setEditSpaceSelected(t);
        setIsEditingSpaceSelected(false);
    }

    const newTypeSelected = (t: string) => {
        setType(t);
        setIsEditingRentalType(false);
    }

    const saveCheckInCheckOut = () => {
        setIsEditingCheckInTime(false);
    }

    const saveMinStay = () => {
        setMinStay(newMinStay);
        setIsEditingMinStay(false);
    }

    const saveNumBaths = () => {
        setEditNumBaths(newNumBaths);
        setIsEditingNumBaths(false);
    }

    const saveNumBedrooms = () => {
        setEditNumBedrooms(newNumBedrooms)
        setIsEditingNumBedrooms(false);
    }

    const saveNumGuests = () => {
        setEditNumGuests(newNumGuests)
        setIsEditingNumGuests(false);
    }

    const updateEditState = (t: string) => {
        if (t == 'minStay') {
            setIsEditingMinStay(true);
            setIsEditingLease(false);
            setIsEditingCheckInTime(false);
            setIsEditingPets(false);
            setIsEditingRentalType(false);
            setIsEditingSecondRentalType(false);
            setIsEditingSpaceSelected(false);
            setIsEditingNumGuests(false);
            setIsEditingNumBedrooms(false);
            setIsEditingNumBaths(false);
            return;
        } else if (t == 'lease') {
            setIsEditingMinStay(false);
            setIsEditingLease(true);
            setIsEditingCheckInTime(false);
            setIsEditingPets(false);
            setIsEditingRentalType(false);
            setIsEditingSecondRentalType(false);
            setIsEditingSpaceSelected(false);
            setIsEditingNumGuests(false);
            setIsEditingNumBedrooms(false);
            setIsEditingNumBaths(false);
            return;
        } else if (t == 'pets') {
            setIsEditingMinStay(false);
            setIsEditingLease(false);
            setIsEditingCheckInTime(false);
            setIsEditingPets(true);
            setIsEditingRentalType(false);
            setIsEditingSecondRentalType(false);
            setIsEditingSpaceSelected(false);
            setIsEditingNumGuests(false);
            setIsEditingNumBedrooms(false);
            setIsEditingNumBaths(false);
            return;
        } else if (t == 'type') {
            setIsEditingMinStay(false);
            setIsEditingLease(false);
            setIsEditingCheckInTime(false);
            setIsEditingPets(false);
            setIsEditingRentalType(true);
            setIsEditingSecondRentalType(false);
            setIsEditingSpaceSelected(false);
            setIsEditingNumGuests(false);
            setIsEditingNumBedrooms(false);
            setIsEditingNumBaths(false);
            return;
        } else if (t == 'category') {
            setIsEditingMinStay(false);
            setIsEditingLease(false);
            setIsEditingCheckInTime(false);
            setIsEditingPets(false);
            setIsEditingRentalType(false);
            setIsEditingSecondRentalType(true);
            setIsEditingSpaceSelected(false);
            setIsEditingNumGuests(false);
            setIsEditingNumBedrooms(false);
            setIsEditingNumBaths(false);
            return;
        } else if (t == 'spaceSelected') {
            setIsEditingMinStay(false);
            setIsEditingLease(false);
            setIsEditingCheckInTime(false);
            setIsEditingRentalType(false);
            setIsEditingPets(false);
            setIsEditingSecondRentalType(false);
            setIsEditingSpaceSelected(true);
            setIsEditingNumGuests(false);
            setIsEditingNumBedrooms(false);
            setIsEditingNumBaths(false);
            return;
        } else if (t == 'numGuests') {
            setIsEditingMinStay(false);
            setIsEditingLease(false);
            setIsEditingCheckInTime(false);
            setIsEditingRentalType(false);
            setIsEditingPets(false);
            setIsEditingSecondRentalType(false);
            setIsEditingSpaceSelected(false);
            setIsEditingNumGuests(true);
            setIsEditingNumBedrooms(false);
            setIsEditingNumBaths(false);
            return;
        } else if (t == 'numBaths') {
            setIsEditingMinStay(false);
            setIsEditingLease(false);
            setIsEditingCheckInTime(false);
            setIsEditingRentalType(false);
            setIsEditingSecondRentalType(false);
            setIsEditingSpaceSelected(false);
            setIsEditingPets(false);
            setIsEditingNumGuests(false);
            setIsEditingNumBedrooms(false);
            setIsEditingNumBaths(true);
            return;
        } if (t == 'checkin') {
            setIsEditingMinStay(false);
            setIsEditingLease(false);
            setIsEditingCheckInTime(true);
            setIsEditingRentalType(false);
            setIsEditingSecondRentalType(false);
            setIsEditingSpaceSelected(false);
            setIsEditingNumGuests(false);
            setIsEditingPets(false);
            setIsEditingNumBedrooms(false);
            setIsEditingNumBaths(true);
            return;
        } else {
            setIsEditingMinStay(false);
            setIsEditingLease(false);
            setIsEditingCheckInTime(false);
            setIsEditingRentalType(false);
            setIsEditingSecondRentalType(false);
            setIsEditingPets(false);
            setIsEditingSpaceSelected(false);
            setIsEditingNumGuests(false);
            setIsEditingNumBedrooms(true);
            setIsEditingNumBaths(false);
        }
    }

    //-----Pure JSX Functions-----//

    const bathroomsJSX = () => {
        return <>
            <Col className='border-bottom ps-0 mb-3' xs={isEditingNumBaths ? 12 : 6} lg={isEditingNumBaths ? '12' : '10'}>
                {isEditingNumBaths ? <div>
                    <div className='d-flex justify-content-between'>
                        <h5 className='fw-bold mb-2'>How many bathrooms?</h5>
                    </div>
                    <Row className='py-2'>
                        <Col className='align-content-center d-flex justify-content-between mb-2 pe-0' xs='12'>
                            <Form.Control className='me-2' defaultValue={numBaths} max={20} min={1} onChange={(e) => setNewNumBaths(e.target.value as any)}
                                size='sm' type='number' />
                            <div>
                                <Button onClick={() => saveNumBaths()} size='sm'>Save changes</Button>
                            </div>
                        </Col>
                    </Row>
                </div> : <div>
                    <h5>How many bathrooms?</h5>
                    <p className='light-gray mb-3 pt-3'>{numBaths}</p>
                </div>}
            </Col>
            {!isEditingNumBaths && <Col className='border-bottom position-relative mb-3 pe-0' xs='6' lg='2'>
                <Button className='mt-4 w-100' disabled={!numBaths}
                    onClick={() => updateEditState('numBaths')}
                    size='sm' variant='outline-secondary'>Edit</Button>
            </Col>}
        </>;
    }

    const bedroomsJSX = () => {
        return <>
            <Col className='border-bottom ps-0 mb-3' xs={isEditingNumBedrooms ? 12 : 6} lg={isEditingNumBedrooms ? '12' : '10'}>
                {isEditingNumBedrooms ? <div>
                    <div className='d-flex justify-content-between'>
                        <h5 className='fw-bold mb-2'>How many bedrooms?</h5>
                    </div>
                    <Row className='py-2'>
                        <Col className='align-items-center d-flex justify-content-between mb-2 pe-0' xs='12'>
                            <Form.Control className='me-2' defaultValue={numBedrooms} max={20} min={1}
                                onChange={(e) => setNewNumBedrooms(e.target.value as any)} size='sm' type='number' />
                            <div>
                                <Button onClick={() => saveNumBedrooms()} size='sm'>Save changes</Button>
                            </div>
                        </Col>
                    </Row>
                </div> : <div>
                    <h5>How many bedrooms?</h5>
                    <p className='light-gray mb-3 pt-3'>{numBedrooms}</p>
                </div>}
            </Col>
            {!isEditingNumBedrooms && <Col className='border-bottom position-relative mb-3 pe-0' xs='6' lg='2'>
                <Button className='mt-4 w-100' disabled={!numBedrooms}
                    onClick={() => updateEditState('numBedrooms')}
                    size='sm' variant='outline-secondary'>Edit</Button>
            </Col>}
        </>;
    }

    const buttonInColJSX = (title: string, callback: (val: string) => void) => {
        return <Col className='mb-2' xs='6' md='3'>
            <Button className='w-100' onClick={() => callback(title)} size='sm' variant='outline-secondary'>
                {title}
            </Button>
        </Col>;
    }

    const checkinJSX = () => {
        return <>
            <Col className='border-bottom px-0 mb-3' xs={isEditingCheckInTime ? 12 : 6} lg={isEditingCheckInTime ? '12' : '10'}>
                {isEditingCheckInTime ? <div className='align-items-center d-flex justify-content-between pb-2'>
                    <div className='d-flex'>
                        <div className='pe-4'>
                            <div className='d-flex justify-content-between'>
                                <h5 className='fw-bold'>Check in time</h5>
                            </div>
                            <Row className='py-2'>
                                <Col className='d-flex' xs='12'>
                                    <Form.Control className='me-2' placeholder='4' size='sm' type='number'
                                        max={12} min={1}
                                        onChange={(e) => setCheckInTime(e?.target?.value)} value={checkInTime} />
                                    <Dropdown>
                                        <Dropdown.Toggle variant='outline-secondary' size='sm'>
                                            {checkInAMPM}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setCheckInAMPM('am')}>am</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setCheckInAMPM('pm')}>pm</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                        <div className='ps-4'>
                            <div className='d-flex justify-content-between'>
                                <h5 className='fw-bold'>Check out time</h5>
                            </div>
                            <Row className='py-2'>
                                <Col className='d-flex' xs='12'>
                                    <Form.Control className='me-2' onChange={(e) => setCheckOutTime(e?.target?.value)}
                                        max={12} min={1} placeholder='11' size='sm' type='number' value={checkOutTime} />
                                    <Dropdown>
                                        <Dropdown.Toggle variant='outline-secondary' size='sm'>
                                            {checkOutAMPM}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setCheckOutAMPM('am')}>am</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setCheckOutAMPM('pm')}>pm</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div>
                        <Button onClick={() => saveCheckInCheckOut()} size='sm'>
                            Update listing
                        </Button>
                    </div>
                </div> : <div className='d-flex flexley'>
                    <div className='pe-3 me-3'>
                        <h5>Check in time</h5>
                        <p className='light-gray mb-3 pt-1'>{checkInTime}{checkInAMPM}</p>
                    </div>
                    <div>
                        <h5 className='me-3'>Checkout time</h5>
                        <p className='light-gray mb-3 pt-1'>{checkOutTime}{checkOutAMPM}</p>
                    </div>
                </div>}
            </Col>
            {!isEditingCheckInTime && <Col className='border-bottom position-relative pe-0 mb-3' xs='6' lg='2'>
                <Button className='mt-2 w-100'
                    onClick={() => updateEditState('checkin')}
                    size='sm' variant='outline-secondary'>Edit</Button>
            </Col>}
        </>;
    }

    const guestsJSX = () => {
        return <>
            <Col className='border-bottom ps-0 mb-3' xs={isEditingNumGuests ? 12 : 6} lg={isEditingNumGuests ? '12' : '10'}>
                {isEditingNumGuests ? <div>
                    <div className='d-flex justify-content-between'>
                        <h5 className='fw-bold mb-2'>How many guests?</h5>
                    </div>
                    <Row className='py-2'>
                        <Col className='align-items-center d-flex justify-content-between mb-2 pe-0' xs='12'>
                            <Form.Control className='me-2' defaultValue={numGuests} max={20} min={1}
                                onChange={(e) => setNewNumGuests(e.target.value as any)} size='sm' type='number' />
                            <div>
                                <Button onClick={() => saveNumGuests()} size='sm'>Update listing</Button>
                            </div>
                        </Col>
                    </Row>
                </div> : <div>
                    <h5>How many guests?</h5>
                    <p className='light-gray mb-3 pt-3'>{numGuests}</p>
                </div>}
            </Col>
            {!isEditingNumGuests && <Col className='border-bottom position-relative mb-3 pe-0' xs='6' lg='2'>
                <Button className='mt-4 w-100' disabled={!numGuests}
                    onClick={() => updateEditState('numGuests')}
                    size='sm' variant='outline-secondary'>Edit</Button>
            </Col>}
        </>;
    }

    const homeTypeJSX = () => {
        return <>
            <Col className='border-bottom mb-3 px-0' xs={isEditingSecondRentalType ? 12 : 6} lg={isEditingSecondRentalType ? '12' : '10'}>
                {isEditingSecondRentalType ? <div>
                    <div className='d-flex justify-content-between'>
                        <h5 className='fw-bold mb-2'>Which of these best describes your place?</h5>
                    </div>
                    <Row className='pb-2 pt-3'>
                        <Col className='mb-2' xs='4' lg='2'>
                            <Button className='w-100' onClick={() => newSecondTypeSelected('Apartment')} size='sm' variant='outline-secondary'>
                                Apartment
                            </Button>
                        </Col>
                        <Col className='mb-2 pl-0' xs='4' lg='2'>
                            <Button className='w-100' onClick={() => newSecondTypeSelected('Townhouse')} size='sm' variant='outline-secondary'>
                                Townhouse
                            </Button>
                        </Col>
                        <Col className='mb-2 px-0' xs='4' lg='2'>
                            <Button className='w-100' onClick={() => newSecondTypeSelected('Single-family home')} size='sm' variant='outline-secondary'>
                                Single-family home
                            </Button>
                        </Col>
                        {buttonInColJSX('Tiny-house/ADU', newSecondTypeSelected)}
                        {buttonInColJSX('Trailer/RV', newSecondTypeSelected)}
                    </Row>
                </div> : <div>
                    <h5>Which of these best describes your place?</h5>
                    <p className='light-gray mb-3 pt-3'>{category}</p>
                </div>}
            </Col>
            {!isEditingSecondRentalType && <Col className='border-bottom position-relative mb-3 pe-0' xs='6' lg='2'>
                <Button className='mt-4 w-100' disabled={!category}
                    onClick={() => updateEditState('category')}
                    size='sm' variant='outline-secondary'>Edit</Button>
            </Col>}
        </>;
    }

    const minimumNightsJSX = () => {
        return <>
            <Col className='border-bottom ps-0 mb-3' xs={isEditingMinStay ? 12 : 6} lg={isEditingMinStay ? '12' : '10'}>
                {isEditingMinStay ? <div>
                    <div className='d-flex justify-content-between'>
                        <h5 className='fw-bold mb-2'>Minimum stay length?</h5>
                    </div>
                    <Row className='py-2'>
                        <Col className='align-content-center d-flex justify-content-between mb-2 pe-0' xs='12'>
                            <div className='align-items-center d-flex'>
                                <Form.Control className='me-2' defaultValue={minStay} min={1} onChange={(e) => setNewMinStay(e.target.value as any)}
                                    size='sm' type='number' /> <small className='light-gray'> nights</small>
                            </div>
                            <div>
                                <Button onClick={() => saveMinStay()} size='sm'>Save changes</Button>
                            </div>
                        </Col>
                    </Row>
                </div> : <div>
                    <h5>Minimum stay length?</h5>
                    <p className='light-gray mb-3 pt-3'>{minStay} night minimum {type !== 'Vacation rental' && <small className='ms-3'>({type})</small>}</p>
                </div>}
            </Col>
            {!isEditingMinStay && <Col className='border-bottom position-relative mb-3 pe-0' xs='6' lg='2'>
                <Button className='mt-4 w-100' disabled={type !== 'Vacation rental'}
                    onClick={() => updateEditState('minStay')}
                    size='sm' variant='outline-secondary'>{type == 'Vacation rental' ? 'Edit' : type}</Button>
            </Col>}
        </>;
    }

    const petsJSX = () => {
        return <>
            <Col className='border-bottom px-0 mb-3' xs={isEditingPets ? 12 : 6} lg={isEditingPets ? '12' : '10'}>
                {isEditingPets ? <div className='align-items-center d-flex justify-content-between pb-2'>
                    <div className='d-flex'>
                        <div className='pe-4'>
                            <div className='d-flex justify-content-between'>
                                <h5 className='fw-bold'>Pets allowed</h5>
                            </div>
                            <Row className='py-2'>
                                <Col className='d-flex' xs='12'>
                                    <Form.Check type='switch' onClick={() => setAllowPets(!allowPets)}
                                        id='custom-switch' checked={allowPets}
                                        label={allowPets ? 'Yes' : 'No'} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div>
                        <Button onClick={() => setIsEditingPets(false)} size='sm'>Update listing</Button>
                    </div>
                </div> : <div>
                    <div className='me-5'>
                        <h5>Pets allowed</h5>
                        <p className='light-gray mb-3 pt-1'>{allowPets == true ? 'Yes' : 'No'}</p>
                    </div>
                </div>}
            </Col>
            {!isEditingPets && <Col className='border-bottom position-relative pe-0 mb-3' xs='6' lg='2'>
                <Button className='mt-2 w-100'
                    onClick={() => updateEditState('pets')}
                    size='sm' variant='outline-secondary'>Edit</Button>
            </Col>}
        </>;
    }

    const rentalTypeJSX = () => {
        return <>
            <Col className='border-bottom px-0 mb-3' xs={isEditingRentalType ? 12 : 6} lg={isEditingRentalType ? '12' : '10'}>
                {isEditingRentalType ? <div>
                    <div className='d-flex justify-content-between'>
                        <h5 className='mb-2 fw-bold'>Rental type</h5>
                    </div>
                    <Row className='pb-2 pt-3'>
                        {buttonInColJSX('Vacation rental', newTypeSelected)}
                        {buttonInColJSX('Month-to-month', newTypeSelected)}
                        {buttonInColJSX('Year-long lease', newTypeSelected)}
                        {buttonInColJSX('Sublet room', newTypeSelected)}
                    </Row>
                </div> : <div>
                    <h5>Rental type</h5>
                    <p className='light-gray mb-3 pt-3'>{type}</p>
                </div>}
            </Col>
            {!isEditingRentalType && <Col className='border-bottom position-relative pe-0 mb-3' xs='6' lg='2'>
                <Button className='mt-t w-100' disabled={!type}
                    onClick={() => updateEditState('type')}
                    size='sm' variant='outline-secondary'>Edit</Button>
            </Col>}
        </>;
    }

    const spaceJSX = () => {
        return <>
            <Col className='border-bottom ps-0 mb-3' xs={isEditingSpaceSelected ? '12' : '6'} lg={isEditingSpaceSelected ? '12' : '10'}>
                {isEditingSpaceSelected ? <div>
                    <div className='d-flex justify-content-between'>
                        <h5 className='fw-bold mb-2'>Which kind of space?</h5>
                    </div>
                    <Row className='pb-2 pt-3'>
                        <Col className='mb-2' xs='4' lg='2'>
                            <Button className='w-100' onClick={() => newSpaceSelected('An entire place')} size='sm' variant='outline-secondary'>An entire place</Button>
                        </Col>
                        <Col className='mb-2' xs='4' lg='2'>
                            <Button className='w-100' onClick={() => newSpaceSelected('A private room')} size='sm' variant='outline-secondary'>A private room</Button>
                        </Col>
                        <Col className='mb-2' xs='4' lg='2'>
                            <Button className='w-100' onClick={() => newSpaceSelected('A shared room')} size='sm' variant='outline-secondary'>A shared room</Button>
                        </Col>
                    </Row>
                </div> : <div>
                    <h5>What kind of space?</h5>
                    <p className='light-gray mb-3 pt-3'>{spaceSelected}</p>
                </div>}
            </Col>
            {!isEditingSpaceSelected && <Col className='border-bottom position-relative mb-3 pe-0' xs='6' lg='2'>
                <Button className='mt-4 w-100' disabled={!spaceSelected}
                    onClick={() => updateEditState('spaceSelected')}
                    size='sm' variant='outline-secondary'>Edit</Button>
            </Col>}
        </>;
    }

    return (
        <Row className='mx-1 my-4'>
            {spaceJSX()}
            {rentalTypeJSX()}
            {type == 'Vacation rental' && checkinJSX()}
            <Lease lease={lease} isEditing={isEditingLease} setIsEditing={setIsEditingLease} setLease={setLease} updateEditState={updateEditState} />
            {petsJSX()}
            {homeTypeJSX()}
            {guestsJSX()}
            {bedroomsJSX()}
            {bathroomsJSX()}
            {minimumNightsJSX()}
        </Row>
    )
}
export default EditListingData;