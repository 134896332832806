import { useEffect, useState } from 'react';

import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AboutPage from './pages/AboutPage';
import BecomeAHostPage from './pages/BecomeAHostPage';
import BookingPage from './pages/BookingPage';
import CareersPage from './pages/CareersPage';
import ContactPage from './pages/ContactPage';
import Context from './models/Context';
import EmailSignIn from './components/EmailSignin';
import FAQPage from './pages/FAQPage';
import Home from './pages/HomePage';
import InvestorsPage from './pages/InvestorsPage';
import ListingPage from './pages/ListingPage';
import MapPage from './pages/MapPage';
import MessagingPage from './pages/MessagingPage';
import MyAccount from './pages/AccountPage';
import PaymentsPage from './pages/PaymentsPage';
import ReviewPage from './pages/ReviewPage';
import Signin from './components/Signin';
import SignInChooseOwnerOrGuest from './components/SignInChooseOwnerOrGuest';
import 'bootstrap/dist/css/bootstrap.css';

import "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/storage";
import './App.css';

import { initializeApp } from "firebase/app";


initializeApp({
  apiKey: "AIzaSyASx3GU4ipY-FHX4eipL9s_2GGmEBkTPic",
  authDomain: "hucklberry-aa338.firebaseapp.com",
  projectId: "hucklberry-aa338",
  storageBucket: "hucklberry-aa338.appspot.com",
  messagingSenderId: "953515148600",
  appId: "1:953515148600:web:848b265bddde433352edcd",
  measurementId: "G-6Y0BJYFK94"
});

const App = () => {
  const auth = getAuth();

  const [currentUser, setCurrentUser] = useState(null);
  const [darkMode, setDarkMode] = useState<any>(true);

  const contextData: any = {
    darkMode, setDarkMode
  }

  useEffect(() => {
    setPersistence(auth, browserLocalPersistence);
  }, []);

  return (
    <Context.Provider value={contextData}>
      <div className={[darkMode ? 'dark' : '', 'vh-100'].join(' ')}>
        <BrowserRouter>
          <Routes>
            <Route path='/about' element={<AboutPage currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/account' element={<MyAccount currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/become-a-host' element={<BecomeAHostPage currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/become-a-host/:listingId' element={<BecomeAHostPage currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/booking/:listingId/:guestEmail' element={<BookingPage currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/careers' element={<CareersPage currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/contact' element={<ContactPage currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/emailsignin' element={<EmailSignIn />} />
            <Route path='/faq' element={<FAQPage currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/investors' element={<InvestorsPage currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/listing/:listingId' element={<ListingPage currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/message/:userId' element={<MessagingPage />} />
            <Route path='/payments' element={<PaymentsPage currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/review/:listingId' element={<ReviewPage currentUser={currentUser} />} />
            <Route path='/signin' element={<Signin currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/signinchoose' element={<SignInChooseOwnerOrGuest />} />
            <Route path='/map' element={<MapPage currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/' element={<Home currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </Context.Provider>

  )
}

export default App;
