
import { useState } from 'react';

import PhotoUpload from './PhotoUpload';
import { Utilities } from '../models/index';

interface IUploadGovtIdProps {
    callback: (val: string) => void,
    currentUser: any,
}

const UploadGovernmentId = (props: IUploadGovtIdProps) => {
    const { callback, currentUser } = props;
    const utils = new Utilities();

    const [images, setImages] = useState([]);

    //-----Pure JSX Functions-----//

    return <div className='border-bottom d-flex justify-content-between py-3 pe-1'>
        <div className='align-items-center d-flex'>
            <h5 className='mb-2'>
                Government ID {utils.privateJSX()}
            </h5>
            <small className='light-gray mx-5' style={{ fontSize: '11px', maxHeight: '130px', overflow: 'hidden', wordBreak: 'break-all' }}>
                {currentUser?.photoURL ? <a target='_blank' href={currentUser.photoURL}>{currentUser.photoURL}</a> : 'No file found'}</small>
        </div>
        <PhotoUpload callback={(e) => callback(e[0].thumb)} isId={true} images={images} setImages={setImages} hideButton={false} />

    </div>
}
export default UploadGovernmentId;