import { useState } from 'react';

import { Modal } from 'react-bootstrap';

import EditCurrentUserOnTheFly from './EditCurrentUserOnTheFly';
import '../styles/GeneralStyling.css';

interface IEditCurrentUserProps {
    callback: any,
    isOpen: boolean,
    setIsOpen: (val: any) => void
}

const EditCurrentUserModal = (props: IEditCurrentUserProps) => {
    const { callback, isOpen, setIsOpen } = props;

    const [isLoading, setIsLoading] = useState(false);

    const save = (data: any) => {
        setIsLoading(false);
        setIsOpen(false);
        callback();
    }

    //-----Pure JSX Functions-----//

    return (
        <>
            <Modal centered onHide={() => setIsOpen(false)} show={isOpen}>
                <Modal.Body>
                    <EditCurrentUserOnTheFly callback={(e: any) => save(e)} />
                </Modal.Body>
            </Modal>
        </>
    )
}
export default EditCurrentUserModal;