
import { useState } from 'react';

import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';

import EditCurrentUserOnTheFly from './EditCurrentUserOnTheFly';

interface IBookListingModalProps {
    callback: (val: any) => void,
    isOpen: boolean,
    listingData: any,
    setIsOpen: (val: boolean) => void
}

const BookListingModal = (props: IBookListingModalProps) => {
    const { callback, isOpen, listingData, setIsOpen } = props;

    const auth = getAuth();

    const [isLoading, setIsLoading] = useState(false);

    const requestBookingClicked = () => {
        setIsLoading(true);
        callback('good to go');
    }

    //-----Pure JSX Functions-----//

    const descriptionJSX = () => {
        return <p className='ms-2 my-3 fs-7' style={{ color: 'gray' }}>Check over the following details to make sure everything looks ok. <br />
            If the owner approves your request, we'll send a Stripe invoice to your email where you can pay
            with a credit or debit card.
        </p>;
    }

    const listingDataJSX = () => {
        return listingData ? <div className='border mt-3 mx-2 p-4 rounded' style={{ backgroundColor: 'rgb(247, 247, 247)' }}>
            <div className='d-flex mb-3'>
                <h4 className='w-36'><u>Dates</u>:</h4>
                <div className='flex-fill'>
                    <p className='text-right' style={{ fontSize: '13px', marginTop: '-5px' }}>
                        <span style={{ fontWeight: '700', marginRight: '10px' }}>{listingData?.startDate?.toLocaleString('en-us', { weekday: 'long' })} </span>
                        {listingData?.startDate?.toLocaleString('en-us')}
                    </p>
                    <p className='text-right' style={{ fontSize: '13px' }}>
                        <span style={{ fontWeight: '700', marginRight: '10px' }}>{listingData?.endDate?.toLocaleString('en-us', { weekday: 'long' })} </span>
                        {listingData?.endDate?.toLocaleString('en-us')}
                    </p>
                </div>
            </div>
            <div className='d-flex mb-3'>
                <h4 className='w-36'><u>Price</u>:</h4>
                <div className='flex-fill'>
                    <h4 className='text-right'>
                        <span className='me-1' style={{ color: 'green' }}>$</span>{listingData.total}
                    </h4>
                </div>
            </div>
            <div className='d-flex mb-3'>
                <h4 className='w-36'><u>Guests</u>:</h4>
                <div className='flex-fill'>
                    <h4 className='text-right'>{listingData.numGuests}</h4>
                </div>
            </div>
        </div> : <></>;
    }

    return (
        <Modal centered onHide={() => setIsOpen(false)} show={isOpen}>
            <Modal.Body>
                <Row>
                    <Col className='mb-1' xs='12'>
                        <>
                            <h4 className='m-2'>Confirm booking request</h4>
                            {descriptionJSX()}
                            {listingDataJSX()}
                            <EditCurrentUserOnTheFly callback={callback} />
                        </>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                    <div className='d-flex w-100'>
                        <Button className='flex-fill mr-1' onClick={() => setIsOpen(false)} size='sm' variant='outline-secondary'>Cancel</Button>
                        <Button className='flex-fill mr-1' disabled={isLoading || !auth?.currentUser?.displayName || !auth?.currentUser?.photoURL} onClick={() => requestBookingClicked()} size='sm'>
                            Request booking
                            {isLoading && <Spinner animation='border' className='ms-2' size='sm' />}
                        </Button>
                    </div>
                </Modal.Footer>
        </Modal>
    )
}
export default BookListingModal;