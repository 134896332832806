import { useEffect, useState } from 'react';

import { ArrowLeftSquareFill, ArrowRightSquareFill, Calendar2, XOctagon } from 'react-bootstrap-icons';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import Calendar from 'react-calendar';
import { getAuth } from 'firebase/auth';

import { ApiCalls } from '../models/apiCalls';
import { ReservationStatus } from '../models';
import { Utilities } from '../models';
import '../styles/GeneralStyling.css';

interface IChooseDateRangeModalProps {
    allReservations?: any,
    callback?: (val1: any, val2?: any) => void,
    clearDatesCallback?: () => void,
    datesAlreadySelected?: boolean,
    didSelectDates?: boolean,
    listing?: any,
    isOpen: boolean,
    setIsOpen: (val: boolean) => void, 
    start?: any,
    end?: any
}

const ChooseDateRangeModal = (props: IChooseDateRangeModalProps) => {
    const { allReservations, callback, clearDatesCallback, datesAlreadySelected, didSelectDates, end, listing, isOpen, setIsOpen, start } = props;

    const apiCalls = new ApiCalls();
    const auth = getAuth();
    const today = new Date();
    const utils = new Utilities();

    const [chooseDateOrIsFlexible, setChooseDateOrIsFlexible] = useState<'ChooseDate' | 'IsFlexible'>('ChooseDate')
    const [didSetEndDate, setDidSetEndDate] = useState(datesAlreadySelected ? true :false);
    const [didSetStartDate, setDidSetStartDate] = useState(datesAlreadySelected ? true :false);
    const [endDate, setEndDate] = useState<any>(datesAlreadySelected ? end : today);
    const [error, setError] = useState('');
    const [isFlexibleMonths, setIsFlexibleMonths] = useState([]);
    const [isFlexibleMonthsSelected, setIsFlexibleMonthsSelected] = useState([]);
    const [monthOnLeftCalendar, setMonthOnLeftCalendar] = useState(today.getMonth());
    const [monthOnRightCalendar, setMonthOnRightCalendar] = useState<any>(null)
    const [nextMonthsStartDate, setNextMonthsStartDate] = useState(today.getMonth() == 11 ? new Date(today.getFullYear() + 1, 0, 1) : new Date(today.getFullYear(), today.getMonth() + 1, 1));
    const [startDate, setStartDate] = useState<any>(today);
    const [stayForALength, setStayForALength] = useState<'Weekend' | 'Week' | 'Month' | 'Year'>('Weekend');
    const [thisMonthsStartDate, setThisMonthsStartDate] = useState(today);
    const [todayIsBlocked, setTodayIsBlocked] = useState(false);
    const [yearOnLeftCalendar, setYearOnLeftCalendar] = useState(today.getFullYear());
    const [yearOnRightCalendar, setYearOnRightCalendar] = useState(today.getFullYear());

    useEffect(() => {
        checkIfCalendarIsAtYearsEnd();
        generateListOfMonths()
    }, []);

    // useEffect(() => {
    //     const html = document.querySelector("html");
    //     if (html) {
    //         html.style.overflow = 'hidden';
    //     }
    //     return () => {
    //         const html = document.querySelector("html");
    //     if (html) {
    //         html.style.overflow = 'unset';
    //     }
    //     }
    // }, []);

    useEffect(() => {
        if (todayIsBlocked) {
            setTimeout(() => {
                clearDates();
            }, 750);
        }
    }, [todayIsBlocked]);

    const cancelClicked = () => {
        clearDates();
        setIsOpen(false);
    }

    const checkIfCalendarIsAtYearsEnd = () => {
        if (utils.getNextMonth(startDate.getDay(), startDate.getMonth(), startDate.getFullYear()) == 0) {
            setMonthOnRightCalendar(0);
            setYearOnRightCalendar(yearOnRightCalendar + 1);
        } else {
            setMonthOnRightCalendar(monthOnRightCalendar + 1);
        }
    }

    const checkIfTileDisabled = ({ date }: { date: any }) => {
        let blockedByRes = false;
        if (allReservations?.length > 0) {
            allReservations.forEach((r: any) => {
                if (date <= new Date(r?.endDate) && date >= new Date(r?.startDate)) {
                    blockedByRes = true;
                }
            });
        }
        const isToday = (date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear());
        if (isToday && !blockedByRes) return false;
        return blockedByRes ? blockedByRes : date < today;
    }

    const checkIfTileIsInSelectedRange = ({ date }: { date: any }) => {
        if(didSelectDates && start && end) {
            if(date <= new Date(end) && date >= new Date(start)) {
                return 'in-selected-range';
            }
        }
        let isBlocked = false;
        if (allReservations?.length > 0) {
            allReservations.forEach((r: any) => {
                if (date <= new Date(r?.endDate) && date >= new Date(r?.startDate)) {
                    if(new Date(date).getDate() == new Date(today).getDate()) {
                        setTodayIsBlocked(true);
                        return;
                    }
                    if (r?.status == ReservationStatus.Blocked) {
                        isBlocked = true;
                        return;
                    }
                }
            });
        }
        if (isBlocked && !auth?.currentUser?.email) return 'in-blocked-range';
        const isInRange = date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime();
        const isToday = (date.getDate() == startDate.getDate() && date.getMonth() == startDate.getMonth() && date.getFullYear() == startDate.getFullYear());
        return (isInRange || isToday) && didSetEndDate ? 'in-selected-range' : date.getTime() == startDate.getTime() ? '' : 'not-in-selected-range';
    }

    const clearDates = () => {
        setError('');
        setDidSetEndDate(false);
        setDidSetStartDate(false);
        setEndDate(today);
        setStartDate(today);
        clearDatesCallback && clearDatesCallback();
    }

    const deleteRes = (res: any) => {
        apiCalls.updateReservationToInactive(res?.id);
        callback && callback('didDelete');
        setIsOpen(false);
    }

    const didSelectIsFlexibleMonth = (obj: any) => {
        const copy = [...isFlexibleMonthsSelected] as any;
        const alreadySelected = copy?.findIndex((m: any) => m.month == obj.month && m.year == obj.year);
        if (alreadySelected > -1) {
            copy.splice(alreadySelected, 1);
        } else {
            copy.push(obj);
        }
        setIsFlexibleMonthsSelected(copy);
    }

    const generateListOfMonths = () => {
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();
        const arrOfMonths = [{ month: currentMonth, year: currentYear }];
        for (let i = 1; i < 12; i++) {
            arrOfMonths.push({
                month: currentMonth + i < 12 ? currentMonth + i : (currentMonth + i) % 12,
                year: currentMonth + i < 12 ? currentYear : currentYear + 1
            })
        }
        setIsFlexibleMonths(arrOfMonths as any);
    }

    const getMonthString = (month: number) => {
        switch (month) {
            case 11: return 'December';
            case 10: return 'November';
            case 9: return 'October';
            case 8: return 'September';
            case 7: return 'August';
            case 6: return 'July';
            case 5: return 'June';
            case 4: return 'May';
            case 3: return 'April';
            case 2: return 'March';
            case 1: return 'February';
            case 0: return 'January';
            default: return month;
        }
    }

    const goToLastMonth = () => {
        if (monthOnLeftCalendar == 0) {
            setYearOnLeftCalendar(yearOnLeftCalendar - 1);
        }
        setThisMonthsStartDate(new Date(monthOnLeftCalendar == 0 ? thisMonthsStartDate.getFullYear() - 1 : thisMonthsStartDate.getFullYear(), monthOnLeftCalendar == 0 ? 11 : monthOnLeftCalendar - 1, 1));
        setMonthOnLeftCalendar(monthOnLeftCalendar == 0 ? 11 : monthOnLeftCalendar - 1);

        if (monthOnRightCalendar == 0) {
            setYearOnRightCalendar(yearOnRightCalendar - 1);
        }
        setNextMonthsStartDate(new Date(monthOnRightCalendar == 0 ? nextMonthsStartDate.getFullYear() - 1 : nextMonthsStartDate.getFullYear(), monthOnRightCalendar == 0 ? 11 : monthOnRightCalendar - 1, 1));
        setMonthOnRightCalendar(monthOnRightCalendar == 0 ? 11 : monthOnRightCalendar - 1);
    }

    const goToNextMonth = () => {
        if (monthOnRightCalendar == 11) {
            setYearOnRightCalendar(yearOnRightCalendar + 1);
        }
        setNextMonthsStartDate(new Date(monthOnRightCalendar == 11 ? nextMonthsStartDate.getFullYear() + 1 : nextMonthsStartDate.getFullYear(), monthOnRightCalendar == 11 ? 0 : monthOnRightCalendar + 1, 1));
        setMonthOnRightCalendar(monthOnRightCalendar == 11 ? 0 : monthOnRightCalendar + 1);

        if (monthOnLeftCalendar == 11) {
            setYearOnLeftCalendar(yearOnLeftCalendar + 1);
        }
        setThisMonthsStartDate(new Date(monthOnLeftCalendar == 11 ? thisMonthsStartDate.getFullYear() + 1 : thisMonthsStartDate.getFullYear(), monthOnLeftCalendar == 11 ? 0 : monthOnLeftCalendar + 1, 1));
        setMonthOnLeftCalendar(monthOnLeftCalendar == 11 ? 0 : monthOnLeftCalendar + 1);
    }

    const saveAndContinueClicked = () => {
        const diffInMs = endDate - startDate;
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        const lengthStay = listing?.minStay >= 1 ? listing?.minStay : 1;
        const numNights = Math.floor(diffInDays);
        if (listing) {
            if (numNights < listing?.minStay && auth?.currentUser?.email) {
                setError(`This listing requires you to stay at least ${lengthStay} ${lengthStay == 1 ? 'night' : 'nights'}`)
            } else {
                if (callback) {
                    callback(startDate, endDate);
                    setIsOpen(false);
                }
            }
        }
    }

    const userDidSelectDate = (newDate: Date) => {
        const isToday = newDate.getTime() == startDate.getTime();
        allReservations?.filter((r: any) => r?.status === ReservationStatus.Blocked).forEach((r: any) => {
            if (new Date(r?.startDate).getTime() <= new Date(newDate).getTime() && new Date(r?.endDate).getTime() <= new Date(newDate).getTime()) return;
        })

        if (didSetStartDate && isToday && didSetEndDate) {
            setEndDate(today);
            setStartDate(today);
            setDidSetEndDate(false);
            setDidSetStartDate(false);
        }
        if (!didSetStartDate || newDate.getTime() < startDate.getTime()) {
            setStartDate(newDate);
            setDidSetStartDate(true);
            setEndDate(today);
            setDidSetEndDate(false);
            
        } else {

            let itsFuckedDood = false;
            if (allReservations?.length > 0) {
                const numberOfReservationsInRange = allReservations.filter((r: any) => new Date(r?.startDate) >= new Date(startDate) && new Date(r?.endDate) <= new Date(newDate))
                if (numberOfReservationsInRange?.length > 0) itsFuckedDood = true;
            }
            if (itsFuckedDood) return;
            setEndDate(newDate);
            setDidSetEndDate(isToday ? false : true);
        }
    }

    //-----Pure JSX Functions-----//

    const arrowLeftJSX = () => {
        const isOnCurrentMonth = monthOnLeftCalendar == new Date().getMonth() && yearOnLeftCalendar == new Date().getFullYear();
        return <ArrowLeftSquareFill className={isOnCurrentMonth ? 'arrow-disabled' : 'cursor'} onClick={() => !isOnCurrentMonth ? goToLastMonth() : null} />;
    }

    const arrowRightJSX = () => {
        return <div className='d-flex justify-content-end'>
            <ArrowRightSquareFill className='cursor' onClick={() => goToNextMonth()} />
        </div>;
    }

    const chooseDatesJSX = () => {
        return <>
            <Col className='mb-3' xs='12' lg='6'>
                <div className='d-flex justify-content-between'>
                    {arrowLeftJSX()}
                    <div className='d-sm-block d-md-block d-lg-none d-xl-none'>
                        {arrowRightJSX()}
                    </div>
                </div>
                {leftCalendarJSX()}
            </Col>
            <Col xs='12' lg='6'>
                <div className='d-none d-lg-block'>
                    {arrowRightJSX()}
                </div>
                {rightCalendarJSX()}
            </Col>
            {auth?.currentUser?.phoneNumber && <>
                <div className='d-flex justify-content-center mt-3 px-5'>
                    <Card className='w-75'>
                        <Row>
                            <Col className='mt-2' xs='12'>
                                <h6 className='border-bottom fw-bold pb-2 text-center'>Blocked Dates</h6>
                                <div className='m-2'>
                                    {allReservations?.length > 0 ? allReservations?.filter((r: any) => r?.active && r?.status == ReservationStatus.Blocked).map((r: any) => {
                                        return <div className='fs-8 d-flex justify-content-center' style={{ color: 'gray' }}>
                                            {r?.status === ReservationStatus.Blocked ? <div className='d-flex p-1'>
                                                You blocked out the following dates: {r?.startDate} - {r?.startDate}
                                                <Button className='extra-small-btn ms-2' onClick={() => deleteRes(r)} size='sm' variant='outline-danger'>
                                                    Delete
                                                </Button>
                                            </div> : <>

                                            </>}
                                        </div>
                                    }) : <p className='fs-8 text-center'>You haven't blocked any dates on this listing yet.</p>}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </>}
        </>;
    }

    const chooseDatesOrImFlexibleJSX = () => {
        return <div className='d-flex justify-content-between round-gray-box'>
            <h6 className={chooseDateOrIsFlexible === 'ChooseDate' ? 'round-white-box' : ''} onClick={() => setChooseDateOrIsFlexible('ChooseDate')}>Choose dates</h6>
            <h6 className={chooseDateOrIsFlexible === 'IsFlexible' ? 'round-white-box' : ''} onClick={() => setChooseDateOrIsFlexible('IsFlexible')}>I'm flexible</h6>
        </div>;
    }

    const isFlexibleJSX = () => {
        return <>
            <Col className='mt-3 text-center' xs='12'>
                <span>
                    <h5 className='fw-bold mb-2'>Stay for a week</h5>
                    <div className='d-flex flexible-stay-length justify-content-center'>
                        <span className={stayForALength == 'Weekend' ? 'bold-pill' : ''} onClick={() => setStayForALength('Weekend')}>Weekend</span>
                        <span className={stayForALength == 'Week' ? 'bold-pill' : ''} onClick={() => setStayForALength('Week')}>Week</span>
                        <span className={stayForALength == 'Month' ? 'bold-pill' : ''} onClick={() => setStayForALength('Month')}>Month</span>
                    </div>
                </span>
            </Col>
            <Col className='text-center' xs='12'>
                <h5 className='fw-bold mt-5'>Go anytime</h5>
                <div className='d-flex horizontal-scroll'>
                    {isFlexibleMonths?.length > 0 && isFlexibleMonths.map((m: any) => {
                        return <div className={isFlexibleMonthsSelected.find((o: any) => o.month == m.month) ? 'bold-block' : 'block'}
                            key={`pick-month-rolliepoly-${m.month}`} onClick={() => didSelectIsFlexibleMonth(m)}>
                            <Calendar2 />
                            <p>{getMonthString(m.month)}</p>
                            <p>{m.year}</p>
                        </div>
                    })}
                </div>
            </Col>
        </>;
    }

    const leftCalendarJSX = () => {
        return <div>
            <p className='mb-2 text-center'>{`${getMonthString(monthOnLeftCalendar)} ${yearOnLeftCalendar}`}</p>
            <Calendar activeStartDate={thisMonthsStartDate}
                calendarType='Hebrew'
                onChange={(e: any) => userDidSelectDate(e)}
                showNeighboringMonth={false}
                tileClassName={checkIfTileIsInSelectedRange}
                tileDisabled={checkIfTileDisabled}
                view='month' />
        </div>;
    }

    const rightCalendarJSX = () => {
        return <div>
            <p className='mb-2 text-center'>{`${getMonthString(monthOnRightCalendar)} ${yearOnRightCalendar}`}</p>
            <Calendar activeStartDate={nextMonthsStartDate}
                calendarType='Hebrew'
                onChange={(e: any) => userDidSelectDate(e)}
                showNeighboringMonth={false}
                tileDisabled={checkIfTileDisabled}
                tileClassName={checkIfTileIsInSelectedRange}
                view='month' />
        </div>;
    }

    return (
        <>
            <Modal centered onHide={() => setIsOpen(false)} show={isOpen} size='lg'>
                <Modal.Body>
                    <Row className='p-3'>
                        {!callback && <Col className='d-flex justify-content-center' xs='12'>
                            <div className='p-3'>
                                {chooseDatesOrImFlexibleJSX()}
                            </div>
                        </Col>}
                        {chooseDateOrIsFlexible === 'ChooseDate' && chooseDatesJSX()}
                        {chooseDateOrIsFlexible === 'IsFlexible' && isFlexibleJSX()}
                        <Col xs='12'>
                            {error != '' && <p className='fs-8 fw-bold text-end' style={{ color: 'red' }}>{error}</p>}
                            <div className='align-items-end d-flex justify-content-end mt-3'>
                                <div>
                                    <Button className='mr-2' onClick={() => cancelClicked()} variant={didSetEndDate ? 'outline-secondary' : 'light'}>cancel</Button>
                                </div>
                                <div>
                                    {!auth?.currentUser?.phoneNumber && didSetEndDate && <Button className='mr-2' onClick={() => clearDates()} variant='outline-danger'>clear dates
                                    </Button>}
                                </div>
                                <div>
                                    {auth?.currentUser?.phoneNumber ? <Button disabled={!didSetEndDate}
                                        onClick={() => saveAndContinueClicked()} variant='danger'>
                                        Block dates <XOctagon className='ms-1' />
                                    </Button> : <Button className='align-items-center d-flex'
                                        disabled={!didSetEndDate}
                                        onClick={() => saveAndContinueClicked()}
                                        variant={didSetEndDate || isFlexibleMonthsSelected?.length > 0 ? 'primary' : 'outline-secondary'}>
                                        Save
                                    </Button>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ChooseDateRangeModal;