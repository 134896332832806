import { useEffect, useState } from 'react';

import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';

import PhotoUpload from './PhotoUpload';
import '../styles/GeneralStyling.css';

interface ILeaseModalProps {
    callback: any,
    isOpen: boolean,
    listing: any,
    reservation: any,
    setIsOpen: (val: boolean) => void
}

const LeaseModal = (props: ILeaseModalProps) => {
    const { callback, isOpen, listing, reservation, setIsOpen } = props;

    const auth = getAuth();

    const [approveOrDenyPhotos, setApproveOrDenyPhotos] = useState<boolean>(false);
    const [disableEdit, setDisableEdit] = useState(false);
    const [fullScreenImage, setFullScreenImage] = useState<any>(null);
    const [images, setImages] = useState<any>([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        if(reservation?.lease) {
            if (reservation.lease.images?.length > 0) setImages(reservation.lease.images);
            if (reservation.lease.confirmed) {
                setApproveOrDenyPhotos(true)
                setDisableEdit(true);
            }
            if (reservation.lease.message) setNewMessage(reservation.lease.message)
        }

    }, [reservation]);

    const fullScreenClicked = (val: any) => {
        setFullScreenImage(val);
        setIsFullScreen(true);
    }

    const photosUploaded = (photos: any) => {
        const arr: any[] = []
        images?.forEach((i: any) => {
            const obj = arr?.find((fuq: any) => fuq?.thumb == i?.thumb);
            if (obj?.thumb) arr.push(i);
        });
        photos?.forEach((p: any) => p?.thumb && arr.push(p));
        if (arr?.length > 0) setImages(arr);
    }

    const saveLeaseClicked = () => {
        callback(images, approveOrDenyPhotos, newMessage);
        setIsOpen(false);
    }

    //-----Pure JSX Functions-----//

    const buttonsJSX = () => {
        return <div className='d-flex justify-content-end'>
            <Button disabled={isLoading} onClick={() => setIsOpen(false)} size='sm' variant='outline-secondary'>
                Back
            </Button>
            {images?.length > 0 && <Button className='ms-1' onClick={() => window?.open(listing?.lease, '_blank')} size='sm' variant='outline-primary'>
                View lease agreement
            </Button>}
            <Button className='ms-1' disabled={(disableEdit && !auth?.currentUser?.email) || isLoading || images?.length == 0} onClick={() => saveLeaseClicked()} size='sm'>
                Save lease info {isLoading && <Spinner animation='border' className='ms-2' size='sm' />}
            </Button>
        </div>
    }

    const ownerSectionJSX = () => {
        let approveOrDenyString = 'Deny';
        if (approveOrDenyPhotos) approveOrDenyString = 'Approve';
        if (disableEdit) approveOrDenyString = 'Approved'
        return <div className='mb-3'>
            {disableEdit ? <p className='fs-8 mb-3'>
                You approved this lease! Your guest is all set to check in.
            </p> : <p className='fs-8 mb-3'>
                Please double check the lease photos that have been uploaded. Make sure your guest hasn't
                missed any signatures or initials on their lease.
                Once you approve we'll notify your guest and give them directions to your place.
            </p>}
            <div className='align-items-center d-flex'>
                <Form.Check checked={approveOrDenyPhotos} className='fs-8 me-3 w-25'
                    disabled={disableEdit} id='approve-or-deny-switch'
                    label={approveOrDenyString}
                    onChange={() => setApproveOrDenyPhotos(!approveOrDenyPhotos)} type='switch' />
                <Form.Control as='textarea' autoComplete='false' className='fs-8' 
                    disabled={disableEdit}
                    onChange={(e) => setNewMessage(e.target.value)} 
                    placeholder={approveOrDenyPhotos === false ? `Explain why you're denying these photos...` : 'Woot! You officially have a new renter! Send them a congratulations..'} type='text' value={newMessage} />
            </div>
        </div>;
    }

    const photosJSX = () => {
        return images?.length > 0 ? <div>
            {images?.filter((m: any) => m?.thumb).map((i: any, idx: number) => {
                return <div className='align-items-center border cursor d-flex justify-content-between mb-2 p-3' key={`photo-upload-${idx}`}>
                    <div className='d-flex'>
                        <h5 className='me-2'>{idx + 1}.</h5>
                        <img onClick={() => fullScreenClicked(i?.thumb)} src={i?.thumb} style={{ borderRadius: '8px', height: '80px', objectFit: 'cover' }} />
                    </div>
                    {auth?.currentUser?.email && <div>
                        <Button className='w-100' onClick={() => setImages(images?.filter((img: any, idx1: number) => idx != idx1))} size='sm' variant='outline-danger'>
                            Delete photo
                        </Button>
                    </div>}
                </div>;
            })}
            {isFullScreen && <div className='full-page-image'>
                <div className='h-100 position-relative'>
                    <img onClick={() => setIsFullScreen(!isFullScreen)} src={fullScreenImage} />
                </div>
            </div>}
        </div> : <></>;
    }

    const viewLeaseAndPhotoUploadButtonsJSX = () => {
        return <div className={['align-items-center d-flex mt-2', images?.length > 0 ? 'py-1' : 'border p-3 rounded'].join(' ')}>
            {images?.length == 0 && <>
                <div className='flex-fill pe-2'>
                    <Button className='w-100' onClick={() => window?.open(listing?.lease, '_blank')} size='sm' variant='outline-primary'>
                        View lease agreement
                    </Button>
                </div>
                {auth?.currentUser?.email ? <div className='flex-fill'>
                    <PhotoUpload callback={(e) => photosUploaded(e)} images={images} justShowUploadButton={true} setImages={() => console.log('')} />
                </div> : <></>}
            </>}
        </div>;
    }

    return (
        <>
            <Modal centered onHide={() => setIsOpen(false)} show={isOpen} backdrop='static'>
                <Modal.Body>
                    <h5 className='mb-3'><u>{images?.length > 0 ? 'Submitted' : 'Unsigned'} lease agreement</u></h5>
                    {auth?.currentUser?.email ? <p className='fs-8'>
                        {images?.length == 0 ? `In order for your booking to be complete, please download and print out the attached lease PDF.
                        Once you've signed it, take a photo of every page you added your signature or initals to, then upload those photos below` :
                            disableEdit ? `Sweet! The owner has approved your photos. You're all set to move in! You can find directions to the place here.` : 
                            `Sweet! The photos you took of your signed lease have been saved. Once the owner confirms that everything looks good,
                            you'll be all set to move in. Woot woot!`}<br />
                    </p> : ownerSectionJSX()}
                    {auth?.currentUser?.email && images?.length > 0 && <div className='align-items-center d-flex justify-content-between my-2'>
                        <h5 style={{ color: 'gray' }}>{images.length} {images?.length == 1 ? ' photo' : ' photos'}</h5>
                        <PhotoUpload callback={(e) => photosUploaded(e)} customTitle='Add more photos'
                            images={images} justShowUploadButton={true} setImages={(e) => console.log('dick', e)} />
                    </div>}
                    {photosJSX()}
                    {viewLeaseAndPhotoUploadButtonsJSX()}
                    {buttonsJSX()}
                </Modal.Body>
            </Modal>
        </>
    )
}
export default LeaseModal;