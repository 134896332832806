import { useContext, useEffect, useRef, useState } from 'react';

import { Button, Col, Form, Overlay, Row, Spinner, Tooltip } from 'react-bootstrap';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import Context from '../models/Context';
import flag from '../images/america.png';

const SignIn = (props) => {
  const { currentUser, setCurrentUser } = props;

  const auth = getAuth();
  const { darkMode } = useContext(Context);
  const navigate = useNavigate();
  const target = useRef(null);

  const [activeType, setActiveType] = useState(0);
  const [confirmationCode, setConfirmationCode] = useState('');
  const countryCode = '+1';
  const [didSendConfirmationCode, setDidSendConfirmationCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [show, setShow] = useState(false);
  const [validPhone, setValidPhone] = useState(true);

  useEffect(() => {
    const screenHeightDiv = document.getElementById('full-height-me-daddy');
    if (screenHeightDiv) {
      screenHeightDiv.style.height = window.innerHeight + 'px';
    }
  }, []);

  useEffect(() => {
    if (phone?.length == 14) {
      const phoneWithoutParensSpacesOrDash = phone.substring(1, 4) + phone.substring(5, 9) + phone.substring(10, phone.length);
      if (isNaN(phoneWithoutParensSpacesOrDash.replace(' ', ''))) {
        setValidPhone(false);
      } else {
        setValidPhone(true);
      }
    }
    if(show) {
      setShow(false);
    }
  }, [phone]);

  const cancelConfirmCode = () => {
    setActiveType(0);
    setIsLoading(false);
  }

  const confirmCode = (e) => {
    setIsLoading(true);
    e.preventDefault();
    window?.confirmationResult?.confirm(confirmationCode).then((result) => {
      setCurrentUser(result.user);
      navigate('/');
    }).catch(() => {
      setIsLoading(false);
    });
  }

  const doLogin = (e) => {
    setIsLoading(true);
    e.preventDefault();
    setDidSendConfirmationCode(true);
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible', 'callback': (response) => { sendPhoneConfirmation(phone); },
      'expired-callback': () => { console.error('Login failed. Your dad doesnt love you wah wah wah.') }
    }, auth);
    sendPhoneConfirmation(phone);
  }

  const isntValidLoginCode = () => {
    let hasError = true;
    if (confirmationCode.length < 6) {
      hasError = false
    } else if (confirmationCode.length == 6 && !isNaN(confirmationCode)) {
      hasError = false;
    }
    return hasError
  }

  const isntValidPhone = () => {
    let hasError = true;

    if(phone.length == 14) {
      if(phone.replace(' ', '').length == 13) {
        hasError = false;
      }
    }
    return hasError;
  }

  const sendPhoneConfirmation = (phone) => {
    if (didSendConfirmationCode) {
      setActiveType(1);
      return;
    }
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, `${countryCode}${phone}`, appVerifier).then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
      setActiveType(1);
      setIsLoading(false);
    }).catch((error) => {
      console.error("error is ", error)
    });
  }

  const updatePhone = (e) => {
    const val = e.target.value;
    if (val.length == 3) {
      setPhone(val.length < phone.length ? val : `(${val}) `)
    } else if (val.length == 9) {
      if (val.length < phone.length && phone.indexOf('-') > -1) {
        setPhone(phone.substring(0, phone.length - 1))
      } else {
        setPhone(`${val}-`);
      }
    } else if (val.length > 14) {
      return;
    } else {
      if(val.length == 5 && val.length < phone.length) {
        setPhone(val.substring(1, 4));
      } else if (val.length == 4 && val.length < phone.length && phone.indexOf(')') > -1) {
        setPhone(phone.substring(1, 4))
      } else if (val.length == 4 && val.indexOf('(') == -1 && val.indexOf(')') == -1) {
        setPhone(`(${val.substring(0, 3)}) ${val[val.length - 1]}`)
      } else if (val.length == 10 && val.indexOf('-') == -1) {
        setPhone(val.slice(0, 9) + '-' + val[val.length - 1])
      } else {
        setPhone(val);
      }
    }
  }

  //-----Pure JSX Functions-----//

  const generateContentJSX = () => {
    if (activeType == 1) {
      return <div className='px-5 w-100'>
        <h3><span className='fw-bold'>huck<span style={{ color: darkMode ? '#59CBE8' : '#C724B1' }}>l</span>Berry</span></h3>
        <Form onSubmit={confirmCode}>
          <Form.Group className='mt-3'>
            <Form.Label>{!isntValidLoginCode() ? 'Confirmation Code' : <span style={{ color: 'red' }}>That confirmation code is {confirmationCode.length == 6 ? 'garbage' : 'too long'}</span>}</Form.Label>
            <Form.Control autoFocus={true} className='w-100' onChange={(e) => setConfirmationCode(e.target.value)} placeholder='X X X X X X' value={confirmationCode} />
          </Form.Group>
          <div className='pt-2 d-flex'>
            <Button className='flex-fill mr-1' disabled onClick={() => cancelConfirmCode()} variant='outline-secondary'>Cancel</Button>
            <Button className='flex-fill ml-1' disabled={isLoading || isntValidLoginCode() || confirmationCode.length != 6} type='submit' variant={isntValidLoginCode() ? 'danger' : 'primary'}>
              {isntValidLoginCode() ? 'Invalid credentials...' : isLoading ? <Spinner animation='border' size='sm' /> : 'Login'}
            </Button>
          </div>
        </Form>
      </div>;
    }
    return <div className='px-5 w-100'>
      <h3><span className='fw-bold'>huck<span style={{ color: darkMode ? '#59CBE8' : '#C724B1' }}>l</span>Berry</span></h3>
      <Form onSubmit={doLogin}>
        <Form.Group className='mt-3'>
          <Form.Label className='align-items-center d-flex'>
            <img onClick={() => setShow(!show)} src={flag} style={{ cursor: 'pointer', marginRight: '6px', width: '30px' }} ref={target} />
            {tooltipJSX()} 
            {validPhone ? <span> US phone numbers only</span> : <span style={{ color: 'red' }}>That phone number is garbage</span>}
          </Form.Label>
          <Form.Control autoFocus={true} className='w-100' isInvalid={!validPhone} onChange={(e) => updatePhone(e)} placeholder='000 000 0000' value={phone} />
        </Form.Group>
        <div className='pt-2 d-flex'>
          <Button className='flex-fill mr-1' disabled={isLoading} onClick={() => navigate('/signinchoose')} variant='outline-secondary'>
            Back
          </Button>
          <Button className='flex-fill ml-1' disabled={isLoading || isntValidPhone()} type='submit'>
            {isLoading ? <Spinner animation='border' size='sm' /> : 'Send login code'}
          </Button>
        </div>
      </Form>
    </div>;
  }

  const titleJSX = () => {
    return <h1 aria-label='hucklberry-home-page' className='mt-5 title huckleberry-small' onClick={() => navigate('/')} style={{ marginTop: '12px' }}>
      hucklBerry
    </h1>;
  }

  const tooltipJSX = () => {
    return <Overlay target={target.current} placement='top' rootClose show={show}>
      {(props) => (
        <Tooltip className='p-1' {...props} onClick={() => setShow(false)}>
          <div className='p-1'>
            <p className='fs-8'>We proudly choose to do business exclusively within the <b>USA</b>. This is to ensure the highest level of safety for our users.</p>
          </div>
        </Tooltip>
      )}
    </Overlay>;
  }

  return (
    <div className='disable-overflow veeh-100' id='full-height-me-daddy' style={{ overflow: 'hidden' }}>
      <Row className='h-100'>
        <Col className='d-none d-lg-block gradient pl-5' lg='6'>
          {titleJSX()}
          <div className='d-flex align-items-center' style={{ height: '90%' }}>
            <div>
              <h3 className='mb-2'>Login/sign up...</h3>
              <p >It only takes 2 seconds ;)</p>
            </div>
          </div>
        </Col>
        <Col className='align-items-center d-flex justify-content-center pl-0 h-100' xs='12' lg='6'>
          {generateContentJSX()}
        </Col>
      </Row>
      <div id='recaptcha-container' />
    </div>
  );
};
export default SignIn;
