import { useContext } from 'react';

import { Col, Container } from 'react-bootstrap';
import { Instagram, Twitter } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

import Context from '../models/Context';
import { Utilities } from '../models';

const Footer = () => {
    const { darkMode } = useContext(Context);
    const navigate = useNavigate();
    const utils = new Utilities();

    const scrollUpAndNavigate = (path: any) => {
        navigate(path);
        window.scrollTo(0, 0);
    }

    //-----Pure JSX Functions-----//
    return (
        <div className='pt-5' style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
            <Col className='footer' xs='12'>
                <Container className='my-5 footer-fatley'>
                    <div className='align-items-center d-flex justify-content-between mb-3 pb-3'>
                        {utils?.titleJSX()}
                        <div className='cursor d-flex'>
                            <Twitter className='mr-3' onClick={() => window.open('https://twitter.com/HucklberryHome', '_blank')} style={{ fontSize: '30px' }} />
                            <Instagram className='' onClick={() => window.open('https://instagram.com/HucklberryHome', '_blank')} style={{ fontSize: '30px' }} />
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='cursor'>
                            <p className='cursor fw-bold mb-2' onClick={() => scrollUpAndNavigate('/about')}><u>Company</u></p>
                            <p className='mb-2' onClick={() => scrollUpAndNavigate('/about')}>About Us</p>
                            <p className='mb-2' onClick={() => scrollUpAndNavigate('/investors')}>Investors</p>
                            <p className='mb-2' onClick={() => scrollUpAndNavigate('/careers')}>Careers</p>
                            <p className='mb-2' onClick={() => scrollUpAndNavigate('/contact')}>Contact</p>
                        </div>
                        <div className='cursor'>
                            <p className='cursor fw-bold mb-2 text-right'><u>Support</u></p>
                            <p className='mb-2 text-right' onClick={() => scrollUpAndNavigate('/faq')}>FAQ</p>
                            <p className='mb-2 text-right' onClick={() => scrollUpAndNavigate('/contact')}>Report a problem</p>
                        </div>
                    </div>
                </Container>
            </Col>
        </div>
    )
}
export default Footer;