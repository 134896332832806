import { useContext, useEffect } from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { House, Search } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

import Context from '../models/Context';

const SignInChooseOwnerOrGuest = () => {
    const { darkMode } = useContext(Context);

    let params = new URLSearchParams(window.location.search);
    const listingId = params.get('listingId');
    const jerryFuckinEastman = params.get('email');
    const isBooking = params.get('isBooking');

    const auth = getAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const screenHeightDiv = document.getElementById('full-height-me-daddy');
        if (screenHeightDiv) {
            screenHeightDiv.style.height = window.innerHeight + 'px';
        }
    }, []);

    useEffect(() => {
        if (auth) {
            checkIfConfirmingEmail();
        }
    }, [auth]);

    const checkIfConfirmingEmail = async () => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            if (jerryFuckinEastman) {
                await signInWithEmailLink(auth, jerryFuckinEastman, window.location.href);
                listingId ? navigate(`/listing/${listingId}?isBooking=${isBooking}`) : navigate('/');
            }
        }
    }

    //-----Pure JSX Functions-----//

    const renterOrHomeownerJSX = () => {
        return <div className='d-flex my-4'>
            <Button className='flex-fill me-1' onClick={() => navigate('/signin')} variant='outline-secondary'>
                I'm a homeowner<House className='ms-1' style={{ marginTop: '-3px' }} />
            </Button>
            <Button className='flex-fill ms-1' onClick={() => navigate('/emailsignin')}>
                I'm a renter <Search className='ms-1' style={{ marginTop: '-3px' }} />
            </Button>
        </div>;
    }

    const titleJSX = () => {
        return <h1 aria-label='hucklberry-home-page' className='mt-4 title huckleberry-small' onClick={() => navigate('/')} >
            hucklBerry
        </h1>;
    }

    return (
        <div className='disable-overflow veeh-100' id='full-height-me-daddy' style={{ overflow: 'hidden' }}>
            <Row className='h-100'>
                <Col className='d-none d-lg-block gradient pl-5' lg='6'>
                    {titleJSX()}
                    <div className='d-flex align-items-center' style={{ height: '90%' }}>
                        <div>
                            <h3 className='mb-2'>Are you a homeowner, or a renter?</h3>
                            <p>Either way, we got you!</p>
                        </div>
                    </div>
                </Col>
                <Col className='align-items-center d-flex h-100' xs='12' lg='6'>
                    <div className='px-5 w-100'>
                        <h3><span className='fw-bold'>huck<span style={{ color: darkMode ? '#59CBE8' : '#C724B1'  }}>l</span>Berry</span></h3>
                        {renterOrHomeownerJSX()}
                    </div>
                </Col>
            </Row>
            <div id='recaptcha-container' />
        </div>
    );
};
export default SignInChooseOwnerOrGuest;
