import { useState, useCallback, useMemo } from 'react';

import { Col } from 'react-bootstrap';
import { GoogleMap, useLoadScript, MarkerF, InfoWindow } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';

import PlacesAutocomplete from './PlacesAutocomplete';

const Maps = (props) => {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: 'AIzaSyAZNiyxrXmftyRky4Fmu8A-9qO0LLcXNe8', libraries: ["places"] });
  if (!isLoaded) return <></>;
  return <Map {...props} />;
}
export default Maps;


function Map({ address, isHeader, listOfListings, onMapPage, selected, setSelected }) {
  const center = useMemo(() => ({ lat: selected?.lat ? selected.lat : 45.68, lng: selected?.lng ? selected.lng : -111.04 }), [selected]);
  const isOnListingPage = window?.location?.pathname?.includes('/listing') || false;
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);

  const getMapCenter = () => {
    if (onMapPage) {
      setSelected({ lat: map.getCenter().lat(), lng: map.getCenter().lng() });
    }
  }

  const onLoad = useCallback((m) => {
    m.setOptions({ minZoom: 4, maxZoom: 18, zoom: 12 });
    setMap(m)
  }, []);

  var image = {
    url: require('../icons/skware.png'),
    labelStyle: { fontWeight: '700' },
    labelOrigin: new window.google.maps.Point(33, 17),
    scaledSize: new window.google.maps.Size(70, 40)
  };

  const openListing = (e, l) => {
    setPopupInfo({ lat: l.lat, lng: l.long });
  }

  //-----Pure JSX Functions-----//

  const listingDetailsJSX = (l) => {
    if (l?.images?.length > 0) return <div key={`rolliePoly-listings-${l.id + 1}`} onClick={() => navigate(`/listing/${l.id}`)}>
      <img className='cursor listing-image' src={l.images[0].thumb} style={{ height: '200px', width: '200px' }} />
      <div className='cursor mt-3'>
        <p className='fw-bold'>{l.city}, {l.state}</p>
        <p>{l.type}</p>
        <p><span className='fw-bold'>${l.price}/night</span></p>
      </div>
    </div>;
    return <></>;
  }

  const markerJSX = (l) => {
    return <MarkerF icon={!isOnListingPage && l.price ? image : null}
      key={`hucklberry-marker-${l.id}`}
      label={!isOnListingPage && l.price ? `$${l.price}` : ''}
      onClick={(e) => isOnListingPage ? null : openListing(e, l)}
      position={{ lat: l.lat, lng: l?.lng ? l.lng : l.long }}
      style={{ fontWeight: '600', fontFamily: 'Dosis' }}>
      {popupInfo?.lat == l.lat ? <InfoWindow>{listingDetailsJSX(l)}</InfoWindow> : <></>}
    </MarkerF>;
  }

  return (
    <>
      {isHeader && <Col className='px-0' xs='12'>
        <PlacesAutocomplete address={address} onMapPage={onMapPage} setSelected={setSelected} />
      </Col>}
      {onMapPage && <GoogleMap center={center} mapContainerStyle={{ width: '100%', height: window?.location?.pathname?.includes('/listing') ? '60vh' : 'calc(100vh - 63px)' }} mapContainerClassName='map-container'
        onClick={() => popupInfo ? setPopupInfo(null) : null}
        onLoad={onLoad}
        options={{ gestureHandling: "greedy" }}
        onDragEnd={() => getMapCenter()}
        zoom={4}>
        {!isHeader && !window?.location?.pathname?.includes('/map') && !isOnListingPage && <Col xs='12'>
          <PlacesAutocomplete address={address} onMapPage={onMapPage} setSelected={setSelected} />
        </Col>}
        {listOfListings?.length > 0 && listOfListings.map((l) => markerJSX(l))}
      </GoogleMap>}
    </>
  );
}