



import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import '../styles/GeneralStyling.css';

interface IConfirmDeleteProps {
    isOpen: boolean,
    img: string,
    setIsOpen: (val: boolean) => void
}

const SeeIdModal = (props: IConfirmDeleteProps) => {
    const navigate = useNavigate();
    const { isOpen, img, setIsOpen } = props;

    const confirmDelete = () => {
        setIsOpen(false);
    }

    //-----Pure JSX Functions-----//

    return (
        <>
            <Modal centered onHide={() => setIsOpen(false)} show={isOpen}>
                <Modal.Body>
                    <Row>
                        <Col className='mb-1' xs='12'>
                            <img style={{ width: '100%' }} src={img} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className='text-right'>
                        <Button onClick={() => confirmDelete()}>Close</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default SeeIdModal;