
import { useContext, useEffect, useState } from 'react';

import { Badge, Button, Col, FormControl, InputGroup, Row, Spinner } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import { useParams } from 'react-router-dom';

import { ApiCalls } from '../models/apiCalls';
import Context from '../models/Context';
import Header from '../components/Header';

const Messaging = () => {
    const apiCalls = new ApiCalls();
    const auth = getAuth();
    const { darkMode } = useContext(Context);
    const { userId } = useParams();

    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [user, setUser] = useState(null)

    useEffect(() => {
        getUser(userId);
        getMessages();
    }, []);

    const deactivateMessages = (arr) => {
        arr.map(n => {
            if (n.from != auth.currentUser.uid) {
                apiCalls.deactivateMessage(n);
            }
        });
    };

    const getMessages = async () => {
        const arr = await apiCalls.getMessages();
        setMessages(arr);
        if (arr.length > 8) {
            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 300);
        }
        setLoading(false);
        deactivateMessages(arr);
    }

    const getUser = async (u) => {
        const user = await apiCalls.getUserById(u);
        setUser(user);
    }

    const saveMessage = () => {
        setLoading(true)
        if (newMessage && newMessage.length > 0) {
            const newMessObj = {
                from: auth.currentUser.uid,
                fromName: auth.currentUser.displayName,
                message: newMessage,
                to: user.id,
                toName: user.email,
            };

            apiCalls.addMessage(newMessObj);
            setMessages([...messages, newMessObj])
            setNewMessage('');
            setLoading(false);
        }
    }

    return (
        <div style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
            <Row>
                <Col className='border-bottom pb-2'>
                    <Header currentUser={user} showAlternate />
                </Col>
            </Row>
            <Row className='pt-5 px-5 text-white' style={{ paddingBottom: '175px' }}>
                {loading ? <div className='align-items-center vh-75 d-flex justify-content-center'>
                    <Spinner animation='border' style={{ height: '180px', width: '180px' }} variant='secondary' />
                </div> :
                    messages && messages.length > 0 ? messages.map((mess, idx) => {
                        return <Col className={[mess.from == auth.currentUser.uid ? 'text-right' : 'text-left', 'my-1'].join(' ')} key={mess.from + idx} xs='12'>
                            <Badge bg={mess.from == auth.currentUser.uid ? 'primary' : 'secondary'}>
                                <h6>{mess.message}</h6>
                            </Badge>
                        </Col>
                    }) : <div className='image-loading'>
                        <p className='message text-center text-pink-light'>say hello..</p>
                    </div>}
            </Row>
            <Row className='border-top fixed-bottom p-3' style={{ backgroundColor: 'lightgray' }}>
                <Col>
                    <InputGroup>
                        <FormControl as='textarea' autoComplete='false' onChange={(e) => setNewMessage(e.target.value)} placeholder="Add a message..." type='text' value={newMessage} />
                        <Button className='bold pink' disabled={!auth.currentUser || newMessage.length < 1 || loading} onClick={saveMessage}>
                            Send message
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )
}
export default Messaging