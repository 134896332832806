import { useContext, useEffect, useState } from 'react';

import { Button, Col, Form, Row } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import { Star, StarFill } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';

import { ApiCalls } from '../models/apiCalls';
import Context from '../models/Context';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ToastVille from '../components/Toast';
import { ReservationStatus } from '../models';

const ReviewPage = (props: any) => {
    const { currentUser } = props;

    const apiCalls = new ApiCalls();
    const auth = getAuth();
    const { darkMode } = useContext(Context);
    const { listingId } = useParams();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const resId = params?.get('reservation');

    const [alreadySubmittedReview, setAlreadySubmittedReview] = useState(false);
    const [listing, setListing] = useState<any>(null);
    const [message, setMessage] = useState('');
    const [reservation, setReservation] = useState<any>(null);
    const [reviews, setReviews] = useState<any>(null);
    const [showToast, setShowToast] = useState('');
    const [starAverage, setStarAverage] = useState<any>(0);
    const [starLevel, setStarLevel] = useState<0 | 1 | 2 | 3 | 4 | 5>(4);

    useEffect(() => {
        window?.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (listingId) {
            getListing();
            getReservation();
            getReviews();
        }
    }, [listingId]);

    useEffect(() => {
        if (reviews?.length > 0) {
            calculateReviewAverage();
        }
    }, [reviews]);

    const calculateReviewAverage = () => {
        let count = 0;
        let total = 0
        reviews.map((r: any) => {
            if (r?.starLevel >= 0) {
                total = total + r?.starLevel;
                count = count + 1;
            }
        });
        setStarAverage(total = (total / count));
    }

    const getListing = async () => {
        const listingData = await apiCalls.getListingById(listingId as any);
        setListing(listingData);
    }

    const getReservation = async () => {
        const resData = await apiCalls.getReservation(auth?.currentUser?.email, listingId);
        if (resData?.length > 0) {
            if (resData[0]?.status !== ReservationStatus.Requested) setReservation(resData[0])
        }
    }

    const getReviews = async () => {
        const reviewData = await apiCalls.getReviewsByListingId(listingId);
        if(reviewData?.length > 0) {    
            reviewData.forEach((r: any) => {
                if(r?.userId == auth?.currentUser?.uid) {
                    setAlreadySubmittedReview(true);
                }
            })
        }
        setReviews(reviewData);
    }

    const saveReview = () => {
        if (validateListing()) {
            apiCalls.addReview({
                listingId: listing?.id, message, name: auth?.currentUser?.displayName,
                userId: auth?.currentUser?.uid, photoURL: auth?.currentUser?.photoURL, starLevel
            });
            setShowToast('Thanks for adding a review! Your feedback is greatly appreciated.');
            getReviews();
        }
    }

    const validateListing = () => {
        if (message == '' || !listing?.id) return false;
        return true;
    }

    //-----Pure JSX Functions-----//

    const createNewListingJSX = () => {
        return <>
            {!alreadySubmittedReview ? <>
                <div className='d-flex justify-content-center'>
                    <div className='w-50'>
                        <Form.Group>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <Form.Label className='fw-bold'>How was your stay?</Form.Label>
                                </div>
                                <div>
                                    {starsGroupJSX()}
                                </div>
                            </div>
                            <Form.Control as='textarea'
                                autoComplete='false'
                                className='fs-8'
                                onChange={(e: any) => setMessage(e.target.value)}
                                placeholder='Be as thorough or brief as you like...'
                                rows={5}
                                type='text'
                                value={message} />
                        </Form.Group>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <div className='w-50 text-end mt-2'>
                        <Button className='me-1' size='sm' variant='outline-secondary'>Cancel</Button>
                        <Button disabled={showToast != ''} onClick={() => saveReview()} size='sm'>Save</Button>
                    </div>
                </div>
            </> : < div className='fw-bold m-3 text-center'>Your review has been submitted! Check it out below</div>}
        </>;
    }

    const reviewsJSX = () => {
        return <div className='d-flex justify-content-center'>
            <div className='w-50'>
                <div className='d-flex justify-content-between my-2'>
                    <p className='fw-bold'>Reviews</p>
                    {reviews?.length > 0 ? <p className='fs-8 mt-1'>{starAverage} star average</p> : <></>}
                </div>

                {reviews?.length > 0 ? reviews.map((r: any) => {
                    return <div className='border-bottom d-flex fs-8 fw-medium pb-2 mb-2' key={`review-${r?.message}`}>
                        <p className='me-3'>{r?.name} -</p>
                        <p>{r?.message}</p>
                    </div>
                }) : <div className='fs-8 text-center'>
                    No reviews yet
                </div>}
            </div>
        </div>;
    }

    const starsGroupJSX = () => {
        return <div className='cursor d-flex'>
            {<div onClick={() => setStarLevel(starLevel == 1 ? 0 : 1)}>{starJSX(1)}</div>}
            {<div onClick={() => setStarLevel(2)}>{starJSX(2)}</div>}
            {<div onClick={() => setStarLevel(3)}>{starJSX(3)}</div>}
            {<div onClick={() => setStarLevel(4)}>{starJSX(4)}</div>}
            {<div onClick={() => setStarLevel(5)}>{starJSX(5)}</div>}
        </div>;
    }

    const starJSX = (val: any) => {
        return <div className='me-1'>
            {starLevel >= val ? <StarFill /> : <Star />}
        </div>
    }

    return (
        <div style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
            <div className='vh-100' style={{ marginBottom: '300px' }}>
                <Row>
                    <Col xs='12'>
                        <Header currentUser={currentUser} />
                    </Col>
                    <Col className='border-top header-padding pt-4 mt-1' lg='12'>
                        {auth?.currentUser?.email == reservation?.guestEmail && createNewListingJSX()}
                        {reviewsJSX()}
                    </Col>
                </Row>
            </div>
            {showToast != '' && <ToastVille message={showToast} />}
            <Footer />
        </div>
    )
}
export default ReviewPage;