
import { useContext, useEffect, useState } from "react";

import { Badge, Button, Card, Col, Collapse, Container, Form, Row, Table } from 'react-bootstrap';
import { getAuth, onAuthStateChanged, updateProfile } from 'firebase/auth';
import { House, Lock, PersonCircle } from 'react-bootstrap-icons';
import { collection, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import { ApiCalls } from '../models/apiCalls';
import Context from '../models/Context';
import Footer from '../components/Footer';
import Header from '../components/Header';
import PhotoUpload from '../components/PhotoUpload';
import ReservationsOrBookings from '../components/ReservationsOrBookings';
import { ReservationStatus } from '../models';
import ToastVille from '../components/Toast';
import UploadGovernmentId from '../components/UploadGovernmentId';
import { Utilities } from '../models';
import venmo from '../images/venmo-logo.png'

interface IAccountPageProps {
    currentUser: any,
    setCurrentUser: (user: any) => void
}

const AccountPage = (props: IAccountPageProps) => {
    const { currentUser, setCurrentUser } = props;

    const apiCalls = new ApiCalls();
    const auth = getAuth() as any;
    const { darkMode } = useContext(Context);
    const db = getFirestore()
    const navigate = useNavigate();
    let params = new URLSearchParams(window.location.search);
    const didEditListing = params.get('didEditListing');
    const utils = new Utilities();

    const [activeBadgeListings, setActiveBadgeListings] = useState(0);
    const [activeBadgeReservations, setActiveBadgeReservations] = useState(0);
    const [annualRevenue, setAnnualRevenue] = useState(0);
    const [didSaveVenmo, setDidSaveVenmo] = useState(false);
    const [inactiveArray, setInactiveArray] = useState([]);
    const [invoices, setInvoices] = useState<any>([]);
    const [isAddingVenmo, setIsAddingVenmo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [myListings, setMyListings] = useState([]);
    const [newDisplayName, setNewDisplayName] = useState('');
    const [newDisplayName1, setNewDisplayName1] = useState<any>(null);
    const [notifications, setNotifications] = useState<any>([]);
    const [reservations, setReservations] = useState([]);
    const [showAlert, setShowAlert] = useState(didEditListing ? `Your listing is now live on Hucklberry. We'll notify you when someone want's to rent your place.` : '');
    const [showEditDisplayName, setShowEditDisplayName] = useState(false);
    const [showEditDisplayName1, setShowEditDisplayName1] = useState(false);
    const [userData, setUserData] = useState<any>({
        displayName: null,
        photoURL: null,
        uid: null,
        username: null,
        venmoUsername: '',
    });
    const [venmoUsername, setVenmoUsername] = useState('');

    onAuthStateChanged(auth, user => {
        if (user) {
            setCurrentUser(user);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (auth?.currentUser) {
            setCurrentUser(auth.currentUser);
            if (auth.currentUser.displayName) {
                setNewDisplayName(auth.currentUser.displayName);
                setShowEditDisplayName(false);
            }
        }
    }, [auth?.currentUser]);

    useEffect(() => {
        if (auth.currentUser) {
            if (auth?.currentUser?.phoneNumber) {
                getListings()
            }
            getReservations();
            getCurrentUserEntry();
        }
    }, [auth.currentUser]);

    useEffect(() => {
        if (myListings?.length > 0) {
            getListingReservations()
        }
    }, [myListings]);

    useEffect(() => {
        if(newDisplayName1 && newDisplayName1 != '') {
            updateCurrentUserEntry(userData?.photoURL);
        }
    }, [newDisplayName1]);

    useEffect(() => {
        if(reservations?.length > 0) {
            let total = 0;
            reservations.filter((r: any) => r?.status == 'Accepted')?.forEach((r: any) => {
                if(!isNaN(r?.price)) {
                    total = total + r?.price;
                }
            });
            if(total > 0) setAnnualRevenue(total);
        }
    }, [reservations]);

    const addFocusListenerForPaidInvoices = () => {
        window.addEventListener('focus', onFocus);
    }

    const getCurrentUserEntry = async () => {
        const arr: any[] = [];
        const q1 = query(collection(db, "users"), where('uid', '==', auth.currentUser.uid));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => {
                let data = doc.data();
                arr.push(data);
            });
            if (arr?.length > 0) {
                setUserData(arr[0]);
                if(arr[0]?.venmoUsername) {
                    setVenmoUsername(arr[0].venmoUsername);
                    setDidSaveVenmo(true);
                }
                if(arr[0]?.displayName) {
                    setNewDisplayName1(arr[0].displayName);
                }
            }
        }
    }

    const getInvoices = async () => {
        const invData = await apiCalls.getInvoicesFromAPI();
        setInvoices(invData);
    }

    const getListings = async () => {
        const listings = await getListingsFromBackend();
        if (listings && listings.length > 0) {
            setMyListings(listings as any);
        }
    }

    const getListingReservations = async () => {
        const arr: any[] = [];
        await Promise.all(myListings?.map(async (l: any) => {
            const req = await getListingReservationsFromAPI(l?.id);
            if (req?.length > 0) {
                arr.push(...req.filter((r: any) => r?.status !== ReservationStatus.Blocked))
            }
        }));
        setReservations(arr as any);
    }

    const getListingReservationsFromAPI = async (id: any) => {
        const arr: any[] = [];
        const q1 = query(collection(db, 'reservations'), where('listingId', '==', id));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => {
                arr.push({ id: doc.id, ...doc.data() });
            });
            return arr;
        }
    }

    const getReservations = async () => {
        const reqs = await getReservationsFromAPI();
        if (reqs && reqs.length > 0) {
            setReservations(reqs.filter((r: any) => r?.status !== ReservationStatus.Blocked && r?.status !== 'ReservationStatus.Denied') as any);
        }
    }

    const getReservationsFromAPI = async () => {
        if (!auth?.currentUser) return;
        const arr: any[] = [];
        const q1 = query(collection(db, 'reservations'), where('userId', '==', auth.currentUser.uid));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => {
                arr.push({ id: doc.id, ...doc.data(), isRequest: true });
            })
            return arr;
        }
    }

    const getListingsFromBackend = async () => {
        if (!auth?.currentUser) return;
        const arr: any[] = [];
        const inactiveArr: any[] = [];
        const q1 = query(collection(db, "listings"), where('userId', '==', auth.currentUser.uid));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => {
                let data = doc.data();
                if (data.active) {
                    arr.push({ id: doc.id, ...data });
                } else {
                    inactiveArr.push({ id: doc.id, ...data });
                }
            })
            if (inactiveArr?.length > 0) {
                setInactiveArray(inactiveArr as any);
            }
            return arr;
        }
    }

    const onFocus = () => {
        getInvoices();
        window.removeEventListener('focus', onFocus);
    }

    const saveVenmoClicked = async () => {
        if(venmoUsername == '') return;
        setIsAddingVenmo(!isAddingVenmo);
        updateCurrentUserEntry(userData?.photoURL ?? auth?.currentUser?.photoURL);
        setShowAlert('Your venmo username has been updated successfully.');
        setDidSaveVenmo(true);
    }

    const updateCurrentUserEntry = async (url: any) => {
        if (!auth?.currentUser) return;
        const arr: any[] = [];
        const q1 = query(collection(db, "users"), where('uid', '==', auth.currentUser.uid));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            setDoc(doc(db, 'users', auth.currentUser.uid), { displayName: newDisplayName1  ?? '', username: auth.currentUser.displayName, photoURL: url == null || url == '' ? '' : url, uid: auth.currentUser.uid, 
                venmoUsername: venmoUsername }).then(() => {
                setUserData({ displayName: newDisplayName1  ?? '', username: auth.currentUser.displayName, photoURL: url, uid: auth.currentUser.uid, venmoUsername });
            });
        } else {
            querySnapshot1.forEach((d) => {
                const l = doc(db as any, 'users', d.id);
                getDoc(l).then((data: any) => {
                    updateDoc(l, {
                        displayName: newDisplayName1  ?? '',
                        photoURL: url == null || url == '' ? '' : url,
                        username: auth.currentUser.displayName ? auth.currentUser.displayName : '',
                        venmoUsername: venmoUsername
                    }).then(() => {
                        setUserData({
                            displayName: newDisplayName1  ?? '',
                            photoURL: url,
                            username: auth.currentUser.displayName ? auth.currentUser.displayName : '',
                            venmoUsername
                        })
                    });
                });
            });
        }
    }

    const showEmptyMessageForListings = (val: string) => {
        switch (activeBadgeListings) {
            case 2: return <p>You don't have any {val}<br />in progress.</p>;
            case 1: return <p>You don't have any inactive<br />{val}</p>;
            default: return <p>You don't have any active<br />{val}.</p>;
        }
    }

    const updateCurrentUser = (url: string) => {
        setIsLoading(true);
        setShowEditDisplayName(!showEditDisplayName);
        updateProfile(auth?.currentUser as any, {
            displayName: newDisplayName,
            photoURL: url,
        }).then(() => {
            setIsLoading(false);
        });
    }

    const uploadNewProfilePhoto = () => document?.getElementById('fuckloadButton')?.click();

    const updateUserPhoto = (photo: string) => updateCurrentUserEntry(photo);

    //-----Pure JSX Functions-----//

    const connectVenmoOrInputJSX = () => {
        return <div>
            <Collapse in={isAddingVenmo}>
                <div>
                    <Form.Control className='mb-1' onChange={(e) => setVenmoUsername(e?.target?.value)} placeholder='Venmo handle..' size='sm' type='text' value={venmoUsername} />
                    <div className='d-flex justify-content-between'>
                        <Button className='flex-fill me-1' onClick={() => setIsAddingVenmo(false)} size='sm' variant='light'>
                            Cancel
                        </Button>
                        <Button className='flex-fill' disabled={venmoUsername == '' || venmoUsername?.length < 3} onClick={() => saveVenmoClicked()} size='sm'>
                            Submit
                        </Button>
                    </div>
                </div>
            </Collapse>
            <Collapse in={!isAddingVenmo}>
                <div>
                    <Button className='w-100' onClick={() => setIsAddingVenmo(!isAddingVenmo)} size='sm'>
                        {didSaveVenmo ? venmoUsername : 'Connect venmo'}
                    </Button>
                </div>
            </Collapse>
        </div>;
    }

    const editDisplayNameJSX = () => {
        return <div className='border-bottom d-flex justify-content-between pb-3 pe-1'>
            <div>
                <h5 className='mb-2'>Public username</h5>
                {showEditDisplayName1 ? <Form.Control onChange={(e) => setNewDisplayName1(e.target.value)} placeholder='Public username' value={newDisplayName1} /> :
                    <p className='light-gray'>{newDisplayName1 ?? 'not set'}</p>}
            </div>
            <div>
                {showEditDisplayName1 ? <Button disabled={newDisplayName1 == ''} 
                    onClick={() => setShowEditDisplayName1(!showEditDisplayName1)} 
                    size='sm'><Lock className='mr-2' />Save</Button> :
                    <Button onClick={() => setShowEditDisplayName1(true)} size='sm' variant={darkMode ? 'outline-primary' : 'primary'}>
                        Edit
                    </Button>}
            </div>
        </div>
    }

    const editLegalNameJSX = () => {
        return <div className='border-bottom border-top d-flex justify-content-between py-3 pe-1'>
            <div>
                <h5 className='align-items-center d-flex justify-content-between mb-2'>Legal name {utils.privateJSX()}</h5>
                {showEditDisplayName ? <Form.Control onChange={(e) => setNewDisplayName(e.target.value)} placeholder='100% confidential' value={newDisplayName} /> :
                    <p className='light-gray'>{auth?.currentUser?.displayName ? auth.currentUser.displayName : 'not set'}</p>}
            </div>
            <div>
                {showEditDisplayName ? <Button disabled={newDisplayName == ''} onClick={() => updateCurrentUser(auth?.currentUser?.photoURL ? auth.currentUser?.photoURL : '') as any}
                    size='sm'><Lock className='mr-2' />Save</Button> :
                    <Button onClick={() => setShowEditDisplayName(true)} size='sm' variant={darkMode ? 'outline-primary' : 'primary'}>
                        Edit
                    </Button>}
            </div>
        </div>
    }

    const generateButtonsJSX = (l: any) => {
        const matchedInvoice = invoices?.find((i: any) => i?.listingId == l?.listingId);
        if (matchedInvoice) return showBookingOrPayInvoiceButtonJSX(matchedInvoice);
        if (l.status == 'Requested') return <Button className='cursor w-100' disabled size='sm' variant='outline-secondary'>Request sent</Button>;
        if (activeBadgeListings == 0) {
            return auth?.currentUser?.email ? <Button className='cursor w-100' disabled size='sm' variant='outline-secondary'>Request sent</Button> :
                <Button className='w-100' onClick={() => navigate(`/become-a-host/${l.id}`)} size='sm' variant='outline-primary'>Modify listing</Button>
        }
        return auth?.currentUser?.email ? <Button className='w-100' onClick={() => navigate(`/listing/${l?.listingId}`)} size='sm' variant='outline-success'>View</Button> :
            <Button className='w-100' disabled size='sm' variant='secondary'>Locked</Button>;
    }

    const landlordDashboardJSX = () => {
        return <div>
            <Row>
                <Col xs='6' lg='12'>
                    <Card className='mb-4 py-3'>
                        <p className='fw-bold mb-4 text-center text-truncate'>
                            <u>Annual revenue</u>
                        </p>
                        <div className='d-flex justify-content-center position-relative px-3'>
                            <h1 className={['fs-2 total-revenue', darkMode ? 'blackify' : 'whiteify'].join(' ')} style={{ backgroundImage: darkMode ? `linear-gradient(0deg, black ${annualRevenue}%, #222222 ${annualRevenue}%)` : 
                                        `linear-gradient(0deg, blue ${annualRevenue}%, rgb(247, 247, 247) ${annualRevenue}%)` }}>
                                ${annualRevenue}
                            </h1>
                        </div>
                    </Card>
                </Col>
                <Col xs='6' lg='12'>
                    <Card className='mb-4 py-3'>
                        <p className='fw-bold mb-4 text-center text-truncate'>
                            <u>Withdraw</u>
                        </p>
                        <div className='fuckopelli px-3 d-flex align-items-center'>
                            <div className='cursor position-relative text-center w-100'>
                                <div className='d-none d-lg-block'>
                                    <img className='jahVenley mb-3' src={venmo} />
                                </div>
                                {connectVenmoOrInputJSX()}
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xs='6' lg='12'>
                    <Card className='mb-4 py-3'>
                        <p className='fw-bold mb-4 text-center text-truncate'>
                            <u>Occupancy rate</u>
                        </p>
                        <div className='align-items-center d-flex fuckopelli justify-content-center position-relative'>
                            <h1 className='fs-2'>0%</h1>
                        </div>
                    </Card>
                </Col>
                <Col xs='6' lg='12'>
                    <Card className='mb-4 py-3'>
                        <p className='fw-bold mb-4 text-center text-truncate'>
                            <u>Current balance</u>
                        </p>
                        <div className='align-items-center fuckopelli d-flex justify-content-center position-relative'>
                            <div><h1 className='fs-2'>${annualRevenue}</h1></div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>;
    }

    const phoneJSX = () => {
        return <div className='border-bottom d-flex justify-content-between py-3 pe-1'>
            {auth?.currentUser?.phoneNumber ? <div>
                <h5 className='align-items-center d-flex mb-2'>Phone number {utils.privateJSX()}</h5>
                <p className='light-gray'>{auth?.currentUser?.phoneNumber}</p>
            </div> : <div>
                <h5 className='mb-2'>Email address</h5>
                <p className='light-gray'>{auth?.currentUser?.email}</p>
            </div>}
            <div>
                <Button disabled size='sm' variant='outline-secondary'><Lock className='mr-2' />Locked</Button>
            </div>
        </div>;
    }

    const showBookingsJSX = () => {
        return <Col className='px-4 py-3' xs='12'>
            <div className='d-flex justify-content-betweeen'>
                <h2 className='pb-4'>Your bookings</h2>
            </div>
            <div className='badges d-flex pb-3'>
                <Badge className={activeBadgeListings === 0 ? 'active-badge' : ''} onClick={() => setActiveBadgeListings(0)} pill bg='light'>
                    Active ({reservations?.length})
                </Badge>
            </div>
            <div className={[myListings?.length == 0 || activeBadgeListings !== 0 ? 'd-flex align-items-center' : '', 'light-gray no-guests', 'py-5 me-1'].join(' ')}>
                {reservations?.length > 0 ? <div className='w-100'>
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>Address</th>
                                <th>Nightly rate</th>
                                <th>Beds/Baths</th>
                                <th>Category</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservations.map((l: any, index: number) => {
                                return <tr key={`hucklberry-home-rental-${index}`}>
                                    <td>{l.address}</td>
                                    <td className='fw-bold'>${l.price}</td>
                                    <td>{l.numBedrooms} bed / {l.numBaths} bath</td>
                                    <td>{l.type}/{l.category}</td>
                                    <td>
                                        {generateButtonsJSX(l)}
                                    </td>
                                </tr>;
                            })}
                        </tbody>
                    </Table>
                </div> :
                    <div className='text-center w-100'>
                        <h2><House /></h2>
                        {showEmptyMessageForListings('booking requests')}
                    </div>}
            </div>
        </Col>;
    }

    const showCurrentUserInfoJSX = () => {
        return <div className='car-talk'>
            {editDisplayNameJSX()}
            {editLegalNameJSX()}
            {phoneJSX()}
            <UploadGovernmentId callback={updateCurrentUser} currentUser={currentUser} />
        </div>;
    }

    const showBookingOrPayInvoiceButtonJSX = (inv: any) => {
        if (inv?.stripeInvoiceStatus != 'paid') return <Button className='w-100' href={inv?.stripeInvoiceUrl} onClick={() => addFocusListenerForPaidInvoices()} target='_blank' size='sm'>Pay Invoice</Button>
        return <Button className='w-100' onClick={() => navigate(`/booking/${inv?.listingId}/${inv?.email}`)} size='sm' variant='outline-primary'>View booking</Button>;
    }

    const showListingsJSX = () => {
        let arr: any[] = [];
        if (activeBadgeListings == 0) {
            arr = myListings
        } else {
            arr = inactiveArray
        }
        return <div className='p-teezley'>
            <div className='d-flex justify-content-between'>
                <h2>Your active listings</h2>
                <div className='badges'>
                    <Badge className={activeBadgeListings === 0 ? 'active-badge' : ''} onClick={() => setActiveBadgeListings(0)} pill bg='light'>
                        Active ({myListings?.length})
                    </Badge>
                </div>
            </div>

            <div className={[myListings?.length == 0 || activeBadgeListings !== 0 ? 'd-flex align-items-center' : '', 'light-gray no-guests', 'py-5'].join(' ')}>
                {arr?.length > 0 ? <div>
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>Address</th>
                                <th>Nightly rate</th>
                                <th>Beds/Baths</th>
                                <th>Category</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {myListings.map((l: any, index: number) => {
                                return <tr key={`hucklberry-home-rental-${index}`}>
                                    <td>{l.address}</td>
                                    <td className='fw-bold'>${l.price}</td>
                                    <td>{l.numBedrooms} bed / {l.numBaths} bath</td>
                                    <td>{l.type}/{l.category}</td>
                                    <td>
                                        {generateButtonsJSX(l)}
                                    </td>
                                </tr>;
                            })}
                        </tbody>
                    </Table>
                </div> :
                    <div className='text-center w-100'>
                        <h2><House /></h2>
                        {showEmptyMessageForListings('listings')}
                    </div>}
            </div>
        </div>;
    }

    return (
        <div style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
            <Row>
                <Col xs='12'>
                    <Header currentUser={currentUser} notifications={notifications}
                        invoices={invoices} setInvoices={setInvoices} setNotifications={setNotifications} setCurrentUser={setCurrentUser} />
                </Col>
                <Col className='border-top p-teezley' xs='12'>
                    <Container fluid='xl'>
                        <Row>
                            <Col className='mb-3' xs='12'>
                                <div className='align-items-center d-flex justify-content-between em-beezley'>
                                    <h2>{auth?.currentUser?.email ? 'Renter dashboard' : 'Landlord dashboard'}</h2>
                                    <div>
                                        {userData?.photoURL ? <div className='cursor position-relative round-image' onClick={() => uploadNewProfilePhoto()}>
                                            <img src={userData.photoURL} alt='hucklberry-userPhoto' />
                                        </div> : <div className='cursor mt-1' onClick={() => uploadNewProfilePhoto()}>
                                            <PersonCircle style={{ fontSize: '47px', color: 'lightgray' }} />
                                        </div>}
                                    </div>
                                </div>
                            </Col>
                            <Col xs='12' lg='3'>
                                {landlordDashboardJSX()}
                            </Col>
                            <Col xs='12' lg='9'>
                                {showCurrentUserInfoJSX()}
                                {auth?.currentUser?.email ? showBookingsJSX() : <div className='car-talk'>
                                    <Row className='my-4'>
                                        <Col className='dem-beezley' xs='12' lg='6'>
                                            <ReservationsOrBookings activeHook={activeBadgeReservations}
                                                invoices={invoices}
                                                isBookings={false}
                                                notifications={notifications}
                                                reservations={reservations}
                                                setActiveHook={setActiveBadgeReservations} />
                                        </Col>
                                        <Col className='dem-beezley' xs='12' lg='6'>
                                            <ReservationsOrBookings activeHook={activeBadgeReservations}
                                                invoices={invoices}
                                                isBookings={true}
                                                notifications={notifications}
                                                reservations={reservations}
                                                setActiveHook={setActiveBadgeReservations} />
                                        </Col>
                                        <Col xs='12'>
                                            {showListingsJSX()}
                                        </Col>
                                    </Row>
                                </div>}
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Footer />
            </Row>
            <PhotoUpload callback={(e) => updateUserPhoto(e[0].thumb)} isId={true} hideButton={true} images={[]} setImages={() => console.log('dd')} />
            {showAlert != '' && <ToastVille message={showAlert} setShowToast={setShowAlert} />}
        </div>
    )
}
export default AccountPage;