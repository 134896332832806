import { useContext } from 'react';

import { Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Context from '../models/Context';
import Footer from '../components/Footer';
import Header from '../components/Header';

interface IInvestorsPageProps {
    currentUser: any,
    setCurrentUser: (val: any) => void
}

const InvestorsPage = (props: IInvestorsPageProps) => {
    const { currentUser, setCurrentUser } = props;
    const { darkMode } = useContext(Context);
    const navigate = useNavigate();

    //-----Pure JSX Functions-----//

    const aboutUsJSX = () => {
        return <Container>
            <Row className='justify-content-md-center'>
                <Col xs='12' lg='7' xl='6'>
                    <h5 className='border-bottom fw-bold pb-3 mb-4'>Investing in Hucklberry</h5>
                    <Form>
                        <Form.Group className='mb-4 pb-4'>
                            <Form.Text className={darkMode ? 'text-lightgray' : 'text-muted'}>
                                <div className='border-bottom'>
                                    Due to an overwhelming amount of inquiries regarding this topic, we felt the need to make a damn webpage about it.
                                    <br /><br />
                                </div>
                                <div className='border-bottom'>
                                    <br/>
                                    At Hucklberry we're proud of lots of things, however one of our proudest accomplishments is that we bootstrapped our company from the ground up.<br/><br/>
                                    We've taken exactly $0 in investments to date, meaning that our engineers retain full ownership of our company. This has allowed us to remain focused on 
                                    our mission of making home rentals easy and affordable for everyone.
                                    <br/><br/>
                                </div>
                                <div className='border-bottom'>
                                    <br/>
                                    We believe in answering to no one but our customers, not some fancy schmancy board of directors. <br /> <br/>
                                    With that said, that doesn't mean we're not always looking for help! Do you see opportunity in our company and want to get involved? 
                                    In that case, check out our <span className='cursor fw-bold' onClick={() => navigate('/careers')}>Careers page</span>. 
                                    <br/><br/>Otherwise, take your money and go invest it in Gamestop or something ;)
                                    <br /><br />
                                </div>
                                <div className='p-5 text-center' style={{ color: 'gray' }}>
                                    <span className='fw-bold'>Sincerely - </span> Our Salty Founders
                                </div>
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>;
    }

    return (
        <>
            <div className='pb-5' style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
                <Row>
                    <Col xs='12'>
                        <Header currentUser={currentUser} hideCenter={true}
                            setCurrentUser={setCurrentUser} />
                    </Col>
                    <Col className='border-top header-padding pt-4 mt-1' lg='12'>
                        {aboutUsJSX()}
                    </Col>
                </Row>
            </div>
            <Footer />
        </>
    )
}
export default InvestorsPage;