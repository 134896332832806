
import { useContext, useEffect, useState } from 'react';

import { BellFill, MoonStars, Person, PersonCircle, Sun } from 'react-bootstrap-icons';
import { Button, Col, Dropdown, Nav, Row } from 'react-bootstrap';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { ApiCalls } from '../models/apiCalls';
import ChooseDateRangeModal from './ChooseDateRangeModal';
import ChooseLocationModal from './ChooseLocationModal';
import Context from '../models/Context';
import huck from '../images/america.png';
import Maps from './Map';
import NotificationModal from './NotificationModal';
import { Utilities } from '../models/index'

interface IHeaderProps {
    currentUser: any,
    hideCenter?: boolean,
    invoices?: any,
    notifications?: any,
    rentalType?: string | null,
    showAlternate?: boolean,
    setCurrentUser?: (user: any) => void,
    setInvoices?: (val: any) => void,
    setNotifications?: (val: any) => void,
}

const Header = (props: IHeaderProps) => {
    const { hideCenter, invoices, notifications, rentalType, showAlternate, setInvoices, setNotifications } = props;
    const { darkMode, setDarkMode } = useContext(Context);
    const utils = new Utilities();

    let params = new URLSearchParams(window.location.search);
    const addr = params.get('addr');
    const apiCalls = new ApiCalls();
    const auth = getAuth();
    const navigate = useNavigate();

    const [address, setAddress] = useState<any>(addr ? addr : '');
    const [chosenRentalType, setChosenRentalType] = useState(rentalType ? rentalType : 'Vacation rentals');
    const [newAddress, setNewAddress] = useState<any>(null);
    const [openDateRangeModal, setOpenDateRangeModal] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [openLocationModal, setOpenLocationModal] = useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);

    useEffect(() => {
        if(newAddress) {
            if(window?.location?.pathname.includes('/map')) {
                window.location.href = `/map?addr=${newAddress?.addr}&lat=${newAddress?.lat}&lng=${newAddress?.lng}`;
            } else {
                navigate(`/map?addr=${newAddress?.addr}&lat=${newAddress?.lat}&lng=${newAddress?.lng}&type=${chosenRentalType}`);
            }
        }
    }, [newAddress]);

    useEffect(() => {
        if (auth.currentUser) {
            getNotifications();
            getInvoices();
        }
    }, [auth.currentUser]);

    const didSelectNewAddress = (adr: any) => {
        setNewAddress({
            addr: getAddressFromArray(adr),
            lat: adr?.lat,
            lng: adr?.lng
        });
    }

    const getAddressFromArray = (adr: any) => {
        if ((adr as any).addressArray?.length > 0) {
            var addr = '';
            (adr as any)?.addressArray?.map((a: any) => {
                const idx = a?.types?.findIndex((b: any) => b == 'street_number' || b == 'route' || b == 'locality' || b == 'administrative_area_level_1' || b == 'postal_code' || b == 'country');
                if (idx > -1) {
                    addr = addr + ' ' + (a as any).short_name
                }
            });
            return addr;
        }
        return '';
    }

    const getNotifications = async () => {
        const notifs = await apiCalls.getNotifications();
        if (notifs?.length > 0 && setNotifications) {
            setNotifications(notifs);
        }
    }

    const getInvoices = async () => {
        const invs = await apiCalls.getInvoicesFromAPI();
        if (invs?.length > 0 && setInvoices) {
            setInvoices(invs);
        }
    }

    const logout = () => {
        signOut(auth).then(() => {
            navigate('/signinchoose');
        });
    }

    //-----Pure JSX Functions-----//

    const profileJSX = () => {
        return <div>
            <Dropdown align='end' className='position-relative'>
                <div>
                    {auth?.currentUser && redDotNotificationCountJSX()}
                    <div className='d-flex' onClick={() => setOpenDropdown(!openDropdown)}>
                        <Dropdown.Toggle className='align-items-center d-flex blue-border px-2'
                            variant={darkMode ? 'dark' : 'outline-secondary'}>
                            <h5 className='me-1'><Person /></h5>
                            <img className='ml-1 profile-image' src={auth?.currentUser?.photoURL ? auth.currentUser.photoURL : huck} alt='user-profile-image' />
                        </Dropdown.Toggle>
                    </div>
                </div>
                <Dropdown.Menu show={openDropdown} variant='dark'>
                    {!auth?.currentUser ? <>
                        <Dropdown.Item onClick={() => navigate('/signinchoose')}>Login</Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate('/signinchoose')}>Sign up</Dropdown.Item>
                    </> : <>
                        <Dropdown.Item onClick={() => navigate('/account')}>My account</Dropdown.Item>
                        {auth?.currentUser?.email ? <Dropdown.Item onClick={() => navigate('/account')}>My bookings</Dropdown.Item> :
                            <Dropdown.Item onClick={() => navigate('/become-a-host')}>Create listing</Dropdown.Item>}
                    </>}
                    <Dropdown.Item onClick={() => navigate('/contact')}>Contact us</Dropdown.Item>
                    {auth?.currentUser && <Dropdown.Item className='pb-2' onClick={() => logout()}>Logout</Dropdown.Item>}
                    <Dropdown.Item className='align-items-center d-flex pt-2' onClick={() => setDarkMode(!darkMode)} style={{ borderTop: '1px solid gray' }}>
                        {!darkMode ? <MoonStars className='me-2' /> : <Sun className='me-2' />}
                        {darkMode ? 'Show light mode' : 'Show dark mode'}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>;
    }

    const redDotNotificationCountJSX = () => {
        if (!notifications && !invoices) return;
        const noties = [...notifications?.filter((n: any) => n.active), ...invoices?.filter((i: any) => i.active)];
        if (noties?.length == 0) return <div className='pin-me' onClick={() => setOpenNotificationModal(true)}><BellFill /></div>;
        return noties?.length >= 10 ? <div className='cursor pin-me-down-daddy-2' onClick={() => setOpenNotificationModal(true)}>
                <span>{noties.length > 99 ? 99 : noties.length}</span>
            </div> :
            <div className='cursor pin-me-down-daddy' onClick={() => setOpenNotificationModal(true)}>
                <span>{noties.length}</span>
            </div>;
    }

    const searchJSX = () => {
        const isCreateListingPage = window?.location?.pathname.includes('/become-a-host');
        return !isCreateListingPage && !hideCenter ? <div>
            <Nav className='d-flex px-2' variant='pills' activeKey='1'>
                <Nav.Item className='flex-fill pe-1'>
                    <Maps address={address} setSelected={didSelectNewAddress} isHeader={true} onMapPage={false} />
                </Nav.Item>
                <Dropdown className='blue-border d-none d-lg-block'>
                    <Dropdown.Toggle variant='dark' id='dropdown-basic'>
                        {chosenRentalType}
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant='dark'>
                        <Dropdown.Item onClick={() => setChosenRentalType('Vacation rentals')}>Vacation rentals</Dropdown.Item>
                        <Dropdown.Item onClick={() => setChosenRentalType('Monthly')}>Monthly</Dropdown.Item>
                        <Dropdown.Item onClick={() => setChosenRentalType('Year lease')}>Year lease</Dropdown.Item>
                        <Dropdown.Item onClick={() => setChosenRentalType('Sublet')}>Sublet</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Nav>
        </div> : <></>;
    }


    return (
        <div className='header header-padding'>
            <div className='py-2'>
                {showAlternate ? <Row>
                    <Col className='align-items-center d-flex justify-content-between' xs='12'>
                        <div>
                            <Button onClick={() => navigate(-1)} size='sm' variant='outline-dark'>Back</Button>
                        </div>
                        <div>{utils.titleJSX()}</div>
                        <div className='pe-2'>{profileJSX()}</div>
                    </Col>
                </Row> : <Row>
                    <Col className='pt-ku' xs='1' lg='3'>
                        {utils.titleJSX()}
                    </Col>
                    <Col className='pt-1' xs='9' lg='6'>
                        <div className='me-2'>
                        {searchJSX()}
                        </div>
                    </Col>
                    <Col className='d-flex justify-content-end pt-1' xs='2' lg='3'>
                        {profileJSX()}
                    </Col>
                    {openDateRangeModal && <ChooseDateRangeModal isOpen={openDateRangeModal} setIsOpen={setOpenDateRangeModal} />}
                    {openLocationModal && <ChooseLocationModal isOpen={openLocationModal} setIsOpen={setOpenLocationModal} />}
                    {openNotificationModal && <NotificationModal isOpen={openNotificationModal}
                        notifications={notifications}
                        invoices={invoices}
                        setIsOpen={setOpenNotificationModal}
                        setInvoices={setInvoices ? setInvoices : null}
                        setNotifications={setNotifications ? setNotifications : null} />}
                </Row>}
            </div>
        </div>
    )
}
export default Header;