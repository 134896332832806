
import { useEffect } from 'react';

import { Dropdown, Form } from 'react-bootstrap';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

const PlacesAutocomplete = ({ address, onMapPage, setSelected }) => {
  const { clearSuggestions, ready, value, setValue, suggestions: { status, data }, } = usePlacesAutocomplete();
  const onCreateListingPage = window?.location?.pathname?.includes('/become-a-host');

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng, addressArray: results[0].address_components });
  };

  return (
    <div className='flex-fill rounded'>
      <Dropdown>
        <div className={onMapPage ? 'pt-4' : ''}>
          <Dropdown.Toggle className={['d-flex fw-bold w-100', onMapPage ? 'p-3' : 'p-0'].join(' ')} id='dropdown-basic' variant='dark'>
            <Form.Control value={value} 
            onChange={(e) => setValue(e.target.value)} placeholder={address ? address : (onCreateListingPage ? 'Search for address..' : 'Search for a city..')} 
            variant='dark'
            style={{ minWidth: '200px' }} />
          </Dropdown.Toggle>
        </div>
        {data?.length > 0 && <Dropdown.Menu className='w-100' variant='dark'>
          {status === "OK" && data.map(({ place_id, description }) => <Dropdown.Item className='fleeklet' key={place_id} onClick={() => place_id ? handleSelect(description) : null}>
            {description}
          </Dropdown.Item>)}
        </Dropdown.Menu>}
      </Dropdown>
    </div>
  );
}
export default PlacesAutocomplete;