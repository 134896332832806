import { useContext } from 'react';

import { addDoc, collection, doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { Button } from 'react-bootstrap';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';

import Context from '../models/Context';

const PaymentsPage = (props) => {
  const { currentUser, setCurrentUser }  = props;

  const auth = getAuth();
  const { darkMode } = useContext(Context);
  const db = getFirestore();

  onAuthStateChanged(auth, user => {
    if (user) {
      setCurrentUser(user);
    }
  });

  const sendToCheckout = async () => {
    if (!auth.currentUser) return;

    const docRef = doc(db, "users", auth.currentUser.uid);
    const colRef = collection(docRef, "checkout_sessions")
    addDoc(colRef, {
      price: 'price_1LwjXkC3dgGpDwNwuPXSqTf9',
      success_url: window.location.origin,
      cancel_url: window.location.origin
    }).then(async (d) => {
      onSnapshot(d, async (doc) => {
        const { error, sessionId } = doc.data();
        if (error) {
          alert(`wah wah wah. An error occured: ${error.message}`);
        }
        if(sessionId) {
          const stripe = await loadStripe('pk_test_51KDjVeC3dgGpDwNwlr1Ec7WiOm4CC1Myjmp4rvK497rViXxPTJbOnpujHElJXw3KrsA1oCJSeBWdVClsc16xbqBd00MeUo4JxR');
          await stripe.redirectToCheckout({ sessionId });
        }
      });
    });
  }

  const sendToCustomerPortal = async () => {
    const functionRef = httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
  }

  //-----Pure JSX Functions-----//

  return (
    <div style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
      <Button onClick={() => sendToCheckout()}>Send to checkout</Button>
      <Button onClick={() => sendToCustomerPortal()}>Manage account</Button>
    </div>
  )
}
export default PaymentsPage;