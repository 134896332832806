

import { useContext, useEffect, useState } from 'react';

import { Button, ProgressBar, Spinner } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { Images, Trash } from 'react-bootstrap-icons';

import Context from '../models/Context';

interface IPhotoUploadProps {
    blockBackBtn?: boolean,
    buttonTitle?: string,
    callback?: (arr: any) => void,
    customTitle?: string,
    hideButton?: boolean,
    images?: any[],
    isId?: boolean,
    justShowUploadButton?: boolean,
    setImages: (arr: any) => void,
    step?: any,
    setShowUpdateUserName?: (val: any) => void,
    setStep?: (s: any) => void,
}

const PhotoUpload = (props: IPhotoUploadProps) => {
    const { blockBackBtn, buttonTitle, callback, customTitle, hideButton, images, isId, 
        justShowUploadButton, setImages, step, setShowUpdateUserName, setStep } = props;

    const auth = getAuth();
    const { darkMode } = useContext(Context);
    const storage = getStorage();

    const arr: any[] = [];
    let cuckArray: any[] = images && images?.length > 0 ? [...images] : [];
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    if (images && images?.length > 0) {
        images.forEach(i => arr.push({ result: i }));
    }
    const [imagesToDisplay, setImagesToDisplay] = useState(arr.length > 0 ? arr : []);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (imagesToDisplay?.length > 0) {
            setIsLoading(false);
            if(callback && isId) {
                uploadMultipleImages();
            }
        }
    }, [imagesToDisplay]);

    const onImageChange = async (e: any) => {
        const files = e?.target.files;
        const filesArray = await Promise.all([].map.call(files, function (file) {
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onloadend = function () {
                    resolve({ result: reader.result, file: file });
                };
                reader.readAsDataURL(file);
            });
        }));
        if (filesArray?.length > 0) {
            setImagesToDisplay([...filesArray, ...imagesToDisplay] as any);
        }
    }

    const removeImage = (idx: number) => {
        const arr: any[] = [];
        setImages(null);
        for (let i = 0; i < imagesToDisplay.length; i++) {
            if (i != idx) {
                arr.push(imagesToDisplay[i]);
            }
        }
        setImagesToDisplay(arr)
        const newListOfUrls: any[] = [];
        arr.map(a => newListOfUrls.push(a.result));
        setImages && setImages([]);
    }

    const uploadMultipleImages = () => {
        setIsLoading(true);
        cuckArray = [];
        imagesToDisplay.map((i) => { uploadToFirebase(i) });
    }

    const uploadToFirebase = async (img: any) => {
        if (!loading) {
            setLoading(true);
        }
        if (img?.file?.name) {
            const storageRef = ref(storage, `images/${auth?.currentUser?.displayName ? auth.currentUser.displayName : 'unknown'}/${img?.file?.name}`);
            await getDownloadURL(storageRef).then((url) => {
                cuckArray.push({ thumb: url });
                console.log("callbacK", callback, cuckArray)
                if (callback && cuckArray?.length >= imagesToDisplay?.length) {
                    callback(cuckArray);
                }
            }).catch(() => {
                const uploadTask = uploadBytesResumable(storageRef, img.file as any);
                uploadTask.on("state_changed", (snapshot) => {
                    const prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    if (!isNaN(prog)) {
                        setProgress(prog);
                    }
                }, () => {
                    setIsLoading(false);
                    setProgress(0);
                    if (callback && cuckArray?.length >= imagesToDisplay?.length) {
                        callback(cuckArray);
                    }
                }, () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        cuckArray.push({ thumb: url, });
                        if (callback && cuckArray?.length >= imagesToDisplay?.length) {
                            callback(cuckArray);
                        }
                    })
                }
                );
            });
        } else {
            cuckArray.push(img?.result);
            if (callback && cuckArray?.length >= imagesToDisplay?.length) {
                callback(cuckArray);
            }
        }
    };

    //-----Pure JSX Functions-----//

    const buttonsJSX = () => {
        return isLoading ? <ProgressBar now={progress} /> :
            <div className='d-flex justify-content-between'>
                {!justShowUploadButton && !blockBackBtn && <Button className='me-1 flex-fill'
                    disabled={loading}
                    onClick={() => setStep && setStep(step - 1)}
                    variant={darkMode ? 'outline-secondary' : 'secondary'}>
                    Cancel
                </Button>}
                <Button className='flex-fill'
                    disabled={loading}
                    onClick={() => document?.getElementById('uploadButton')?.click()}
                    variant={justShowUploadButton || darkMode ? 'outline-primary' : 'outline-secondary'}>
                    {customTitle ? customTitle :
                        (justShowUploadButton ? 'Upload photos of signed lease' : imagesToDisplay?.length > 0 ? 'Add more photos' : 'Choose photos')}
                    {loading && <Spinner animation='border' className='ms-1' size='sm' />}
                </Button>
                {!justShowUploadButton && imagesToDisplay && imagesToDisplay?.length > 4 && <Button className='flex-fill ms-1'
                    disabled={imagesToDisplay?.length < 4} 
                    onClick={() => uploadMultipleImages()} size='sm'>
                        Next step
                </Button>}
            </div>;
    }

    const displayImagesOrNoPhotosLabelJSX = () => {
        return imagesToDisplay?.length > 0 ? <div className='mb-4 lipbopdick' style={{ overflow: 'scroll' }}>
            <p className='fs-5 light-gray mb-2 text-center'>Reorder photos next page</p>
            {imagesToDisplay.map((i: any, idx: number) => {
                return <div className='cursor position-relative' key={`photo-upload-${idx}`} style={{ minHeight: '25vh' }}>
                    {progress == 0 && <Trash 
                    style={{ color: 'red', fontSize: '40px', position: 'absolute', 
                    right: '18px', top: '18px', backgroundColor: 'black', padding: '10px', 
                    borderRadius: '6px', zIndex: '10' }} 
                    onClick={() => removeImage(idx)} />}
                    <img className='mb-2' src={i.result?.thumb ? i.result.thumb : i.result} style={{ borderRadius: '8px', objectFit: 'cover', opacity: isLoading ? '0.4' : '1', width: '100%' }} />
                </div>;
            })}
        </div> : <div className='pb-4 pt-2'>
            <h1 className='text-center'><Images /></h1>
            <h4 className='text-center'>No photos uploaded yet</h4>
            <h6 className='mb-4 text-center'>Add at least 5 photos</h6>
        </div>;
    }

    const invisibleFileInputJSX = () => {
        return <input accept={buttonTitle ? 'application/pdf' : 'image/*,video/*'} className='fixed-middle' hidden id='uploadButton' 
            multiple={buttonTitle || isId ? false : true} onChange={onImageChange} type='file' />;
    }

    return (
        isId || customTitle? <div className={buttonTitle ? 'flex-fill' : ''}>
            {hideButton ? <>
                <input accept={buttonTitle ? 'application/pdf' : 'image/*,video/*'} className='fixed-middle' 
                    hidden id='fuckloadButton' multiple={buttonTitle || isId ? false : true} onChange={(e) => onImageChange(e)} type='file' />
            </> : <div>
                <Button className={['h-100', buttonTitle ? 'w-100' : ''].join(' ')} onClick={() => document?.getElementById('uploadButton')?.click()} 
                    size='sm' variant={darkMode ? 'outline-primary' : 'primary'}>
                    {customTitle ? customTitle : (buttonTitle ? buttonTitle : 'Upload photo')}
                </Button>
                {invisibleFileInputJSX()}
            </div>}
        </div> : <div className={[justShowUploadButton ? '' : 'border mx-4 p-4 rounded'].join(' ')}>
            {!justShowUploadButton && displayImagesOrNoPhotosLabelJSX()}
            {buttonsJSX()}
            {invisibleFileInputJSX()}
        </div>
    )
}
export default PhotoUpload;