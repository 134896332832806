



import { Button, Col, Modal, Row } from 'react-bootstrap';
import '../styles/GeneralStyling.css';

interface IChooseDateRangeModalProps {
    continueClicked: () => void,
    errors: any[],
    isOpen: boolean,
    setIsOpen: (val: boolean) => void
}

const ErrorModal = (props: IChooseDateRangeModalProps) => {
    const { continueClicked, errors, isOpen, setIsOpen } = props;

    const generateDescriptionBasedOffType = (desc: string) => {
        switch (desc) {
            case 'price': return <div className='p-3' style={{ border: '1px solid red', borderRadius: '5px', boxShadow: '2px 2px 5px lightgray' }}>
                <span className='fw-bold'>Price error</span>
                <br />
                <span style={{ fontSize: '13px' }}>Nightly rate must be between 1 and 99,999.</span>
            </div>;
            case 'images': return <div className='p-3' style={{ border: '1px solid red', borderRadius: '5px', boxShadow: '2px 2px 5px lightgray' }}>
                <span className='fw-bold'>Photo Error</span>
                <br />
                <span style={{ fontSize: '13px' }}>You must have at least 5 photos.</span>
            </div>;
            case 'description': return <div className='p-3' style={{ border: '1px solid red', borderRadius: '5px', boxShadow: '2px 2px 5px lightgray' }}>
                <span className='fw-bold'>Your description isn't long enough. </span>
                <br />
                <span style={{ fontSize: '13px' }}>We expect at least a short paragraph for every listing.</span>
            </div>;
            default: return <></>;
        }
    }

    const startNewListing = () => {
        setIsOpen(false);
    }

    //-----Pure JSX Functions-----//

    return (
        <>
            <Modal centered onHide={() => setIsOpen(false)} show={isOpen}>
                <Modal.Body>
                    <Row className='p-3'>
                        <Col xs='12'>
                            <h4 className='mb-3 text-center'>Fix the following errors</h4>
                            {errors?.map((e: any, idx) => { return <div className='mb-2 text-center' key={`you-fucked-up-error-${idx}`} style={{ color: 'red' }}>{generateDescriptionBasedOffType(e.description)}</div> })}
                        </Col>
                        <Col className='d-flex justify-content-end mt-3' xs='12'>
                            <Button onClick={() => continueClicked()} variant='outline-secondary'>close</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ErrorModal;