
import { useContext, useEffect, useState } from 'react';

import { Card, Col, Container, Placeholder, Row, Spinner } from 'react-bootstrap';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import { MapFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

import { ApiCalls } from '../models/apiCalls';
import Context from '../models/Context';
import Footer from '../components/Footer';
import Header from '../components/Header';

interface IHomePageProps {
    currentUser: any,
    setCurrentUser: (user: any) => void
}

const HomePage = (props: IHomePageProps) => {
    const { currentUser, setCurrentUser } = props;

    const apiCalls = new ApiCalls();
    const auth = getAuth();
    const { darkMode } = useContext(Context);
    const navigate = useNavigate();

    const [invoices, setInvoices] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [listings, setListings] = useState([]);
    const [notifications, setNotifications] = useState<any>([]);

    onAuthStateChanged(auth, user => {
        user && setCurrentUser(user);
    });

    useEffect(() => {
        getListings();
        window.scrollTo(0, 0);
    }, []);

    const getListings = async () => {
        const listingData = await apiCalls.getListings();
        if (listingData && listingData.length > 0) {
            setListings(listingData as any);
            setIsLoading(false);
        }
    }

    //-----Pure JSX Functions-----//

    const listingsJSX = () => {
        return <div className='px-2'>
            <Row>
                {listings?.length > 0 ? listings.map((l: any, index) => {
                    return <Col className='mb-3 position-relative px-1' key={`rolliePoly-listings-${index + 1}`} onClick={() => navigate(`/listing/${l.id}`)} xs='4' md='3' xl='2'>
                        {placeholderJSX(l?.images?.length > 0 && <img className='cursor listing-image' src={l.images[0].thumb} style={{ backgroundColor: '#ededed'}} />, 12, true)}
                        <div className='cursor mt-2'>
                            <div className='d-flex fw-bold'>{placeholderJSX(l?.city, 2)}, {placeholderJSX(l?.state, 2)}</div>
                            <div className='fw-bold' style={{ opacity: darkMode ? '0.29' : '1' }}>${placeholderJSX(l?.price, 2)}/{l?.type == 'Vacation rental' ? 'night' : 'month'}</div>
                        </div>
                    </Col>
                }) : noListingsJSX()}
            </Row>
        </div>;
    }

    const loadingJSX = () => {
        return <div className='align-items-center d-flex vh-75'>
            <div className='text-center w-100'>
                <h1 className='fs-7 mb-2'><span className='blue-text fw-bold'>
                    HUCKLBERRY</span>
                </h1>
                <p className='fs-8 mb-4' style={{ opacity: 0.7 }}>
                    Getting rentals...
                </p>
                <Spinner animation='border' variant='secondary' />
            </div>
        </div>;
    }

    const noListingsJSX = () => {
        const arr = [];
        for (let i = 0; i < 18; i++) {
            arr.push(<Col className='mb-3 position-relative px-1' xs='6' md='3' xl='2'>
                <div className='cursor listing-image' style={{ backgroundColor: '#ededed' }} />
                <div className='cursor mt-3'>
                    <div className='fw-bold' style={{ color: 'lightgray'}}>.......</div>
                </div>
            </Col>);
        }
        return arr;
    }

    const placeholderJSX = (val: any, size: number, isImage?: boolean) => {
        return val ? val : <Placeholder as={Card.Title} animation='glow' className='me-1' bg={darkMode ? 'dark' : 'light'}>
            <Placeholder className={isImage ? 'listing-image' : ''} xs={size} />
        </Placeholder>;
    }

    const showMapJSX = () => {
        return <div className='show-map' onClick={() => navigate('/map')}>
            Show map
            <MapFill />
        </div>;
    }

    return (
        <div style={{ backgroundColor: darkMode ? 'black' : '#f5f5f5' }}>
            <div className='vh-100' style={{ paddingBottom: '100px' }}>
                <Row>
                    <Col xs='12'>
                        <Header currentUser={currentUser} invoices={invoices} setInvoices={setInvoices} notifications={notifications}
                            setNotifications={setNotifications} setCurrentUser={setCurrentUser} />
                    </Col>
                    <Col className='header-padding p-teezley' xs='12'>
                        {!isLoading ? <Container fluid='xl'>
                            <div className='wookie-life'>
                                {listingsJSX()}
                            </div>
                        </Container> : loadingJSX()}
                    </Col>
                </Row>
                {showMapJSX()}
                <Helmet>
                    <title>Home rentals made easy - Hucklberry</title>
                    <h1>Home rentals made easy - Hucklberry</h1>
                    <meta name="description" content="Find vacation rentals, cabins, beach houses, unique homes and experiences around the world - all made possible by hosts on Hucklberry." />
                </Helmet>
            </div>
            <Footer />
        </div>
    )
}
export default HomePage;