

import { useState } from 'react';

import { Button, Col, Modal, Row } from 'react-bootstrap';


interface IChooseLocationModalProps {
    isOpen: boolean,
    setIsOpen: (val: boolean) => void
}

const ChooseLocationModal = (props: IChooseLocationModalProps) => {
    const { isOpen, setIsOpen } = props;
    const [recentSearchesList, setRecentSearchesList] = useState([{}, {}, {}, {}]);

    //-----Pure JSX Functions-----//

    const recentSearchesJSX = () => {
        return <>
            {recentSearchesList?.length > 0 && recentSearchesList.map((r: any, idx: number) => {
                return <div className='d-flex my-2' key={`hucklberry-recent-seardh${idx}`}>
                <img className='rounded p-2 w-25' src='https://picsum.photos/200' />
                <div className='ml-2 mt-3'>
                    <h5>Big Sky - Stays</h5>
                    <small>Week in Dec-Feb</small>
                </div>
                </div>;
            })}
        </>;
    }

    const searchByRegionJSX = () => {
        return <Row className='py-2'>
            <Col className='mb-4' xs='4'>
                <img className='mb-2 rounded w-100' src='https://picsum.photos/200' /> 
                <h6>I'm flexible</h6>
            </Col>
            <Col xs='4'>
                <img className='mb-2 rounded w-100' src='https://picsum.photos/200' />
                <h6>Europe</h6>
            </Col>
            <Col xs='4'>
                <img className='mb-2 rounded w-100' src='https://picsum.photos/200' />
                <h6>Canada</h6>
            </Col>
            <Col xs='4'>
                <img className='mb-2 rounded w-100' src='https://picsum.photos/200' /> 
                <h6>Southeast Asia</h6>
            </Col>
            <Col xs='4'>
                <img className='mb-2 rounded w-100' src='https://picsum.photos/200' />
                <h6>Italy</h6>
            </Col>
            <Col xs='4'>
                <img className='mb-2 rounded w-100' src='https://picsum.photos/200' />
                <h6>South America</h6>
            </Col>
        </Row>;
    }

    return (
        <>
            <Modal centered onHide={() => setIsOpen(false)} show={isOpen} size='lg'>
                <Modal.Body>
                    <Row className='p-3'>
                        <Col className='border-right' xs='5'>
                            <p className='fw-bold mb-4 pl-2'>Recent searches</p>
                            {recentSearchesJSX()}
                        </Col>
                        <Col xs='7'>
                            <div className='ml-4'>
                                <p className='fw-bold mb-4'>Search by region</p>
                                {searchByRegionJSX()}
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ChooseLocationModal;