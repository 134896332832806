



import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import '../styles/GeneralStyling.css';

interface IConfirmDeleteProps {
    callback: (val1: any, val: boolean) => void,
    isOpen: boolean,
    selectedReq: any,
    setIsOpen: (val: boolean) => void
}

const ConfirmReservationModal = (props: IConfirmDeleteProps) => {
    const navigate = useNavigate();
    const { callback, isOpen, selectedReq, setIsOpen } = props;

    const save = () => {
        setIsOpen(false);
        callback(selectedReq, true);
    }

    //-----Pure JSX Functions-----//

    const selectedReqJSX = () => {
        return <div className='border mb-3 mx-3 p-4 rounded' style={{ backgroundColor: 'rgb(247, 247, 247)' }}>
        <div className='d-flex mb-3'>
            <h4 className='w-36'><u>Check in/out</u>:</h4>
            <div className='flex-fill mt-1'>
                <p className='text-right' style={{ fontSize: '13px', marginTop: '-5px' }}>
                    {selectedReq?.startDate?.toLocaleString()}
                </p>
                <p className='text-right' style={{ fontSize: '13px' }}>
                    {selectedReq?.endDate?.toLocaleString()} 
                </p>
            </div>
        </div>
        <div className='d-flex mb-3'>
            <h4 className='w-36'><u>Amount</u>:</h4>
            <div className='flex-fill mt-1'>
                <h4 className='text-right'>
                    <span className='me-1' style={{ color: 'green' }}>$</span>
                    {selectedReq.price}
                </h4>
            </div>
        </div>
        <div className='d-flex mb-3'>
            <h4 className='w-36'><u>Guests</u>:</h4>
            <div className='flex-fill mt-1'>
                <h4 className='text-right'>{selectedReq.numGuests}</h4>
            </div>
        </div>
    </div>
    }

    return (
        <>
            <Modal centered onHide={() => setIsOpen(false)} show={isOpen}>
                <Modal.Body>
                    <Row>
                        <Col className='mb-1' xs='12'>
                            <div className='mb-3 mx-4 mt-2'>
                                <h4>Woot!</h4>
                                <p>{selectedReq?.guestName} wants to stay at your place!</p>
                            </div>
                            {selectedReq && selectedReqJSX()}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-end'>
                        <Button onClick={() => setIsOpen(false)} size='sm' variant='outline-secondary'>Cancel</Button>
                        <Button className='ms-2' onClick={() => callback(selectedReq, false)} size='sm' variant='danger'>Deny</Button>
                        <Button className='ms-2' onClick={() => save()} size='sm'>Send invoice for ${selectedReq?.price}</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ConfirmReservationModal;