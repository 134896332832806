
import { useEffect, useState } from 'react';

import { Button, Col, Form, Row } from 'react-bootstrap';
import PhotoUpload from './PhotoUpload';

interface ILeaseProps {
    lease: any,
    isEditing: boolean,
    setIsEditing: (val: any) => void,
    setLease: (val: any) => void,
    updateEditState: (val: string) => void,
}

const Lease = (props: ILeaseProps) => {
    const { lease, isEditing, setIsEditing, setLease, updateEditState } = props;

    const [allowLease, setAllowLease] = useState(false);
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const saveLeaseClicked = () => {
        if(!lease) setAllowLease(false);
        setIsEditing(false);
    }

    //-----Pure JSX Functions-----//

    const leaseJSX = () => {
        return <div>
            <div className='align-items-center'>
                <div className='flex-fill'>
                    <Form.Check disabled={lease} type='switch' onClick={() => setAllowLease(!allowLease)} id='lease-type-switch' checked={allowLease} label={allowLease ? 'Yes' : 'No'} />
                </div>
                <div>
                    {leaseLinkJSX()}
                </div>
                <div className='d-flex'>
                    {lease && <Button className='flex-fill me-2' onClick={() => setLease(null)} size='sm' variant='outline-danger'>Delete file</Button>}
                    {allowLease && <PhotoUpload customTitle={lease ? 'Choose different file' : 'Upload lease PDF'} callback={(e) => setLease(e[0].thumb)}
                        isId={true} images={images} setImages={setImages} hideButton={false} />}
                </div>
            </div>
        </div>;
    }

    const leaseLinkJSX = () => {
        return <p className='cursor fs-8 mt-2 mb-3 w-50' onClick={() => window?.open(lease, '_blank')} style={{ color: 'blue', opacity: '0.4' }}>
            <u>{lease}</u>
        </p>;
    }

    return (
        <>
            <Col className='border-bottom px-0 mb-3' xs={isEditing ? 12 : 6} lg={isEditing ? '12' : '10'}>
                {isEditing ? <div className='d-flex justify-content-between pb-2'>
                    <div className='flex-fill'>
                        <div className='pe-4'>
                            <div className='d-flex justify-content-between'>
                                <h5 className='fw-bold'>Lease required</h5>
                            </div>
                            <Row className='py-2'>
                                <Col xs='12'>
                                    {leaseJSX()}
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div>
                        <Button onClick={() => saveLeaseClicked()} size='sm' variant='outline-secondary'>Save lease info</Button>
                    </div>
                </div> : <div>
                    <div className='me-5'>
                        <h5>Lease required</h5>
                        {lease ? leaseLinkJSX() : <p className='light-gray mb-3 pt-1'>{allowLease ? 'Yes' : 'No'}</p>}
                    </div>
                </div>}
            </Col>
            {!isEditing && <Col className='border-bottom position-relative pe-0 mb-3' xs='6' lg='2'>
                <Button className='mt-2 w-100'
                    onClick={() => updateEditState('lease')}
                    size='sm' variant='outline-secondary'>Edit</Button>
            </Col>}
        </>
    )
}
export default Lease;