
import { getAuth } from 'firebase/auth';
import { addDoc, doc, collection, getFirestore, updateDoc, getDoc, where, query, getDocs } from 'firebase/firestore';

import { ReservationStatus } from './'

export class ApiCalls {
    auth = getAuth();
    db = getFirestore();

    addInvoice(obj: any) {
        addDoc(collection(this.db, 'invoices'), obj);
    }

    addListing(obj: any) {
        addDoc(collection(this.db, 'listings'), { ...obj, active: true, reviews: [], userId: this.auth?.currentUser?.uid, userName: this.auth?.currentUser?.displayName });
    }

    addMessage(obj: any) {
        addDoc(collection(this.db, 'messages'), { active: true, date: new Date(), ...obj });
    }

    async addNotification(toUserId: any, message: string, reservationId?: any) {
        return await addDoc(collection(this.db, 'notifications'), {
            active: true,
            date: new Date(),
            reservationId: reservationId ? reservationId : '',
            to: toUserId,
            from: this.auth?.currentUser?.uid,
            photoURL: this.auth?.currentUser?.photoURL ? this.auth.currentUser?.photoURL : '',
            person: this.auth?.currentUser?.displayName,
            message: message
        }).then(res => {
            return res.id;
        });
    }

    async addReservation(listingId: any, listing: any, numGuests: number, total: number, startDate: any, endDate: any, numNights: number, status: ReservationStatus, isBlocked?: boolean) {
        return await addDoc(collection(this.db, 'reservations'), {
            active: true,
            address: listing?.address ? listing.address: '',
            isBlocked: isBlocked ? isBlocked : false,
            category: listing?.category ? listing.category : '',
            date: new Date(),
            endDate: endDate?.toDateString(),
            guestEmail: this.auth?.currentUser?.email,
            guestName: this.auth?.currentUser?.displayName,
            guestPhoto: this.auth?.currentUser?.photoURL,
            lease: {
                file: listing?.lease ? listing?.lease : '',
                renterSigned: false,
            },
            listingId: listingId,
            numBedrooms: listing?.numBedrooms ? listing.numBedrooms: 0,
            numBaths: listing?.numBaths ? listing.numBaths : 0,
            numNights,
            numGuests: numGuests,
            price: total,
            startDate: startDate?.toDateString(),
            status: status ? status : ReservationStatus.Requested,
            type: listing?.type ? listing.type : '',
            userId: this.auth?.currentUser?.uid,
        }).then(res => {
            return res.id;
        });
    }

    addReview(obj: any) {
        addDoc(collection(this.db, 'reviews'), { date: new Date(), ...obj });
    }

    async deactivateMessage(n: any) {
        const notif = doc(this.db, "messages", n.id);
        updateDoc(notif, { active: false });
    }

    async getAllReservationsByListingId(listingId: any) {
        const arr: any[] = [];
        const q1 = query(collection(this.db as any, 'reservations'), where('listingId', '==', listingId), where('active', '==', true));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => arr.push({ id: doc.id, ...doc.data() }));
            return arr;
        }
    }

    async getGuestOfListing(email: string) {
        const arr: any[] = [];
        const q1 = query(collection(this.db as any, 'users'), where('email', '==', email));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => {
                const data = doc?.data();
                arr.push({ id: doc.id, ...doc.data() });
            });
            return arr?.length > 0 ? arr[0] : null;
        }
    }

    async getInvoicesFromAPI () {
        const arr: any[] = [];
        const q1 = query(collection(this.db as any, "invoices"),
        where(this.auth?.currentUser?.email ? 'email' : 'userId', '==', this.auth?.currentUser?.email ? this.auth.currentUser.email : this.auth?.currentUser?.uid));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => {
                arr.push({ id: doc.id, ...doc.data() });
            });
            return arr;
        }
    }

    async getListingById(listingId: string) {
        return getDoc(doc(this.db as any, 'listings', listingId)).then(res => {
            if(res.exists()) {
                return {
                    ...res.data(),
                    id: res.id,
                };
            }
            return null;
        }).catch(() => null);
    }

    async getListings() {
        const arr: any[] = [];
        const q1 = query(collection(this.db as any, 'listings' as any), where('active', '==', true));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => {
                arr.push({ id: doc.id, ...doc.data() });
            })
            return arr;
        }
    }

    async getMapListings(addressSelectedFromMap: any, ) {
        const arr: any[] = [];
        const q1 = query(collection(this.db as any, 'listings' as any), where('active', '==', true),  where('lat', '>=', addressSelectedFromMap.lat - 2), where('lat', '<=', addressSelectedFromMap.lat + 2));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => {
                arr.push({ id: doc.id, ...doc.data() });
            })
            return arr;
        }
    }

    async getMessages() {
        let arr: any[] = [];
        const q = query(collection(this.db, "messages"), where('from', '==', this.auth?.currentUser?.uid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            let data = doc.data();
            arr.push({...data, id: doc.id})
        });

        const q2 = query(collection(this.db, "messages"), where('to', '==', this.auth?.currentUser?.uid));
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((doc) => {
            let data = doc.data();
            arr.push({...data, id: doc.id})
        });

        return arr.sort((a, b) => (a.date > b.date ? 1 : -1));
    }

    async getNotifications() {
        const arr: any[] = [];
        const q1 = query(collection(this.db as any, "notifications"), where('to', '==', this.auth?.currentUser?.uid));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => {
                arr.push({ id: doc.id, ...doc.data() });
            });
            return arr;
        }
    }

    async getUserById(id: string) {
        return getDoc(doc(this.db as any, 'users', id)).then(res => {
            if(res.exists()) {
                return {
                    ...res.data(),
                    id: res.id,
                };
            }
            return null;
        }).catch(() => null);
    }

    async getPaidInvoice(email: string) {
        const arr: any[] = [];
        const q1 = query(collection(this.db as any, 'invoices'), where('email', '==', email), where('stripeInvoiceStatus', '==', 'paid'));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => arr.push({ id: doc.id, ...doc.data() }));
            return arr;
        }
    }

    async getReservation(email: any, listingId: any) {
        const arr: any[] = [];
        const q1 = query(collection(this.db as any, 'reservations'), where('listingId', '==', listingId), where('guestEmail', '==', email));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => {
                arr.push({ id: doc.id, ...doc.data() });
            });
            return arr;
        }
    }

    async getReviewsByListingId(listingId: any) {
        const arr: any[] = [];
        const q1 = query(collection(this.db as any, 'reviews'), where('listingId', '==', listingId));
        const querySnapshot1 = await getDocs(q1);
        if (querySnapshot1.size == 0) {
            return [];
        } else {
            querySnapshot1.forEach((doc) => arr.push({ id: doc.id, ...doc.data() }));
            return arr;
        }
    }

    submitContactForm(obj: any) {
        addDoc(collection(this.db, 'contact'), { date: new Date(), ...obj });
    }

    updateInvoiceToInactive(id: string) {
        const i = doc(this.db as any, 'invoices', id);
        getDoc(i).then(() => updateDoc(i, { active: false }));
    }

    updateListing(id: string, obj: any) {
        const l = doc(this.db as any, 'listings', id);
        getDoc(l).then(() => updateDoc(l, obj));
    }

    updateReservation(id: string, obj: any) {
        const l = doc(this.db as any, 'reservations', id);
        getDoc(l).then(() => updateDoc(l, obj));
    }

    updateReservationToAcceptedOrDenied(id: string, acceptedOrDenied: string) {
        const l = doc(this.db as any, 'reservations', id);
        getDoc(l).then(() => updateDoc(l, { active: acceptedOrDenied == 'Accepted' ? true : false, status: acceptedOrDenied }));
    }

    updateReservationToInactive(id: string) {
        const l = doc(this.db as any, 'reservations', id);
        getDoc(l).then(() => {
            updateDoc(l, { active: false });
        });
    }

    updateNotificationInactive(id: string) {
        const l = doc(this.db as any, 'notifications', id);
        getDoc(l).then(() => updateDoc(l, { active: false }));
    }

    updateUserPhoto(id: any, url: string, name: string) {
        const u = doc(this.db as any, 'users', id);
        getDoc(u).then(() => {
            updateDoc(u, { userName: name, photoURL: url });
        });
    }
}