import { useEffect, useState } from 'react';

import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { ApiCalls } from '../models/apiCalls';
import huck from '../images/america.png';
import { Utilities } from '../models/';
import '../styles/GeneralStyling.css';

interface INotif {
    invoices: any,
    isOpen: boolean,
    notifications: any,
    setIsOpen: (val: boolean) => void,
    setNotifications: any, 
    setInvoices: any
}

const NotificationModal = (props: INotif) => {
    const { invoices, isOpen, notifications, setIsOpen, setNotifications, setInvoices } = props;

    const apiCalls = new ApiCalls();
    const auth = getAuth();
    const navigate = useNavigate();
    const utils = new Utilities();

    const [newlyPaidInvoices, setNewlyPaidInvoices] = useState<any>([]);
    let paidInvoiceListener;

    useEffect(() => {
        if (notifications) updateNotificationsToInactive();
        if(invoices) updateInvoicesToInactive();

        return () => {
            window.removeEventListener('focus', onFocus);
        }
    }, []);

    useEffect(() => {
        if (invoices?.length > 0) {
            getPaidInvoices();
        }
    }, [invoices]);

    const addFocusListenerForPaidInvoices = () => {
        paidInvoiceListener = window.addEventListener('focus', onFocus);
    }

    const getPaidInvoices = async () => {
        const paidInvoiceData = await apiCalls.getPaidInvoice(auth?.currentUser?.email as any);
        setNewlyPaidInvoices(paidInvoiceData);
    }

    const onFocus = () => {
        getPaidInvoices();
        window.removeEventListener('focus', onFocus);
    }

    const updateInvoicesToInactive = () => {
        const arr: any[] = [];
        invoices?.forEach((n: any) => {
            apiCalls.updateInvoiceToInactive(n?.id);
            arr.push({ ...n, active: false })
        });
        setInvoices(arr.sort((a, b) => a?.date > b?.date ? 1 : -1));
    }

    const updateNotificationsToInactive = () => {
        const arr: any[] = [];
        notifications?.forEach((n: any) => {
            apiCalls.updateNotificationInactive(n?.id);
            arr.push({ ...n })
        });
        setNotifications(arr.sort((a, b) => a?.date > b?.date ? 1 : -1));
    }

    //-----Pure JSX Functions-----//

    const bookingRequestApprovedGuestViewJSX = (n: any, amount: any) => {
        return <Alert className='align-items-center d-flex justify-content-between p-2' key={`booking-req-guest-${n?.listingId}`} variant='secondary'>
            <div>
                <h6 className='fw-bold mb-2'><u>Your reservation was approved!</u></h6>
                <p className='fs-7'>To confirm your stay, you must complete payment with a credit or debit card.</p>
            </div>
            <div className='ms-4'>
                <Button onClick={() => addFocusListenerForPaidInvoices()} target='_blank' href={n?.stripeInvoiceUrl} size='sm' variant='primary'>
                    Pay ${amount}
                </Button>
            </div>
        </Alert>;
    }

    const bookingRequestApprovedJSX = (n: any) => {
        const amount = utils.formatNumberToShowDollazAndCentz(n?.items);
        const isOwner = n?.userId == auth?.currentUser?.uid;
        return isOwner ? bookingRequestApprovedOwnerViewJSX(n) : bookingRequestApprovedGuestViewJSX(n, amount);
    }

    const notyJSX = () => {
        const notelli = notifications?.filter((n: any) => n?.active);
        return notelli?.length > 0 ? notelli.map((n: any, i: number) => notificationJSX(n, i)) : 
        <div className='text-center'>
            No new notifications
        </div>
    }

    const bookingRequestApprovedOwnerViewJSX = (n: any) => {
        return <Alert className='align-items-center d-flex justify-content-between px-3 py-2' key={`booking-${n?.listingId}-${n?.email}`} variant='secondary'>
            <div>
                <h6 className='fw-bold mb-2'>
                    <u>{n?.stripeInvoiceStatus == 'paid' ? 'Woot! A customer just paid for a booking!' : 'Booking request awaiting payment'}</u>
                </h6>
                <p className='fs-7'>
                    {n?.stripeInvoiceStatus == 'paid' ? `You made $${utils.formatNumberToShowDollazAndCentz(n?.items)} on this transaction.` : 'As soon as the customer pays in full, the booking request will be complete.'}
                </p>
            </div>
            <div className='ms-4'>
                {n?.stripeInvoiceStatus == 'paid' ? <Button onClick={() => navigate(`/booking/${n?.listingId}/${n?.email}`)} size='sm' variant='outline-success'>
                    View details
                </Button> : <></>}
            </div>
        </Alert>;
    }

    const invoiceOrPaidJSX = (n: any, i: number) => {
        const paidIdx = newlyPaidInvoices.findIndex((i: any) => i?.listingId == n?.listingId);
        if (paidIdx > -1) return paidInvoiceJSX(n, i);
        return bookingRequestApprovedJSX(n);
    }

    const notificationJSX = (n: any, i: number) => {
        return <Alert className='p-2' key={`hucklberry-alert-secondary-${i}`} variant='secondary'>
            <div className='cursor d-flex' onClick={() => navigate('/account')}>
                <img src={n?.photoURL != '' ? n?.photoURL : huck} style={{ height: '34px', width: '34px', borderRadius: '17px', opacity: !n?.active ? '0.9' : '1.0' }} />
                <p className='fs-7 ms-2 mt-1' style={{ color: !n?.active ? 'gray' : 'black' }}>
                    <span className='me-1 fw-bold'>{n?.person}</span>
                    {n?.message}
                </p>
            </div>
        </Alert>;
    }

    const paidInvoiceJSX = (n: any, i: number) => {
        return <Alert className='align-items-center d-flex justify-content-between p-2' key={`hucklberry-invoice-paid-${i}`} variant='success'>
            <div>
                <h6 className='fw-bold mb-2'><u>You're all set!</u></h6>
                <p className='fs-7'>Pack your bags, cause you're goin on a road trip!.</p>
            </div>
            <div className='ms-4'>
                <Button size='sm' onClick={() => navigate(`/booking/${n?.listingId}/${auth?.currentUser?.email}`)} variant='outline-success'>View booking</Button>
            </div>
        </Alert>;
    }

    return (
        <Modal centered onHide={() => setIsOpen(false)} show={isOpen}>
            <Modal.Body>
                <Row>
                    <Col className='mb-1 px-4' xs='12'>
                        <h5 className='fw-bold mb-3'>Notifications</h5>
                        {notyJSX()}
                        {invoices?.map((n: any, i: number) => invoiceOrPaidJSX(n, i))}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex justify-content-end'>
                    <Button onClick={() => setIsOpen(false)} size='sm' variant='outline-secondary'>close notifications</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
export default NotificationModal;