
import { Button, Col, Modal, Row } from 'react-bootstrap';

interface IBookListingModalProps {
    callbackSave: () => void,
    setShow: (val: any) => void,
    show: boolean
}

const ShowWhoopsModal = (props: IBookListingModalProps) => {
    const { callbackSave, show, setShow } = props;

    //-----Pure JSX Functions-----//

    return (
        <Modal centered onHide={() => setShow(false)} show={show}>
            <Modal.Header>
                <h5 className='fw-bold'>Hold up..</h5>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className='mb-1' xs='12'>
                        Before you can book this place you'll need to log in with your email. Don't worry we dont send spam, 
                        we hate that stuff..
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex w-100'>
                    <Button className='flex-fill mr-1' onClick={() => setShow(false)} size='sm' variant='outline-secondary'>Back</Button>
                    <Button className='flex-fill mr-1' onClick={() => callbackSave()} size='sm'>
                        Create account
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
export default ShowWhoopsModal;